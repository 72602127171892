import React from 'react';
import styled from 'styled-components';
import {device} from '../../config/device';

function CsHeader(props) {
    return (
        <StyledHeader>
            <div className="cs-title">
                고객센터
            </div>

            <div className="cs-desc">
                단계별 도움말 및 자주 묻는 질문을 검색하고,<br />궁금한 점이 있다면 고객지원 팀에 문의하세요!
            </div>

            <StyledForm>
                <input type="text" placeholder="어떤 도움이 필요하신가요?" />
                <button type="submit">
                    <svg
                        width="30px" height="30px">
                        <image  x="0px" y="0px" width="30px" height="30px"  xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAQAAACROWYpAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfkCxkPDSnngHssAAAB1ElEQVQ4y5XUsWsTURzA8W8qpoi0EERoGlSsWisuFaOTitRJB10E/Q8cnFSou/+B4KoI7VAcjYMoKihIpYRWhNZNcbi00YJiFdTq1+W1ubvcJeT3ltzv/T53l9979wqSiHHOc5L9lPhDxAKPqbFGXtga4z6yPZpOSvZo/byRIN/8Gbuac3cnfHuz8L4XHXHAHR5z0nrIfvVgHr4WSp451lZyNcx9cXsWHgnTUzn/repvVWtZ+IWqs3ltESfC7Y+n8Ziqfx3qgPGuqg/T+Jaq0x0plsOzS/FsH6cBmKFzNHgNwIl4so99wD/qdIuXAOxN4kHgO6tdcROAgSReB4oUu+J+ANaTeAXYlnydzBgF4HMSLwAw0RWfA0j1xkuqLnVZqguqfkyvc3/YfFc64g+q6Y8T8XrYAEdy6ZSq89lf1ZKqvzybAQveCzevuTULD7kWCu54ODa9xcsuxg6FTw7HcSGcYYd4SiX08BV1mhQ5wCl2pXreoErU6vbGKPnA7Hjrk9hVZKX9DEM844w/EvCdN0WMYrnljZcvmN4MO6myh0HWWWGR+ZCtMEd5s2aZo0SQtzjto5J4euSwPWCs2EjyXnCav+kNp3ivOM7f946x7HNXnXX0P5pgT4X6v3Q1AAAAAElFTkSuQmCC" />
                    </svg>
                </button>
            </StyledForm>
        </StyledHeader>
    );
}

const StyledHeader = styled.div`
    width: 100%;
    height: 390px;
    background-color: #7d8594;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .cs-title {
        font-size: 60px;
        color: #fff;
        margin-bottom: 20px;
    }
    
    .cs-desc {
        max-width: 435px;
        font-size: 24px;
        color: #fff;
        text-align: center;
        line-height: 30px;
    }
    
    @media ${device.small} {
        height: 340px;
        padding: 20px;
        
        .cs-title {
            font-size: 35px;
        }
        
        .cs-desc {
            font-size: 17px;
        }
    }
`;

const StyledForm = styled.form`
    width: 100%;
    max-width: 500px;
    height: 60px;
    display: flex;
    margin-top: 55px;
    
    input {
        width: 100%;
        max-width: 440px;
        height: 60px;
        border: 1px solid #3e475c;
        outline: none;
        padding: 0 13px;
    }
    
    button {
        width: 60px;
        height: 60px;
        padding: 0;
        border: none;
        outline: none;
        background-color: #3e475c;
        cursor: pointer;
    }
    
    @media ${device.small} {
        margin-top: 35px;
    
        input {
            width: calc(100% - 50px);
            height: 50px;
            font-size: 14px;
        }
        
        button {
            width: 50px;
            height: 50px;
             
            svg {
                transform: scale(0.6);
            }
        }
    }
`;

export default CsHeader;