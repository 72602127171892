import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

InputText.propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    type: PropTypes.string,
    value: PropTypes.any
};

InputText.defaultProps = {
    placeholder: "입력해주세요",
    type: "text",
    disabled: false
};

function InputText(props) {
    return (
        <StyledInput
            type={props.type}
            placeholder={props.placeholder}
            onChange={props.onChange}
            defaultValue={props.value || ''}
            disabled={props.disabled}
        />
    );
}

const StyledInput = styled.input`
    width: 50%;
    font-size: 16px;
    color: #000;
    border: none;
    outline: none;
    
    &::placeholder {
        color: #aaaaaa;
    }
    
    &:disabled {
        background-color: #fff;
    } 
`;

export default InputText;