import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const InputBox = props => {
    return (
        <StyledInputBox className={props.firstBox ? '' : 'overlap-div'} titleSize={props.titleSize}>
            <label>
                <div className="title">{props.title}</div>
                {props.children}
            </label>
        </StyledInputBox>
    );
};

InputBox.propTypes = {
    firstBox: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.any,
    titleSize: PropTypes.string
};

const StyledInputBox = styled.div`
    width: 100%;
    height: 60px;
    padding: 0 20px;
    display: flex;
    background-color: #fff;
    font-size: 16px;
    border: 1px solid #dfdfdf;
    user-select: none;
    
    &.overlap-div {
        margin-top: -1px;
    }
    
    & > label {
        display: flex;
        align-items: center;  
        width: 100%;
        height: 100%;
        
        .title {
            width: 120px;
            min-width: 120px;
            color: #666;
            font-size: ${props => props.titleSize ? props.titleSize : '16px'}
        }
        
        span {
            color: red;
            font-size: 13px;
        }
    }
`;

export default InputBox;
