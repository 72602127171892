import React, {useEffect, useState, useRef} from "react";
import styled from "styled-components";
import {callApi} from "../../api";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {SlideBox, SlideBox2} from "./SlideBox";
import YoutubeBanner from "./YoutubeBanner";

const Banner = (props) => {

    const [leftBanner, setLeftBanner] = useState([]);
    const [rightBanner, setRightBanner] = useState([]);
    const [centerBanner, setCenterBanner] = useState([]);

    useEffect(() => {
        callBanner();

    }, []);

    const callBanner = () => {
        callApi('/banner', 'GET', {}).then((res) => {
            if (res.success) {
                const left = res.list.filter((list) => list.type === 'left');
                const right = res.list.filter((list) => list.type === 'youtube');
                const center = res.list.filter((list) => list.type === 'center');

                setLeftBanner(left);
                setRightBanner(right);
                setCenterBanner(center);
            }
        }).catch((msg) => {
            console.log(msg);
        });
    }


    const PrevArrowButton = (props) => {
        const {className, style, onClick} = props;
        return (
            <div className={className} style={{...style, left: 25, zIndex: 2}} onClick={onClick}/>
        );
    };

    const NextArrowButton = (props) => {
        const {className, style, onClick} = props;
        return (
            <div className={className} style={{...style, right: 25, zIndex: 2}} onClick={onClick}/>
        );
    };

    const slideSettings = {
        infinite: true,
        // fade: true,
        speed: 500,
        // autoplay: true,
        // autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (idx) => {

        },
        beforeChange: (current, next) => {
        },
        nextArrow: <NextArrowButton/>,
        prevArrow: <PrevArrowButton/>
    };

    const anchorRef = useRef();

    if (props.type === undefined) {
        return (
            <CenterBanner ref={anchorRef}>
                <StyledSlider1 {...slideSettings}>
                    {centerBanner.map((list, idx) => {
                        return (
                            <SlideBox key={list.id} id={list.id} idx={idx} total={centerBanner.length}
                                      imgStyle={{width: '100%', maxWidth: 1440, objectFit: 'cover', maxHeight: 400}}
                                      image_url={list.image_url} link={list.link}/>
                        )
                    })}
                </StyledSlider1>
            </CenterBanner>
        );
    } else {
        return (
            <BannerDiv>
                <StyledSlider2 {...slideSettings}>
                    {leftBanner.map((list, idx) => {
                        return (
                            <SlideBox key={list.id} id={list.id} idx={idx} total={leftBanner.length}
                                      imgStyle={{width: '100%', maxHeight: 200, objectFit: 'cover'}}
                                      image_url={list.image_url} link={list.link}/>
                        )
                    })}
                </StyledSlider2>
                <VideoWrap>
                    <Video>
                        {rightBanner.length ? (
                            <YoutubeBanner list={rightBanner}/>
                        ) : null}

                    </Video>
                </VideoWrap>
            </BannerDiv>
        );
    }

    // return (
    //     <BannerDiv>
    //         <StyledSlider1 {...slideSettings}>
    //             {leftBanner.map((list, idx) => {
    //                 return (
    //                     <SlideBox key={list.id} id={list.id} idx={idx} total={leftBanner.length} image_url={list.image_url}/>
    //                 )
    //             })}
    //         </StyledSlider1>
    //         <StyledSlider2 {...slideSettings}>
    //             {rightBanner.map((list, idx) => {
    //                 return (
    //                     <SlideBox key={list.id} id={list.id} idx={idx} total={rightBanner.length} image_url={list.image_url}/>
    //                 )
    //             })}
    //         </StyledSlider2>
    //     </BannerDiv>
    // );

}

const VideoWrap = styled.div`
    width: 50%;
    padding: 5px;
    @media screen and (max-width: 767px) {
        width: 100%;
        height: 330px;
    }
`;

const Video = styled.div`
    position:relative;
    height:100%;
    width:100%;
`;

const CenterBanner = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 30px auto;

`;

const BannerDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 1440px;
    margin: 30px auto;
    @media screen and (max-width: 767px) {
        flex-direction: column;
    }
`;

const StyledSlider1 = styled(Slider)`
    // border:1px solid red;
    position:relative;
    width:100%;
    // height: 100%;
    padding: 5px;
    @media screen and (max-width: 767px) {
        // width: 100%;
    }
`;
const StyledSlider2 = styled(Slider)`
    width: 50%;
    height: 100%;
    padding: 5px;
    @media screen and (max-width: 767px) {
        width: 100%;
    }
`;
const StyledSlider3 = styled(Slider)`
    width: 100%;
    height: 100%;
    padding: 5px;
    @media screen and (max-width: 767px) {
        width: 100%;
    }
`;
export default Banner;