// actions
const SET_CATEGORY = "SET_CATEGORY";

// action creators
const setMenus = (category) => {
    return {
        type: SET_CATEGORY,
        category
    };
};

const addCategory = (category) => {
    return (dispatch, getState) => {
        const categoryList = getState().category;
        let newArr = [];
        const newCategory = newArr.concat(categoryList.category, category);
        dispatch(setMenus(newCategory));
    };
};
const delCategory = (category) => {
    return (dispatch, getState) => {
        const categoryList = getState().category;
        const newCategory = categoryList.category.filter((cate) => cate !== category);
        dispatch(setMenus(newCategory));
    };
};

// Initial State

const initialState = {
    category: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CATEGORY:
            return applySetCategory(state, action);
        default:
            return state;
    }
};

// Reducer Funcitons
const applySetCategory = (state, action) => {
    const {category} = action;

    return {
        ...state,
        category
    };
};


// exports
const actionCreator = {
    addCategory,
    delCategory,
};

export {actionCreator};

export default reducer;