import React, {useEffect, useState} from 'react';
import {Header, Footer, ScrollTop, SliderBox, RecentView, Breadcrumb} from '../components';
import styled from 'styled-components';
import farmBg from '../assets/images/farm/bg.jpg';
// import dummyFarmData from '../dummy/farmData.json';

import {callApi} from '../api';

function Farm(props) {
    const [farmData, setFarmData] = useState();

    useEffect(() => {
        window.scrollTo(0, 0);

        const apiURL = '/company/농장';
        callApi(apiURL, 'GET', {}).then((res) => {
            if (res)
                setFarmData(res.list);
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    const [farmList, setFarmList] = useState();
    useEffect(() => {
        try {
            let result = farmData.map((item, index) => {
                return (
                    <SliderBox
                        type="farm"
                        key={item.id}
                        id={item.id}
                        link={item.link}
                        desc={item.farmInfo}
                        title={item.companyName}
                        images={item.images}
                    />
                )
            });
            setFarmList(result);
        } catch {
            setFarmList(null);
        }
    }, [farmData]);

    const breadcrumb = [
        {
            id: 1,
            title: '농촌체험',
            link: '/farm'
        },
        {
            id: 2,
            title: '농업체험',
            link: '/farm'
        }
    ];

    return (
        <>
            <Header />

            <StyledContainer>
                <div className="title-wrap">
                    <img src={farmBg} alt="" />
                    <div className="title">
                        직접 <span>수확</span>하는<br /> <span>농업 체험</span>을 즐겨보세요!
                    </div>
                </div>

                <Breadcrumb data={breadcrumb} />

                <div className="category">
                    <div></div>
                    <div className="active">농업 체험 정보</div>
                    <div></div>
                </div>

                <StyledCategoryContainer>
                    {farmList}
                </StyledCategoryContainer>
            </StyledContainer>

            <RecentView />

            <Footer />

            <ScrollTop />
        </>
    );
}

const StyledContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    
    br {
        display: none;
    }
    
    .title-wrap {
        position: relative;
        
        img {
            width: 100%;
        }
        
        .title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 45px;
            font-weight: 200;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
            
            span {
                font-weight: 600;
            }
        }        
    }
    
    .category {
        display: flex;
        justify-content: center;
        margin-top: 110px;
        margin-bottom: 30px;
        
        div {
            flex:1;
            max-width: 440px;
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            color: #434343;
            border-bottom: 2px solid #dcdcdc;
            
            &.active {
                max-width: 170px;
                border-bottom: 2px solid #f68b1f;
                padding-bottom: 12px;
            }
        }
    }
    
    @media (max-width: 940px) {
        margin: 60px auto 0;
    }
    
    @media (max-width: 475px) {
        br {
            display: block;
        }
    
        .title-wrap {
            img {
                height: 300px;
                object-fit: cover;
            }
            
            .title {
                font-size: 29px;
                text-align: center;
                width: 85%;
            }
        }
        
        .category {
            margin-top: 45px;
            
            div {
                font-size: 19px;
            }
        }
    }
`;

const StyledCategoryContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 30px auto 93px;
    max-width: 1440px;
    
    @media (max-width: 1460px) {
        max-width: 1080px;
    }
    
    @media (max-width: 1100px) {
        max-width: 720px;
    }
    
    @media (max-width: 740px) {
        max-width: 360px;
        margin: 0 auto 50px;
    }
`;

export default Farm;