import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Button = props => {
    return (
        <StyledButton width={props.width} height={props.height} onClick={props.onClick}>
            {props.text}
            {
                props.downArrow && <svg
                    width="17px" height="10px">
                    <path fillRule="evenodd" fill="rgb(62, 71, 92)"
                          d="M16.659,2.058 L8.880,9.835 L8.563,9.518 L8.245,9.835 L0.466,2.058 L1.881,0.643 L8.563,7.325 L15.244,0.643 L16.659,2.058 Z"/>
                </svg>
            }
        </StyledButton>
    );
};

Button.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    text: PropTypes.string,
    onClick: PropTypes.func,
    downArrow: PropTypes.bool
};

const StyledButton = styled.button`
    width: ${props => props.width};
    height: ${props => props.height};
    border: 1px solid #dfdfdf;
    background-color: #fff;
    color: #000;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 3px 7px 0px rgba(0, 0, 0, 0.15);
    margin-left: 20px;
    cursor: pointer;
    outline: none;
    
    svg {
        margin-left: 10px;
    }
`;

export default Button;
