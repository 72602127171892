import styled from "styled-components";

const MemberWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 740px;
    min-width: 360px;
    background-color: #efefef;
    padding: 60px 20px 30px;
    margin: 0 auto;
`;

export default MemberWrap;