import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {Logo} from './';
import logo1 from '../assets/images/footer/1_v3.png';
import logo2 from '../assets/images/footer/2.png';
import logo3 from '../assets/images/footer/3.png';
import logo4 from '../assets/images/footer/4_v1.png';
import logo5 from '../assets/images/footer/5.png';
import logo6 from '../assets/images/footer/6.png';
import logo7 from '../assets/images/footer/7.png';
import newlogo1 from '../assets/images/footer/new/1.png';
import newlogo2 from '../assets/images/footer/new/2.png';
import newlogo3 from '../assets/images/footer/new/3.png';
import newlogo4 from '../assets/images/footer/new/4.png';
import newlogo5 from '../assets/images/footer/new/5.png';
import newlogo6 from '../assets/images/footer/new/6.png';
import newlogo7 from '../assets/images/footer/new/7.png';
import Cookies from 'universal-cookie';
import Slider from 'react-slick';

const Footer = (props) => {

    const cookie = new Cookies();
    const user = cookie.get('token') !== undefined ? cookie.get('token') : null;

    // console.log(user);
    const logOut = () => {
        cookie.remove('token');
        cookie.remove('user_name');
        cookie.remove('user_id');

        window.location.href = "/";
    }

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    };

    return (
        <StyledFooter>
            <StyledFooterBanner>
                <div>
                    <a href="https://www.mafra.go.kr/" target="_blank" rel="noopener noreferrer">
                        <img src={newlogo1} alt=""/>
                    </a>
                </div>
                <div>
                    <a href="https://www.nyj.go.kr/" target="_blank" rel="noopener noreferrer">
                        <img src={newlogo2} alt=""/>
                    </a>
                </div>
                <div>
                    <a href="https://www.gg.go.kr/" target="_blank" rel="noopener noreferrer">
                        <img src={newlogo3} alt=""/>
                    </a>
                </div>
                <div>
                    <a href="https://www.nyj.go.kr/agri" target="_blank" rel="noopener noreferrer">
                        <img src={newlogo4} alt=""/>
                    </a>
                </div>
                <div>
                    <a href="/" rel="noopener noreferrer">
                        <img src={newlogo5} alt=""/>
                    </a>
                </div>
                <div>
                    <a href="/" rel="noopener noreferrer">
                        <img src={newlogo6} alt=""/>
                    </a>
                </div>
                <div>
                    <a href="https://pensionnara.co.kr" target="_blank" rel="noopener noreferrer">
                        <img src={newlogo7} alt=""/>
                    </a>
                </div>
            </StyledFooterBanner>

            <StyledMobileFooterBanner>
                <Slider {...settings}>
                    <div>
                        <a href="https://www.mafra.go.kr/" target="_blank" rel="noopener noreferrer">
                            <img src={newlogo1} alt=""/>
                        </a>
                    </div>
                    <div>
                        <a href="https://www.nyj.go.kr/" target="_blank" rel="noopener noreferrer">
                            <img src={newlogo2} alt=""/>
                        </a>
                    </div>
                    <div>
                        <a href="https://www.gg.go.kr/" target="_blank" rel="noopener noreferrer">
                            <img src={newlogo3} alt=""/>
                        </a>
                    </div>
                    <div>
                        <a href="https://www.nyj.go.kr/agri" target="_blank" rel="noopener noreferrer">
                            <img src={newlogo4} alt=""/>
                        </a>
                    </div>
                    <div>
                        <a href="/" rel="noopener noreferrer">
                            <img src={newlogo5} alt=""/>
                        </a>
                    </div>
                    <div>
                        <a href="/" rel="noopener noreferrer">
                            <img src={newlogo6} alt=""/>
                        </a>
                    </div>
                    <div>
                        <a href="https://pensionnara.co.kr" target="_blank" rel="noopener noreferrer">
                            <img src={newlogo7} alt=""/>
                        </a>
                    </div>
                </Slider>
            </StyledMobileFooterBanner>

            <StyledFooterNav>
                <div>
                    <Link to="/policy">개인정보처리방침</Link>
                    <div className="divider">|</div>
                    <Link to="/email">이메일무단수집거부</Link>
                    <div className="divider">|</div>
                    {
                        user ? (<Link to={""} onClick={logOut}>로그아웃</Link>) : (<Link to="/login">로그인</Link>)
                    }
                </div>
                <div>
                    <div className="divider">|</div>
                    <Link to="/join">회원가입</Link>
                    <div className="divider">|</div>
                    <Link to="#">입점신청</Link>
                    <div className="divider">|</div>
                    <Link to="#">고객센터</Link>
                </div>

            </StyledFooterNav>

            <StyledFooterSection>
                <div className="logo-wrap">
                    <Logo/>
                </div>
                <div className="info">
                    <div>회사명 : 리암<br/><span>&nbsp;&nbsp;&nbsp;</span>대표 : 최명훈 / 고유번호 :
                        751-73-00403
                        <div>개인정보관리책임자 : 사무국장 박정민</div>

                    </div>
                    <div>Tel. 010-9425-1773</div>
                    {/*Fax : 031-590-2319*/}
                </div>
                <div className="bottom-wrap">
                    <div className="address">주소 : (12284) 경기도 남양주시 경춘로 498 4층 433호</div>
                    <div className="copyright">copyright @ 2020 nyjstory.kr All rights reserved.</div>
                </div>
            </StyledFooterSection>
        </StyledFooter>
    );
};

const StyledFooter = styled.footer`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
`;

const StyledFooterBanner = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    
    div {
        border: 1px solid #d0d2d0;
        padding: 10px;
        margin: 30px 10px;
        width: 200px;
        height: 64px;
        
        a {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
        }
        
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            
            @media (max-width: 767px) {
                width: auto;
            }
        }
    }
    
    @media (max-width: 767px) {
        display: none;
    }
    
    //@media (max-width: 650px) {
    //    flex-wrap: wrap;
    //    margin: 15px 0;
    //    
    //    div {
    //        width: 100%;
    //        margin: 10px 20px;
    //        
    //        a {
    //            display: flex;
    //            width: 100%;
    //            height: 100%;
    //            justify-content: center;
    //            align-items: center;
    //        }
    //    }
    //}
`;

const StyledMobileFooterBanner = styled.div`
    @media (min-width: 768px) {
        display: none;
    }

    height: 100px;
    padding: 20px;
    
    .slick-list {
        width: 68%;
        margin: 0 auto;
    }
    
    .slick-next:before, .slick-prev:before {
        color: #000;
    }
    
    .slick-prev {
        left: 0;
    }
    
    .slick-next {
        right: 0;
    }
    
    .slick-slide img {
        margin: 0 auto;
    }
`;

const StyledFooterNav = styled.div`
    width: 100%;
    padding: 15px 0;
    margin: 0 auto;
    background-color: #a7be38;
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap:wrap;
    
    > div {
        color: #fff;
        font-size: 14px;
        font-weight: 300;
        display: flex;
        justify-content: center;
        align-items: center;
        
        .divider {
            margin: 0 30px;
        }
        
        @media (max-width: 700px) {
            font-size: 11px;
            padding: 5px 0;
            
            .divider:first-child {
                display:none;
            }         
            .divider {
                margin: 0 6px;
            }
        }
    }
`;

const StyledFooterSection = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    .logo-wrap {
        max-width: 280px;
    }
    
    br {
        display: none;
    }
    
    .info {
        font-size: 14px;
        font-weight: 300;
        color: #434343;
        
        margin-top: 15px;
        
        & > div:first-child {
            margin-bottom: 10px;
        }
    }
    
    .bottom-wrap {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    
        .address {
            font-size: 14px;
            font-weight: 300;
            color: #434343;    
        }
        
        .copyright {
            font-size: 12px;
            font-weight: 300;
            color: #434343;
        }
    }
    
    @media (max-width: 1260px) {
        padding: 0 20px;
    }
    
    @media (max-width: 700px) {
        padding: 0 40px;
        
        br {
            display: block;
        }
        
        .info {
            font-size: 11px;
            line-height: 17px;
            
            span {
                display: none;
            }
            
            & > div:first-child {
                margin-bottom: 0;
            }
        }
        
        .bottom-wrap {
            flex-direction: column;
            margin-top: 0;
            
            .address {
                font-size: 11px; 
                margin-bottom: 6px; 
            }
            
            .copyright {
                font-size: 11px;
            }
        }
    }
`;
export default Footer;
