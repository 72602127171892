import React, {useState} from 'react';
import styled from 'styled-components';
import {Header, Footer} from '../../components';
import {InputBox, InputText} from '../../components/inputs';
import {Link} from 'react-router-dom';
import bg from "../../assets/images/member/join-bg.jpg";
import CheckIcon from '@material-ui/icons/Check';

function MyPage(props) {
    // 성명
    const name = '테스터';

    // 이메일
    const email = 'test@test.com';

    // 기존 비밀번호
    const [currentPassword, setCurrentPassword] = useState();
    function handleChangeCurrentPassword(event) {
        setCurrentPassword(event.target.value);
    }

    const [newPasswordMatch, setNewPasswordMatch] = useState(false);

    // 신규 비밀번호
    const [newPassword1, setNewPassword1] = useState();
    function handleChangeNewPassword1(event) {
        setNewPassword1(event.target.value);
        if (newPassword2) {
            if (newPassword2 === event.target.value) {
                setNewPasswordMatch(true);
            } else {
                setNewPasswordMatch(false);
            }
        }
    }

    // 신규 비밀번호 확인
    const [newPassword2, setNewPassword2] = useState(false);
    function handleChangeNewPassword2(event) {
        setNewPassword2(event.target.value);
        if (newPassword1 === event.target.value) {
            setNewPasswordMatch(true);
        } else {
            setNewPasswordMatch(false);
        }
    }

    // 비밀번호변경 제출
    function handleClickSubmit() {
        if (!currentPassword) {
            alert('기존 비밀번호를 입력해주세요.');
            return 0;
        }

        if (!newPasswordMatch) {
            alert('비밀번호가 다릅니다. 다시 입력해주세요.');
            return 0;
        }

        // 수정 정보
        let submitData = {
            email: email,
            currentPassword: currentPassword,
            newPassword: newPassword1
        };

        /////////////////////////
        // API 호출
        //
        //
        //
        /////////////////////////

        console.log(submitData);
    }

    return (
        <>
            <Header />

            <StyledContainer>
                <div className="title-wrap">
                    <img src={bg} alt="" />
                    <div className="title">
                        <span>마이페이지</span>
                    </div>
                </div>

                <div className="category">
                    <div></div>
                    <div className="active">마이페이지</div>
                    <div></div>
                </div>

                <StyledCategoryContainer>
                    <InputBox title="성명" titleSize="14px">
                        <InputText
                            value={name}
                            disabled={true}
                        />
                    </InputBox>

                    <InputBox title="이메일" titleSize="14px">
                        <InputText
                            value={email}
                            placeholder="___@___"
                            disabled={true}
                        />
                    </InputBox>

                    <InputBox title="기존 비밀번호" titleSize="14px">
                        <InputText
                            type="password"
                            onChange={handleChangeCurrentPassword}
                        />
                    </InputBox>

                    <InputBox title="신규 비밀번호" titleSize="14px">
                        <InputText
                            type="password"
                            onChange={handleChangeNewPassword1}
                        />
                    </InputBox>

                    <InputBox title="신규 비밀번호 확인" titleSize="14px">
                        <InputText
                            type="password"
                            onChange={handleChangeNewPassword2}
                        />
                        {
                            newPassword2 &&
                            !newPasswordMatch &&
                            <span>* 비밀번호가 다릅니다.</span>
                        }
                        {
                            newPassword2 &&
                            newPasswordMatch &&
                            <CheckIcon style={{color: "green"}} />
                        }
                    </InputBox>
                    <StyledButton onClick={handleClickSubmit}>비밀번호 변경</StyledButton>
                    {/*<StyledOut><Link to="#">회원탈퇴</Link></StyledOut>*/}
                </StyledCategoryContainer>
            </StyledContainer>

            <Footer />
        </>
    );
}

const StyledContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    
    br {
        display: none;
    }
    
    .title-wrap {
        position: relative;
        
        img {
            width: 100%;
        }
        
        .title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 45px;
            font-weight: 200;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
            
            span {
                font-weight: 600;
            }
        }        
    }
    
    .category {
        display: flex;
        justify-content: center;
        margin-top: 110px;
        margin-bottom: 30px;
        
        div {
            flex:1;
            max-width: 440px;
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            color: #434343;
            border-bottom: 2px solid #dcdcdc;
            
            &.active {
                max-width: 170px;
                border-bottom: 2px solid #f68b1f;
                padding-bottom: 12px;
            }
        }
    }
    
    @media (max-width: 940px) {
        margin: 60px auto 0;
    }
    
    @media (max-width: 475px) {
        br {
            display: block;
        }
    
        .title-wrap {
            img {
                height: 300px;
                object-fit: cover;
            }
            
            .title {
                font-size: 29px;
                text-align: center;
                width: 85%;
            }
        }
        
        .category {
            margin-top: 45px;
            
            div {
                font-size: 19px;
            }
        }
    }
`;

const StyledCategoryContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 30px auto 93px;
    max-width: 540px;
    
    @media (max-width: 600px){
        padding: 0 20px;
    }
`;

const StyledButton = styled.button`
    width: 100%;
    height: 60px;
    background-color: #a7be38;
    border: none;
    outline: none;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    margin: 20px auto 20px;
`;

const StyledOut = styled.div`
    width: 100%;
    text-align: right;
`;

export default MyPage;