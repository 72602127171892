import React from 'react';
import {Header, Footer, ScrollTop, RecentView, Breadcrumb} from '../components';
import styled from 'styled-components';
import mapBg from '../assets/images/map/bg.jpg';
import mapKor from '../assets/images/map/map-kor.jpg';
// import stayKor from '../assets/images/map/stay-kor.jpg';

function Course(props) {
    const breadcrumb = [{
        id: 1,
        title: '코스별숙박안내',
        link: '/course'
    }];

    return (
        <>
           <Header />

            <StyledContainer>
                <div className="title-wrap">
                    <img src={mapBg} alt="" />
                    <div className="title">
                        남양주 <span>여행지</span><br /> 코스별 <span>숙박안내</span>
                    </div>
                </div>

                <Breadcrumb data={breadcrumb} />

                <div className="category">
                    <div></div>
                    <div className="active">남양주 <br />관광안내지도</div>
                    <div></div>
                </div>

                <StyledCategoryContainer>
                    <img src={mapKor} alt="" />
                    {/*<img src={stayKor} alt="" />*/}
                </StyledCategoryContainer>
            </StyledContainer>

           <RecentView />

           <Footer />

           <ScrollTop />
        </>
    );
}

const StyledContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    
    br {
        display: none;
    }
    
    .title-wrap {
        position: relative;
        
        img {
            width: 100%;
        }
        
        .title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 45px;
            font-weight: 200;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
            
            span {
                font-weight: 600;
            }
        }        
    }
    
    .category {
        display: flex;
        justify-content: center;
        margin-top: 110px;
        margin-bottom: 30px;
        
        div {
            flex:1;
            max-width: 440px;
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            color: #434343;
            border-bottom: 2px solid #dcdcdc;
            
            &.active {
                max-width: 170px;
                border-bottom: 2px solid #f68b1f;
                padding-bottom: 12px;
            }
        }
    }
    
    @media (max-width: 940px) {
        margin: 60px auto 0;
    }
    
    @media (max-width: 475px) {
        br {
            display: block;
        }
    
        .title-wrap {
            img {
                height: 300px;
                object-fit: cover;
            }
            
            .title {
                font-size: 29px;
                text-align: center;
                width: 85%;
            }
        }
        
        .category {
            margin-top: 45px;
            
            div {
                font-size: 19px;
            }
        }
    }
`;

const StyledCategoryContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 30px auto 93px;
    max-width: 1440px;
    
    img {
        width: 100%;
    }
    
    @media (max-width: 1460px) {
        max-width: 1080px;
    }
    
    @media (max-width: 1100px) {
        max-width: 720px;
    }
    
    @media (max-width: 740px) {
        max-width: 360px;
        margin: 0 auto 50px;
    }
`;

export default Course;