import React, {useState} from 'react';
import styled from 'styled-components';
import {Header, Footer, Checkbox, Breadcrumb} from '../../components';
import {InputBox, InputText} from '../../components/inputs';
import {Modal} from '../../components'
import bg from "../../assets/images/member/join-bg.jpg";
import CheckIcon from '@material-ui/icons/Check';
import {callApi} from "../../api";

import "../../assets/css/join.css";

import img1 from '../../assets/images/join/1.png';
import img2 from '../../assets/images/join/2.png';
import img3 from '../../assets/images/join/3.png';
import img4 from '../../assets/images/join/4.png';

function Join(props) {
    // 성명
    const [name, setName] = useState();
    const [phone, setPhone] = useState();
    const [pension, setPension] = useState();
    const [businessNumber, setBusinessNumber] = useState();
    const [file, setFile] = useState(null);

    function handleChangeName(event) {
        setName(event.target.value);
    }

    // 이메일
    const [email, setEmail] = useState();

    function handleChangeEmail(event) {
        setEmail(event.target.value);
    }

    const [passwordMatch, setPasswordMatch] = useState(false);

    // 비밀번호
    const [password1, setPassword1] = useState();

    function handleChangePassword1(event) {
        setPassword1(event.target.value);
        if (password2) {
            if (password2 === event.target.value) {
                setPasswordMatch(true);
            } else {
                setPasswordMatch(false);
            }
        }
    }

    // 비밀번호 확인
    const [password2, setPassword2] = useState(false);

    function handleChangePassword2(event) {
        setPassword2(event.target.value);
        if (password1 === event.target.value) {
            setPasswordMatch(true);
        } else {
            setPasswordMatch(false);
        }
    }

    // 이용약관, 개인정보 수집 및 이용 동의 Modal
    const [modalVisible1, setModalVisible1] = useState(false);
    const [modalVisible2, setModalVisible2] = useState(false);

    function closeModal1() {
        setModalVisible1(false);
    }

    function closeModal2() {
        setModalVisible2(false);
    }

    function handleClickTerms1() {
        setModalVisible1(true);
    }

    function handleClickTerms2() {
        setModalVisible2(true);
    }

    // 동의 체크박스
    const [agree, setAgree] = useState(false);

    function handleChangeAgree(event) {
        setAgree(event.target.checked);
    }

    // 회원가입 제출
    function handleClickSubmit() {

        console.log(file);

        if (!name) {
            alert('성명을 입력해주세요.');
            return 0;
        }

        if (!pension) {
            alert('펜션명을 입력해주세요.');
            return 0;
        }


        if (!businessNumber) {
            alert('사업자등록번호를 입력해주세요.');
            return 0;
        }
        if (!file) {
            alert('사업자등록증을 첨부하세요.');
            return 0;
        }
        if (!phone) {
            alert('전화번호를 입력해주세요.');
            return 0;
        }

        if (!email) {
            alert('아이디를 입력해주세요.');
            return 0;
        }
        if (!passwordMatch) {
            alert('비밀번호가 다릅니다. 다시 입력해주세요.');
            return 0;
        }

        if (!agree) {
            alert('이용약관, 개인정보 수집 및 이용 내용에 동의하셔야 회원가입 하실 수 있습니다.');
            return 0;
        }

        // 회원가입 정보
        let submitData = {
            name: name,
            email: email,
            pension: pension,
            phone: phone,
            password: password1
        };

        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', name);
        formData.append('email', email);
        formData.append('pension', pension);
        formData.append('business_number', businessNumber);
        formData.append('phone', phone);
        formData.append('password', password1);

        callApi('/member/join', 'POST', formData, true).then((res) => {

            if (res.message !== undefined) {
                alert(res.message);
                return;
            }

            alert('가입되었습니다. 관리자 승인 후 로그인 하실 수 있습니다.');
            props.history.push('/login');
        });
    }

    const breadcrumb = [{
        id: 1,
        title: '협회원가입신청',
        link: '/join'
    }];

    return (
        <>
            <Header/>

            <StyledContainer>
                <div className="title-wrap">
                    <img src={bg} alt=""/>
                    <div className="title">
                        <span>협회원가입신청</span>
                    </div>
                </div>

                <Breadcrumb data={breadcrumb}/>

                <div className="category">
                    <div></div>
                    <div className="active">협회원가입신청</div>
                    <div></div>
                </div>

                <div className="join">
                    <div className="join-header">
                        <div className="join-title">&nbsp;&bull;&nbsp;&nbsp;회원가입절차</div>
                        <div className="join-desc">회비는 가입일을 기준으로 해당 분기에서 일할 계산하여 부과(가입시 동시 납부 가능)</div>
                    </div>

                    <div className="join-process">
                        <div className="join-circle">
                            <img src={img1}/>
                            <div className="join-circle-contents">
                                <div>
                                    회원신청서 작성
                                </div>
                                <div>
                                    (온라인,FAX. 방문신청)
                                </div>
                            </div>
                        </div>
                        <div className="join-next">
                            <img src={img4}/>
                        </div>
                        <div className="join-circle">
                            <img src={img2}/>
                            <div className="join-circle-contents">
                                <div>
                                    가입비 납부
                                </div>
                            </div>
                        </div>
                        <div className="join-next">
                            <img src={img4}/>
                        </div>
                        <div className="join-circle">
                            <img src={img3}/>
                            <div className="join-circle-contents">
                                <div>
                                    가입완료
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="category">
                    <div></div>
                    <div className="active">회원자격 및 혜택</div>
                    <div></div>
                </div>

                <div className="join-second">
                    <div className="join-row">
                        <div className="join-col">
                            <div className="join-title">&nbsp;&bull;&nbsp;&nbsp;회원의 자격</div>
                            <ol>
                                <li>남양주시에서 숙박업(민박,펜션업) 관련된 사업자</li>
                                <li>지방자치단체</li>
                            </ol>
                        </div>

                        <div className="join-col">
                            <div className="join-title">&nbsp;&bull;&nbsp;&nbsp;회원의 의무</div>
                            <ol>
                                <li>정관 및 각종 회의에서 결의된 사항의 준수</li>
                                <li>회원은 임원회에서 정한 가입비 및 회비의 납부</li>
                            </ol>
                        </div>
                    </div>
                    <div className="join-row">
                        <div className="join-col">
                            <div className="join-title">&nbsp;&bull;&nbsp;&nbsp;회원의 혜택</div>
                            <ol>
                                <li>고객들에게 신뢰를 제공하는 회원 인증마크 부착</li>
                                <li>대고객서비스를 위한 농어촌민박 위생의무교육실시</li>
                                <li>회의(간담회) 및 워크샵을 통한 애로사항 청취 후 남양주시 건의</li>
                                <li>남양주시 포상 상신</li>
                                <li>협회 주관 대형이벤트 개최 시 부대시설 운영 참여권 부여</li>
                                <li>회원사 대상 장학사업 등</li>
                                <li>홍보사이트 "GAZA(가자)" 를 통한 상품 판매 지원</li>
                                <li>각 종 온라인사이트(SNS, 블로그, 기사 등) 회원사 홍보</li>
                                <li>남양주시 문화관광 및 남양주 문화원 각 종 홍보물을 통한 회원사 홍보</li>
                                <li>신규 숙박아이템 발굴 제공</li>
                                <li>경쟁력 강화를 위한 홍보마케팅 지원</li>
                                <li>협회 가입 시 가입비 전액 지원</li>
                                <li>"GAZA" 민박(펜션) 홍보 사이트 광고 100% 지원</li>
                                <li>"GAZA" 민박(펜션) 홍보 사이트 입점비 협회 100% 지원</li>
                                <li>펜션 신규 또는 인테리어 리뉴얼 사진촬영 시 일부 지원</li>
                                <li>영업상 필요한 물품 공동구매 및 지원</li>
                            </ol>
                        </div>
                    </div>
                </div>

                <StyledCategoryContainer>
                    <InputBox title="성명">
                        <InputText
                            value={name}
                            onChange={handleChangeName}
                        />
                    </InputBox>

                    <InputBox title="펜션명">
                        <InputText
                            value={pension}
                            onChange={(e) => setPension(e.target.value)}
                        />
                    </InputBox>

                    <InputBox title="사업자등록번호">
                        <InputText
                            value={businessNumber}
                            placeholder="- 포함하여 입력하세요."
                            onChange={(e) => setBusinessNumber(e.target.value)}
                        />
                    </InputBox>
                    <InputBox title="사업자등록증">
                        <input type="file" onChange={(e)=>setFile(e.target.files[0])}/>
                    </InputBox>

                    <InputBox title="전화번호">
                        <InputText
                            value={phone}
                            placeholder="- 포함하여 입력하세요."
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </InputBox>

                    <InputBox title="아이디">
                        <InputText
                            value={handleChangeEmail}
                            onChange={handleChangeEmail}
                        />
                    </InputBox>
                    <InputBox title="비밀번호">
                        <InputText
                            type="password"
                            onChange={handleChangePassword1}
                        />
                    </InputBox>

                    <InputBox title="비밀번호 확인">
                        <InputText
                            type="password"
                            onChange={handleChangePassword2}
                        />
                        {
                            password2 &&
                            !passwordMatch &&
                            <span>* 비밀번호가 다릅니다.</span>
                        }
                        {
                            password2 &&
                            passwordMatch &&
                            <CheckIcon style={{color: "green"}}/>
                        }
                    </InputBox>

                    <StyledTerms>
                        <label>
                            <Checkbox checked={agree} onChange={handleChangeAgree}/>
                            <button onClick={handleClickTerms1}>이용약관,</button>
                            <button onClick={handleClickTerms2}>개인정보 수집 및 이용</button>
                            내용을 확인 하였으며, 동의합니다.
                        </label>
                    </StyledTerms>

                    <StyledButton onClick={handleClickSubmit}>회원가입</StyledButton>
                </StyledCategoryContainer>
            </StyledContainer>

            {
                modalVisible1 && <Modal
                    visible={modalVisible1}
                    closable={true}
                    maskClosable={true}
                    onClose={closeModal1}
                >
                    이용규칙
                </Modal>
            }

            {
                modalVisible2 && <Modal
                    visible={modalVisible2}
                    closable={true}
                    maskClosable={true}
                    onClose={closeModal2}
                >
                    개인정보 수집 및 이용
                </Modal>
            }

            <Footer/>
        </>
    );
}

const StyledContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    
    br {
        display: none;
    }
    
    .title-wrap {
        position: relative;
        
        img {
            width: 100%;
        }
        
        .title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 45px;
            font-weight: 200;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
            
            span {
                font-weight: 600;
            }
        }        
    }
    
    .category {
        max-width: 1050px;
        display: flex;
        margin: 110px auto 30px;
        
        div {
            width: 100%;
            max-width: 440px;
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            color: #434343;
            border-bottom: 2px solid #dcdcdc;
            
            &.active {
                max-width: 170px;
                border-bottom: 2px solid #f68b1f;
                padding-bottom: 12px;
            }
        }
    }
    
    @media (max-width: 940px) {
        margin: 60px auto 0;
    }
    
    @media (max-width: 475px) {
        br {
            display: block;
        }
    
        .title-wrap {
            img {
                height: 300px;
                object-fit: cover;
            }
            
            .title {
                font-size: 29px;
                text-align: center;
                width: 85%;
            }
        }
        
        .category {
            margin-top: 45px;
            
            div {
                font-size: 19px;
            }
        }
    }
`;

const StyledCategoryContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto 93px;
    max-width: 520px;
    
    @media (max-width: 600px){
        padding: 0 20px;
    }
`;

const StyledButton = styled.button`
    width: 100%;
    height: 60px;
    background-color: #a7be38;
    border: none;
    outline: none;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    margin: 20px auto 30px;
`;

const StyledTerms = styled.div`
    font-size: 14px;
    color: #666;
    line-height: 28px;
    display: flex;
    margin-top: 10px;
    
    & > label {
        display: flex;
        cursor: pointer;
        user-select: none;
        
        label {
            margin-right: 10px;
        }
    }
    
    button {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid #666;
        outline: none;
        background-color: inherit;
        color: inherit;
        padding: 0;
        cursor: pointer;
        margin-right: 5px;
    }
    
    @media (max-width: 600px) {
        & > label {
            align-items: center;
            font-size: 10px;
            
            label {
                margin-right: 5px;
            }
        }   
    }
`;

export default Join;