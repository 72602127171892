import React, {useState, useEffect} from 'react';
import {Header, Footer, ScrollTop, RecentView, Map, Breadcrumb} from '../../components';
import {useParams} from 'react-router-dom';
import {callApi} from '../../api';
import styled from 'styled-components';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import PhoneInTalkOutlinedIcon from '@material-ui/icons/PhoneInTalkOutlined';
import InfoIcon from '@material-ui/icons/Info';
import RoomIcon from '@material-ui/icons/Room';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import SubwayIcon from '@material-ui/icons/Subway';
import '../../assets/css/tour-detail.css';
import breadcrumbData from './breadcrumbData.json';
import natureData from '../../dummy/tourNature.json';
import cultureTourData from './cultureTourData.json';

function TourDetail(props) {
    let {type, id} = useParams();

    const [tourData, setTourData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [content, setContent] = useState();
    const [contentDetail, setContentDetail] = useState();
    const [mapTitle, setMapTitle] = useState();

    // 남양주시청 위도경도
    const namCityLatLng = {Lat: 37.6359871, Lng: 127.216322};

    const [mapLat, setMapLat] = useState(namCityLatLng.Lat);
    const [mapLng, setMapLng] = useState(namCityLatLng.Lng);
    const [isMarker, setIsMarker] = useState(false);
    const [mapLevel, setMapLevel] = useState(8);

    const breadcrumb = breadcrumbData[type];

    useEffect(() => {
        window.scrollTo(0, 0);

        if (type === "자연관광") {
            const res = natureData.find(data => data.id == id);

            if (res) {
                setTourData(res);
                // setContent(res.content.replace(/="\/File/gi, '="https://www.nyj.go.kr/File'));
                // setContentDetail(res.content_detail.replace(/="\/File/gi, '="https://www.nyj.go.kr/File'));
                setContent(res.content.replace(/\n/g, '<br/>'));
                setContentDetail(res.content_detail.replace(/\n/g, '<br/>'));
                if (res.lat !== "" && res.lng !== "") {
                    setMapTitle(res.title);
                    setMapLat(parseFloat(res.lat));
                    setMapLng(parseFloat(res.lng));
                    setIsMarker(true);
                    setMapLevel(3);
                }
                setIsLoading(false);
            }
        } else if (type === "문화관광") {
            const res = cultureTourData.find(data => data.id == id);

            if (res) {
                setTourData(res);
                // setContent(res.content.replace(/="\/File/gi, '="https://www.nyj.go.kr/File'));
                // setContentDetail(res.content_detail.replace(/="\/File/gi, '="https://www.nyj.go.kr/File'));
                setContent(res.content.replace(/\n/g, '<br/>'));
                setContentDetail(res.content_detail.replace(/\n/g, '<br/>'));
                if (res.lat !== "" && res.lng !== "") {
                    setMapTitle(res.title);
                    setMapLat(parseFloat(res.lat));
                    setMapLng(parseFloat(res.lng));
                    setIsMarker(true);
                    setMapLevel(3);
                }
                setIsLoading(false);
            }
        } else {
            const url = '/namyangju/' + id;
            callApi(url, 'GET', {}).then((res) => {
                if (res) {
                    setTourData(res);
                    setContent(res.content.replace(/="\/File/gi, '="https://www.nyj.go.kr/File'));
                    setContentDetail(res.content_detail.replace(/="\/File/gi, '="https://www.nyj.go.kr/File'));
                    if (res.lat !== "" && res.lng !== "") {
                        setMapTitle(res.title);
                        setMapLat(parseFloat(res.lat));
                        setMapLng(parseFloat(res.lng));
                        setIsMarker(true);
                        setMapLevel(3);
                    }
                    setIsLoading(false);
                }
            }).catch((err) => {
                console.log(err);
            });
        }
    }, [id]);

    return (
        <>
            <Header/>

            <StyledContainer>
                <Breadcrumb data={breadcrumb}/>

                <div className="wrap">
                    <div className="img-container">
                        <div><img src={tourData.img_url} alt=""/></div>
                        <div className="right">
                            <div className="title">
                                <h2>{tourData.title}</h2>
                            </div>
                            <div className="info-wrap">
                                <div className="info">
                                    <div><LocationOnOutlinedIcon/>주소</div>
                                    <div> : {tourData.address}</div>
                                </div>
                                <div className="info">
                                    <div><PhoneInTalkOutlinedIcon/>전화번호</div>
                                    <div> : {tourData.tel}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h3>
                        <div><InfoIcon/></div>
                        <div>소개</div>
                    </h3>

                    <div className="contents">
                        {
                            !isLoading &&
                            <div style={{letterSpacing :0.5, lineHeight:1.5}} dangerouslySetInnerHTML={{__html: content}}></div>
                        }

                        {
                            !isLoading &&
                            <div style={{letterSpacing :0.5, lineHeight:1.5}} dangerouslySetInnerHTML={{__html: contentDetail}}></div>
                        }
                    </div>

                    <h3>
                        <div><RoomIcon/></div>
                        <div>찾아오시는 길</div>
                    </h3>

                    <div>
                        {
                            !isLoading &&
                            <Map
                                title={mapTitle}
                                lat={mapLat}
                                lng={mapLng}
                                isMarker={isMarker}
                                level={mapLevel}
                            />
                        }
                    </div>
                    {/*<div className="traffic">*/}
                    {/*    <div><DirectionsBusIcon/>&nbsp;&nbsp;버스이용 시</div>*/}
                    {/*    <div>{tourData.bus}</div>*/}
                    {/*</div>*/}
                    {/*<div className="traffic">*/}
                    {/*    <div><SubwayIcon/>&nbsp;&nbsp;지하철이용 시</div>*/}
                    {/*    <div>{tourData.subway}</div>*/}
                    {/*</div>*/}
                    <div className="license">
                        <a href="http://www.kogl.or.kr/info/licenseType3.do" target="_blank" title="새창열림"
                           rel="noopener noreferrer">
                            <img alt="[공공누리 공공저작물 자유이용허락] 제3유형:출처표시+변경금지"
                                 src="https://www.nyj.go.kr/common/image/new_img_opentype03.png"/>
                            <span>본 저작물은 "공공누리" <a href="http://www.kogl.or.kr/info/licenseType3.do" target="_blank"
                                                   title="새창열림" rel="noopener noreferrer">제3유형:출처표시+변경금지</a> 조건에 따라 이용 할 수 있습니다.</span>
                        </a>
                    </div>
                </div>
            </StyledContainer>

            <RecentView/>

            <Footer/>

            <ScrollTop/>
        </>
    );
}

const StyledContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    
    .wrap {
        display: flex;
        flex-direction: column;
        max-width: 1240px;
        margin: 60px auto;
        padding: 0 20px;
        
        .img-container {
            border: 1px solid #bfbfbf;
            display: flex;
            padding: 30px;
            
            img {
                max-width: 300px;
            }
            
            .right {
                padding: 0 20px;
                flex: 1;
                display: flex;
                flex-direction: column;
                
                .title {
                    border-bottom: 1px solid #bfbfbf;
                    width: 100%;
                }
                
                .info-wrap {
                    padding: 20px 0;
                    
                    .info {
                        display: flex;
                        align-items: center;
                        height: 35px;
                    }
                    
                    .info > div:first-child {
                        font-size: 20px;
                        color: #e87f00;
                        line-height: 20px;
                        display: flex;
                        align-items: center;
                        margin-right: 5px;
                    }
                }
            }
        }
        
        .traffic {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 20px;
            line-height: 20px;
            
            & > div:first-child {
                display: flex;
                align-items: center;
            }
            
            & > div:last-child {
                font-size: 16px;
                margin: 10px 0 30px;
            }
        }
        
        .license {
            border: 1px solid #e5e5e5;
            padding: 10px 20px;
            
            a {
                display: flex;
                align-items: center;
                
                span {
                    display: flex;
                    margin-left: 20px;
                    font-size: 13px;
                    
                    a {
                        margin-left: 5px;
                    }
                }
            }
        }
    }
    
    h3 {
        font-size: 22px;
        display: flex;
        margin-top: 34px;
        
        & div:last-child {
            margin-left: 5px;
            padding-bottom: 2px;
            line-height: 22px;
        }
    }
    
    p {
        margin: 0;
    }
    
    @media (max-width: 940px) {
        padding: 60px 0 0;
    }
    
    @media (max-width: 900px) {
        .img-container {
            flex-direction: column;
            
            .right {
                padding: 0 !important;
            }
            
            img {
                max-width: 100%;
            }
            
            .title {
                h2 {
                    font-size: 18px;
                }
            }
            
            .right .info-wrap .info > div:first-child {
                font-size: 16px;
            }
        }
    }
    
    @media (max-width: 600px) {
        .img-container {
            flex-direction: column;
            
            img {
                width: 100%;
                max-width: 100%;
            }
            
            .title {
                h2 {
                    font-size: 18px;
                }
            }
            
            .right .info-wrap .info div {
                font-size: 14px !important;
            }
        }
        
        h3 {
            font-size: 18px;
            display: flex;
            margin-top: 30px;
            
            & div:last-child {
                margin-left: 5px;
                padding-bottom: 2px;
                line-height: 22px;
            }
        }
        
        .contents {
            text-align: justify;
            font-size: 14px;
        }
    }
    
    @media (max-width: 500px) {
        .img-container {
        
            .title {
                h2 {
                    font-size: 16px;
                }
            }
            
            .right .info-wrap .info div {
                font-size: 12px !important;
            }
        }
    }
`;

export default TourDetail;