import React, {useEffect} from 'react';
import {Header, Footer, ScrollTop, RecentView, Breadcrumb} from '../../components';
import styled from 'styled-components';
import bg from "../../assets/images/assoc/greetings-bg.jpg";
import partner1 from "../../assets/images/partner/design.png";
import partner3 from "../../assets/images/partner/liomlogo.jpg";
import partner2 from "../../assets/images/partner/pensionnara_logo.png";
import partner4 from "../../assets/images/partner/ddnayo.png";
import partner5 from "../../assets/images/partner/yanolja.png";
import '../../assets/css/partner.css';

function Partners(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const breadcrumb = [{
        id: 1,
        title: '협력업체',
        link: '/partners'
    }];

    return (
        <>
            <Header/>

            <StyledContainer>
                <div className="title-wrap">
                    <img src={bg} alt=""/>
                    <div className="title">
                        <span>협력업체</span>
                    </div>
                </div>

                <Breadcrumb data={breadcrumb}/>

                <div className="category">
                    <div></div>
                    <div className="active">협력업체</div>
                    <div></div>
                </div>


                <div className="partner">
                    <div className="partner-list">
                        <div className="partner-img"><img src={partner2} alt="펜션나라"/></div>
                        <div className="partner-contents">
                            <div className="partner-title">펜션나라</div>
                            <div className="partner-desc">높은 기술력과 차별화된 시스템으로 성공적인 결과로 말씀드립니다.</div>
                            <div className="partner-cate">홈페이지 제작 / 사진 촬영 / 예약 포털 사이트 / 온라인 마케팅</div>
                            <div className="partner-tel">TEL. 1544-0758</div>
                        </div>
                    </div>
                    {/*<div className="partner-list">*/}
                    {/*    <div className="partner-img"><img src={partner1} alt="디자인명소"/></div>*/}
                    {/*    <div className="partner-contents">*/}
                    {/*        <div className="partner-title">디자인명소</div>*/}
                    {/*        <div className="partner-desc">다양한 디자인과 홍보물을 제작해드립니다.</div>*/}
                    {/*        <div className="partner-cate">온라인 마케팅 / 웹디자인 / 인쇄물디자인 / 디자인편집물</div>*/}
                    {/*        <div className="partner-tel">TEL. 010-8802-3186</div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="partner-list">
                        <div className="partner-img"><a href="http://liomdesign.kr" target="_blank"><img src={partner3}
                                                                                                         alt="리암디자인"/></a>
                        </div>
                        <div className="partner-contents">
                            <div className="partner-title">리암디자인</div>
                            <div className="partner-desc">다양한 디자인과 홍보물을 제작해드립니다.</div>
                            <div className="partner-cate">온라인 마케팅 / 웹디자인 / 인쇄물디자인 / 디자인편집물</div>
                            <div className="partner-tel">TEL. 010-5890-0045</div>
                        </div>
                    </div>
                    <div className="partner-list">
                        <div className="partner-img"><a href="https://trip.ddnayo.com" target="_blank"><img
                            src={partner4} alt="떠나요닷컴"/></a></div>
                        <div className="partner-contents">
                            <div className="partner-title">떠나요닷컴</div>
                            <div className="partner-desc">최저가 실시간 펜션예약</div>
                            <div className="partner-cate">예약 포털 사이트</div>
                        </div>
                    </div>
                    <div className="partner-list">
                        <div className="partner-img"><a href="https://www.yanolja.com" target="_blank"><img
                            src={partner5} alt="야놀자"/></a></div>
                        <div className="partner-contents">
                            <div className="partner-title">야놀자</div>
                            <div className="partner-desc">
                                여가를 위한 모든 서비스
                            </div>
                            <div className="partner-cate">예약 포털 사이트</div>
                        </div>
                    </div>
                </div>


            </StyledContainer>

            <RecentView/>

            <Footer/>

            <ScrollTop/>
        </>
    );
}

const StyledContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    
    .title-wrap {
        position: relative;
        
        img {
            width: 100%;
        }
        
        .title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 45px;
            font-weight: 200;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
            
            span {
                font-weight: 600;
            }
        }        
    }
    
    .category {
        max-width: 1050px;
        display: flex;
        margin: 110px auto 30px;
        
        div {
            width: 100%;
            max-width: 440px;
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            color: #434343;
            border-bottom: 2px solid #dcdcdc;
            
            &.active {
                max-width: 170px;
                border-bottom: 2px solid #f68b1f;
                padding-bottom: 12px;
            }
        }
    }
    
    @media (max-width: 940px) {
        margin: 60px auto 0;
    }
    
    @media (max-width: 475px) {
    
        .title-wrap {
            img {
                height: 300px;
                object-fit: cover;
            }
            
            .title {
                font-size: 29px;
                text-align: center;
                width: 85%;
            }
        }
        
        .category {
            margin-top: 45px;
            
            div {
                font-size: 19px;
            }
        }
    }
`;

const StyledCategoryContainer = styled.div`
    margin: 100px auto 100px;
    // max-width: 643px;
    max-width: 800px;
    padding: 0 20px;
    text-align: justify;
    
    .mobile-hide {
        display: none;
    }
    
    h2 {
        font-size: 30px;
    }
    
    h3 {
        font-size: 21px;
    }
    
    div {
        line-height: 24px;
        font-size: 16px;
    }
    
    @media (max-width: 660px) {
        margin: 60px auto 60px;
    
        .mobile-hide {
            display: none;
        }
    
        h2 {
            font-size: 25px;
        }
        
        h3 {
            font-size: 17px;
        }
    
        div {
            margin: 0;
            font-size: 14px;
        }
    }
`;

export default Partners;