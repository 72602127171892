import React, {useState, useEffect} from 'react';
import {Header, Footer, ScrollTop, RecentView, Breadcrumb} from '../../components';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';
import '../../assets/css/tour-detail.css';
import festivalData from '../Tour/festivalData.json';
import showData from '../Tour/showData.json';
import citytourData from '../Tour/citytourData.json';
import restaurantData from '../Tour/restaurantData.json';
import breadcrumbData from './breadcrumbData.json';

function TourDetail(props) {
    let {type, id} = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [content, setContent] = useState();

    useEffect(() => {
        window.scrollTo(0, 0);

        if (type === 'festival') {
            festivalData.map((item, index) => {
                if (item.id === parseInt(id)) {
                    setContent(item.content.replace(/="\/File/gi, '="https://www.nyj.go.kr/File'));
                    setIsLoading(false);
                    return null;
                } else {
                    return null;
                }
            });
        } else if (type === 'show') {
            showData.map((item, index) => {
                if (item.id === parseInt(id)) {
                    setContent(item.content.replace(/="\/File/gi, '="https://www.nyj.go.kr/File'));
                    setIsLoading(false);
                    return null;
                } else {
                    return null;
                }
            });
        } else if (type === 'citytour') {
            citytourData.map((item, index) => {
                if (item.id === parseInt(id)) {
                    setContent(item.content.replace(/="\/File/gi, '="https://www.nyj.go.kr/File'));
                    setIsLoading(false);
                    return null;
                } else {
                    return null;
                }
            });
        } else if (type === 'restaurant') {
            restaurantData.map((item, index) => {
                if (item.id === parseInt(id)) {
                    let temp = item.content.replace(/="\/File/gi, '="https://www.nyj.go.kr/File');
                    let temp2 = temp.replace("messagebox", "messagebox-hide");
                    let temp3 = temp2.replace("document_add_infomation", "document_add_infomation-hide");
                    setContent(temp3);
                    setIsLoading(false);
                    return null;
                } else {
                    return null;
                }
            });
        }
    }, [type, id]);

    const breadcrumb = breadcrumbData[type];

    return (
        <>
            <Header />

            <StyledContainer>
                <Breadcrumb data={breadcrumb} />

                <div className="wrap">
                    <div className="contents">
                        {
                            !isLoading &&
                            <div dangerouslySetInnerHTML={{__html: content}}></div>
                        }
                    </div>

                    <div className="license">
                        <a href="http://www.kogl.or.kr/info/licenseType3.do" target="_blank" title="새창열림" rel="noopener noreferrer">
                            <img alt="[공공누리 공공저작물 자유이용허락] 제3유형:출처표시+변경금지" src="https://www.nyj.go.kr/common/image/new_img_opentype03.png" />
                            <span>본 저작물은 "공공누리" <a href="http://www.kogl.or.kr/info/licenseType3.do" target="_blank" title="새창열림" rel="noopener noreferrer">제3유형:출처표시+변경금지</a> 조건에 따라 이용 할 수 있습니다.</span>
                        </a>
                    </div>
                </div>
            </StyledContainer>

            <RecentView />

            <Footer />

            <ScrollTop />
        </>
    );
}

const StyledContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    
    .wrap {
        display: flex;
        flex-direction: column;
        max-width: 1240px;
        margin: 60px auto;
        padding: 0 20px;
        
        .contents {
            margin-bottom: 30px;
        }
        
        .img-container {
            border: 1px solid #bfbfbf;
            display: flex;
            padding: 30px;
            
            .right {
                padding: 0 20px;
                flex: 1;
                display: flex;
                flex-direction: column;
                
                .title {
                    border-bottom: 1px solid #bfbfbf;
                    width: 100%;
                }
                
                .info-wrap {
                    padding: 20px 0;
                    
                    .info {
                        display: flex;
                        align-items: center;
                        height: 35px;
                    }
                    
                    .info > div:first-child {
                        font-size: 20px;
                        color: #e87f00;
                        line-height: 20px;
                        display: flex;
                        align-items: center;
                        margin-right: 5px;
                    }
                }
            }
        }
        
        .traffic {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 20px;
            line-height: 20px;
            
            & > div:first-child {
                display: flex;
                align-items: center;
            }
            
            & > div:last-child {
                font-size: 16px;
                margin: 10px 0 30px;
            }
        }
        
        .license {
            border: 1px solid #e5e5e5;
            padding: 10px 20px;
            
            a {
                display: flex;
                align-items: center;
                
                span {
                    display: flex;
                    margin-left: 20px;
                    font-size: 13px;
                    
                    a {
                        margin-left: 5px;
                    }
                }
            }
        }
    }
    
    h3 {
        font-size: 22px;
        display: flex;
        margin-top: 34px;
        
        & div:last-child {
            margin-left: 5px;
            padding-bottom: 2px;
            line-height: 22px;
        }
    }
    
    p {
        margin: 0;
    }
    
    @media (max-width: 940px) {
        padding: 60px 0 0;
    }
    
    @media (max-width: 576px) {
        .license a {
            display: flex;
            flex-direction: column;
            
            img {
                margin-bottom: 10px;
            }
        }
    }
`;

export default TourDetail;