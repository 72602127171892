import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import {device} from '../config/device';
import prevIcon from '../assets/images/slider-prev-icon.png';
import nextIcon from '../assets/images/slider-next-icon.png';
import PropTypes from 'prop-types';
import noImage from "../assets/images/noimage.jpg";

const DetailSlider = props => {
    const [isImage, setIsImage] = useState(false);

    useEffect(() => {
        if (props.images.length > 0) {
            setIsImage(true);
        } else {
            setIsImage(false);
        }
    }, [props.images]);

    const PrevArrowButton = (props) => {
        return (
            <StyledArrowButton className="slick-arrow prev" onClick={props.onClick}>
                <img src={prevIcon} alt="" />
            </StyledArrowButton>
        );
    };

    const NextArrowButton = (props) => {
        return (
            <StyledArrowButton className="slick-arrow next" onClick={props.onClick}>
                <img src={nextIcon} alt="" />
            </StyledArrowButton>
        );
    };

    const SliderSettings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrowButton />,
        prevArrow: <PrevArrowButton />
    }

    // 노 이미지
    function defaultSrc(event) {
        event.target.src = noImage;
    }

    return (
        <DetailSliderContainer isImage={isImage}>
            <Slider {...SliderSettings}>
                {
                    props.images &&
                    props.images.map((item, index) => {
                        return (
                            <div key={index}>
                                <img src={item.img_url} alt="" onError={defaultSrc} />
                            </div>
                        )
                    })
                }
            </Slider>
        </DetailSliderContainer>
    );
};

DetailSlider.propTypes = {
    images: PropTypes.array
}

const StyledArrowButton = styled.button`
    background: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &.prev {
        left: 10px;
    }

    &.next {
        right: 10px;
    }
    
    img {
        height:100% !important;
    }

    display: flex;
    border: none;
    outline: none;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    z-index: 2;
    
    @media ${device.small} {
        
    }
`;

const DetailSliderContainer = styled.div`
    position: relative;
    width: 100%;
    max-width: 1250px;
    padding: 0 20px;
    height: ${props => props.isImage ? '680px' : '0'};
    margin: 0 auto;
    
    img {
        width: 100%;
        height: 680px;
        object-fit: cover;
    }
    
    @media (max-width: 1260px) {
        & {
            padding-top: 56.25%;
        }
        
        & div {
            height: 100%;
        }
    
        & > div {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        
        img {
            height: 100%;
        }    
    }
    
    .slick-slider {
        .slick-arrow {
            display:none !important;
        }

        &:hover {
            .slick-arrow {
                display:flex !important;
            }
        }
        
        visibility: visible;
        opacity: 1;
    }
    
    @media (max-width: 940px) {
        margin: 0 auto 0;
        
        height: auto;
        
        .slick-slider {
            .slick-arrow {
                display: flex !important;
            }
        }
    }
`;

export default DetailSlider;
