import {combineReducers, createStore, applyMiddleware} from "redux";
import thunk from "redux-thunk";
import {routerMiddleware, connectRouter} from 'connected-react-router';
import category from "./modules/category";
import {createBrowserHistory} from 'history';
import {composeWithDevTools} from "redux-devtools-extension";

const env = process.env.NODE_ENV;
const history = createBrowserHistory();
const middlewares = [thunk, routerMiddleware(history)];

if (env === "development") {
    const {logger} = require("redux-logger");
    middlewares.push(logger);
}

const reducer = (history) => combineReducers({
    router: connectRouter(history),
    category,
});

let store;

if (env === "development") {
    store = initialState =>
        createStore(reducer(history), composeWithDevTools(applyMiddleware(...middlewares)));
} else {
    store = initialState => createStore(reducer(history), applyMiddleware(...middlewares));
}

export {history};

export default store();