import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {size} from '../../config/config';
import {Link} from 'react-router-dom';

CsBody.propTypes = {
    active: PropTypes.oneOf(['notice', 'faq', 'qna', 'companymanagement', 'partnership'])
};

function CsBody(props) {
    return (
        <StyledContainer>
            <StyledLink>
                <Link to="/notice" className={props.active === 'notice' ? 'active' : ''}>공지사항<div className="triangle"></div></Link>
                {/*<Link to="/faq">자주 묻는 질문<div className="triangle"></div></Link>*/}
                {/*<Link to="/qna">질문과 답변<div className="triangle"></div></Link>*/}
                {/*<Link to="/companymanagement">업체 등록 및 변경<div className="triangle"></div></Link>*/}
                <Link to="/partnership" className={props.active === 'partnership' ? 'active' : ''}>제휴문의<div className="triangle"></div></Link>
            </StyledLink>

            <StyledContents>
                {props.children}
            </StyledContents>
        </StyledContainer>
    );
}

const StyledContainer = styled.div`
    max-width: ${size.pcMaxWidth};
    margin: 0 auto;
`;

const StyledLink = styled.div`
    margin-top: 60px;
    display: flex;
    
    a {
        flex: 1;
        height: 60px;
        border: 1px solid #dfdfdf;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        color: #000;
        position: relative;
        
        &.active {
            color: #fff;
            background-color: #5fc148;
        }
        
        &.active .triangle {
            width: 0;
            height: 0;
            
            border-left: 9px solid transparent;
            border-right: 9px solid transparent;
            border-top: 9px solid #5fc148;
            
            position: absolute;
            bottom: -9px;
            left: 50%;
            transform: translateX(-50%);
        }
        
        &:hover {
            color: #fff;
            background-color: #5fc148;
            
            .triangle {
                width: 0;
                height: 0;
                
                border-left: 9px solid transparent;
                border-right: 9px solid transparent;
                border-top: 9px solid #5fc148;
                
                position: absolute;
                bottom: -9px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        
        &:first-child, &:nth-child(2), &:nth-child(3), &:nth-child(4) {
            border-right: 0;
        } 
    }
`;

const StyledContents = styled.div`
    margin-top: 30px;
    padding-bottom: 60px;
`;

export default CsBody;