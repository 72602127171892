import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Breadcrumb, Footer, Header, RecentView, ScrollTop, SliderBox} from '../components';
import pensionBg from '../assets/images/pension/bg.jpg';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import {useHistory, useParams} from 'react-router-dom';

// import allPensionData from '../dummy/allPensionData.json';
import {callApi} from '../api';

function Pension(props) {
    let {type} = useParams();

    const history = useHistory();

    const [allPensionData, setAllPensionData] = useState([]);

    useEffect(() => {
        if (type !== 'grid') {
            window.scrollTo(0, 0);
        }

        callApi('/company/숙박', 'GET', {}).then((res) => {
           if (res)
               setAllPensionData(res.list);
        }).catch((err) => {
            console.log(err);
        });
    }, [type]);

    const allPensionList = allPensionData.map((item, index) => {
        if (item.type === '숙박') {
            return (
                <SliderBox
                    key={item.id}
                    id={item.id}
                    link={item.homepage}
                    tag={[item.cate1[0], item.cate1[1], item.cate1[2]]}
                    price={item.price}
                    title={item.companyName}
                    images={item.thumb}
                />
            )
        } else {
            return null;
        }
    });

    // 지도에서 보기
    function handleClickMap() {
        history.push('/map');
    }

    const breadcrumb = [{
        id: 1,
        title: '펜션안내',
        link: '/pension'
    }];

    return (
        <>
            <Header />

            <StyledContainer>
                <div className="title-wrap">
                    <img src={pensionBg} alt="" />
                    <div className="title">
                        <span>나</span>에게 꼭 맞춘<br /> <span>남양주</span>펜션
                    </div>
                </div>

                <Breadcrumb data={breadcrumb} />

                <div className="category">
                    <div></div>
                    <div className="active">펜션안내</div>
                    <div></div>
                    <button onClick={handleClickMap}><MapOutlinedIcon /> 지도에서 보기</button>
                </div>

                <StyledCategoryContainer>
                    {allPensionList}
                </StyledCategoryContainer>
            </StyledContainer>

            <RecentView />

            <Footer />

            <ScrollTop />
        </>
    );
}

const StyledContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    
    br {
        display: none;
    }
    
    .title-wrap {
        position: relative;
        
        img {
            width: 100%;
        }
        
        .title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 45px;
            font-weight: 200;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
            
            span {
                font-weight: 600;
            }
        }        
    }
    
    .category {
        display: flex;
        justify-content: center;
        margin-top: 110px;
        margin-bottom: 30px;
        
        div {
            flex:1;
            max-width: 440px;
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            color: #434343;
            border-bottom: 2px solid #dcdcdc;
            
            &.active {
                max-width: 170px;
                border-bottom: 2px solid #f68b1f;
                padding-bottom: 12px;
            }
        }
        
        button {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            height: 30px;
            cursor: pointer;
            position: absolute;
            right: 10px;
            background-color: #a7be38;
            color: #fff;
            border: none;
            outline: none;
            
            &:hover {
                background-color: #86982d;
            }
        }
    }
    
    @media (max-width: 1461px) {
        .category {
            max-width: 1080px;
        }
    }
    
    @media (max-width: 1101px) {
        .category {
            max-width: 720px;
        }
    }
    
    @media (max-width: 940px) {
        margin: 60px auto 0;
    }
    
    @media (max-width: 741px) {
        .category {
            max-width: 360px;
            
            button {
                top: 0;
                font-size: 0px;
            }
        }
    }
    
    @media (max-width: 475px) {
        br {
            display: block;
        }
    
        .title-wrap {
            img {
                height: 300px;
                object-fit: cover;
            }
            
            .title {
                font-size: 29px;
                text-align: center;
                width: 85%;
            }
        }
        
        .category {
            margin-top: 45px;
            
            div {
                font-size: 19px;
            }
            
            button {
                top: -7px;
                font-size: 0px;
            }
        }
    }
`;

const StyledCategoryContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 30px auto 93px;
    max-width: 1440px;
    
    @media (max-width: 1460px) {
        max-width: 1080px;
    }
    
    @media (max-width: 1100px) {
        max-width: 720px;
    }
    
    @media (max-width: 740px) {
        max-width: 360px;
        margin: 0 auto 50px;
    }
`;

export default Pension;