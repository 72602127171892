import React, {useState, useEffect} from 'react';
import {Footer, Header, RecentView} from '../components';
import styled from 'styled-components';
import pensionBg from "../assets/images/pension/bg.jpg";
import GridOnOutlinedIcon from '@material-ui/icons/GridOnOutlined';
import {useHistory} from 'react-router-dom';
import '../assets/css/map.css';

import {callApi} from '../api';
// import mapData from '../dummy/mapData.json';

function Map(props) {
    let history = useHistory();

    function handleClickGrid() {
        history.push('/pension/grid');
    }

    const [pensionList, setPensionList] = useState([]);

    useEffect(() => {
        callApi('/company/숙박', 'GET', {}).then((res) => {
            if (res)
                setPensionList(res.list);
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    // 남양주시청 위도경도
    // const namCityLatLng = {Lat: 37.6359871, Lng: 127.216322};
    // 남양주시 호평동 위도경도
    // const namHopyeongDongLatLng = {Lat: 37.658271, Lng: 127.244648};

    const [kakaoMap, setKakaoMap] = useState();

    useEffect(() => {
        // 천마산 오른쪽
        const namCenter = {Lat: 37.67210898265679, Lng: 127.30224254147463}

        let mapContainer = document.getElementById('map'),
            mapOption = {
                center: new window.kakao.maps.LatLng(namCenter['Lat'], namCenter['Lng']),
                level: 8
            };

        let map = new window.kakao.maps.Map(mapContainer, mapOption);
        setKakaoMap(map);

        // 마커를 표시할 위치와 title 객체 배열
        let positions =
            pensionList.map((item, index) => {
                return (
                    {
                        title: item.companyName,
                        location: item.location,
                        id: item.id
                    }
                )
            })
        ;

        for (let i=0; i<positions.length; i++) {

            // 주소로 좌표 검색
            let geocoder = new window.kakao.maps.services.Geocoder();
            geocoder.addressSearch(positions[i].location, function(result, status) {
                // 검색 성공
                if (status === window.kakao.maps.services.Status.OK) {
                    let coords = new window.kakao.maps.LatLng(result[0].y, result[0].x);

                    let marker = new window.kakao.maps.Marker({
                        map: map,
                        position: coords,
                        title: positions[i].title
                    });

                    var content = '<div class="customoverlay">' +
                        '  <a href="/detail/' + positions[i].id + '">' +
                        '    <span class="title">' + positions[i].title + '</span>' +
                        '  </a>' +
                        '</div>';

                    let overlay = new window.kakao.maps.CustomOverlay({
                        content: content,
                        map: map,
                        position: marker.getPosition()
                    });

                    overlay.setMap(map);
                }
            });

        }

    }, [pensionList]);

    function zoomIn() {
        kakaoMap.setLevel(kakaoMap.getLevel() - 1);
    }

    function zoomOut() {
        kakaoMap.setLevel(kakaoMap.getLevel() + 1);
    }

    return (
        <>
            <Header />

            <StyledContainer>
                <div className="title-wrap">
                    <img src={pensionBg} alt="" />
                    <div className="title">
                        <span>나</span>에게 꼭 맞춘<br /> <span>남양주</span>펜션
                    </div>
                </div>

                <div className="category">
                    <div></div>
                    <div className="active">펜션안내</div>
                    <div></div>
                    <button onClick={handleClickGrid}><GridOnOutlinedIcon /> 그리드로 보기</button>
                </div>

                <StyledCategoryContainer>
                    <div id="map"></div>
                    <div className="custom_zoomcontrol radius_border">
                        <span onClick={zoomIn}><img src="https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/ico_plus.png" alt="확대" /></span>
                        <span onClick={zoomOut}><img src="https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/ico_minus.png" alt="축소" /></span>
                    </div>
                </StyledCategoryContainer>
            </StyledContainer>

            <RecentView />

            <Footer />
        </>
    );
}

const StyledContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    
    br {
        display: none;
    }
    
    .title-wrap {
        position: relative;
        
        img {
            width: 100%;
        }
        
        .title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 45px;
            font-weight: 200;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
            
            span {
                font-weight: 600;
            }
        }        
    }
    
    .category {
        display: flex;
        justify-content: center;
        margin-top: 110px;
        margin-bottom: 30px;
        
        div {
            flex:1;
            max-width: 440px;
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            color: #434343;
            border-bottom: 2px solid #dcdcdc;
            
            &.active {
                max-width: 170px;
                border-bottom: 2px solid #f68b1f;
                padding-bottom: 12px;
            }
        }
        
        button {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            height: 30px;
            cursor: pointer;
            position: absolute;
            right: 10px;
            background-color: #a7be38;
            color: #fff;
            border: none;
            outline: none;
            
            &:hover {
                background-color: #86982d;
            }
        }
    }
    
    @media (max-width: 1461px) {
        .category {
            max-width: 1080px;
        }
    }
    
    @media (max-width: 1101px) {
        .category {
            max-width: 720px;
        }
        
        #map {
            height: 500px !important;
        }
    }
    
    @media (max-width: 940px) {
        margin: 60px auto 0;
    }
    
    @media (max-width: 741px) {
        .category {
            max-width: 360px;
            
            button {
                top: 0;
                font-size: 0px;
            }
        }
        
        #map {
            height: 300px !important;
        }
    }
    
    @media (max-width: 475px) {
        br {
            display: block;
        }
    
        .title-wrap {
            img {
                height: 300px;
                object-fit: cover;
            }
            
            .title {
                font-size: 29px;
                text-align: center;
                width: 85%;
            }
        }
        
        .category {
            margin-top: 45px;
            
            div {
                font-size: 19px;
            }
            
            button {
                top: -7px;
                font-size: 0px;
            }
        }
    }
`;

const StyledCategoryContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 30px auto 93px;
    max-width: 1440px;
    position: relative;
    
    #map {
        width: 100%;
        height: 800px;
    }
    
    @media (max-width: 1460px) {
        max-width: 1080px;
    }
    
    @media (max-width: 1100px) {
        max-width: 720px;
    }
    
    @media (max-width: 740px) {
        max-width: 360px;
        margin: 0 auto 50px;
    }
`;

export default Map;