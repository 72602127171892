import React, {useEffect, useState} from 'react';
import {Logo} from './';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Link, useHistory} from 'react-router-dom';
import MoreIcon from '../assets/images/more-icon.png';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {useDispatch, useSelector} from "react-redux";
import {actionCreator as categoryAction} from "../redux/modules/category";
// import {push} from 'connected-react-router';

const pensionStyle = [
    {
        id: 1,
        title: '모던'
    },
    {
        id: 2,
        title: '유럽풍'
    },
    {
        id: 3,
        title: '한옥'
    },
    {
        id: 4,
        title: '황토'
    },
    {
        id: 5,
        title: '통나무'
    },
    {
        id: 6,
        title: '프로방스'
    },
    {
        id: 7,
        title: '워크샵'
    },
    {
        id: 8,
        title: '복층형'
    },
    {
        id: 9,
        title: '독채형'
    },
    {
        id: 10,
        title: '풀빌라/풀빌라독채'
    },
    {
        id: 11,
        title: '키즈컨셉'
    }
];

const pensionFacilities = [
    {
        id: 1,
        title: '수영장'
    },
    {
        id: 2,
        title: '독립수영장'
    },
    {
        id: 3,
        title: '유아용수영장'
    },
    {
        id: 4,
        title: '워터슬라이드'
    },
    {
        id: 5,
        title: '스파'
    },
    {
        id: 6,
        title: '월풀'
    },
    {
        id: 7,
        title: '노래방'
    },
    {
        id: 8,
        title: '잔디운동장'
    },
    {
        id: 9,
        title: '개별바베큐장'
    },
    {
        id: 10,
        title: '족구'
    },
    {
        id: 11,
        title: '농구장'
    },
    {
        id: 12,
        title: '탁구장'
    },
    {
        id: 13,
        title: '와이파이'
    }
];

const pensionTheme = [
    {
        id: 1,
        title: '커플'
    },
    {
        id: 2,
        title: '가족'
    },
    {
        id: 3,
        title: '단체'
    },
    {
        id: 4,
        title: '카페'
    },
    {
        id: 5,
        title: '키즈카페'
    },
    {
        id: 6,
        title: '계곡'
    },
    {
        id: 7,
        title: '이벤트가능'
    },
    {
        id: 8,
        title: '반려동물가능'
    },
    {
        id: 9,
        title: '체험'
    },
    {
        id: 10,
        title: '벽난로'
    },
    {
        id: 11,
        title: '산책로'
    },
    {
        id: 12,
        title: '찜질방'
    },
    {
        id: 13,
        title: '캠프파이어/모닥불'
    },
    {
        id: 14,
        title: '텃밭'
    }
];

function Header_bak(props) {
    const [moreOpen, setMoreOpen] = useState(false);

    function handleHoverButton() {
        setMoreOpen(true);
    }

    function handleHoverOut() {
        setMoreOpen(false);
    }

    // 여행정보 서브 메뉴 hover
    const [tourSubMenuOpen, setTourSubMenuOpen] = useState(false);

    function handleHoverTour() {
        setTourSubMenuOpen(true);
        handleLeaveStay();
        handleLeaveFarm();
    }

    function handleLeaveTour() {
        setTourSubMenuOpen(false);
    }

    // 민박협회 서브 메뉴 hover
    const [staySubMenuOpen, setStaySubMenuOpen] = useState(false);

    function handleHoverStay() {
        handleLeaveTour();
        handleLeaveFarm();
        setStaySubMenuOpen(true);
    }

    function handleLeaveStay() {
        setStaySubMenuOpen(false);
    }

    // 농촌체험 서브 메뉴 hover
    const [farmSubMenuOpen, setFarmSubMenuOpen] = useState(false);

    function handleHoverFarm() {
        handleLeaveTour();
        handleLeaveStay();
        setFarmSubMenuOpen(true);
    }

    function handleLeaveFarm() {
        setFarmSubMenuOpen(false);
    }

    // 모바일 메뉴 버튼 클릭
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    function handleClickMobileNav() {
        setMobileMenuOpen(!mobileMenuOpen);
    }

    // 모바일 메뉴별 상태
    const [mobileTourMenu, setMobileTourMenu] = useState(true);
    const [mobilePensionMenu, setMobilePensionMenu] = useState(true);
    const [mobileCourseMenu, setMobileCourseMenu] = useState(true);
    const [mobileFarmMenu, setMobileFarmMenu] = useState(true);
    const [mobileMountainMenu, setMobileMountainMenu] = useState(true);
    const [mobileStayMenu, setMobileStayMenu] = useState(true);
    const [mobileMoreMenu, setMobileMoreMenu] = useState(true);

    const [mobileTourMenuExpand, setMobileTourMenuExpand] = useState(false);
    const [mobileStayMenuExpand, setMobileStayMenuExpand] = useState(false);
    const [mobileMoreMenuExpand, setMobileMoreMenuExpand] = useState(false);
    const [mobileFarmMenuExpand, setMobileFarmMenuExpand] = useState(false);

    // 모바일 여행정보 메뉴 클릭
    function handleClickMobileTour(event) {
        event.preventDefault();

        if (!mobileTourMenuExpand) {
            setMobileTourMenuExpand(!mobileTourMenuExpand);
            setMobileTourMenu(true);

            setMobilePensionMenu(false);
            setMobileCourseMenu(false);
            setMobileFarmMenu(false);
            setMobileMountainMenu(false);
            setMobileStayMenu(false);
            setMobileMoreMenu(false);
        } else {
            setMobileTourMenuExpand(!mobileTourMenuExpand);

            setMobileTourMenu(true);
            setMobilePensionMenu(true);
            setMobileCourseMenu(true);
            setMobileFarmMenu(true);
            setMobileMountainMenu(true);
            setMobileStayMenu(true);
            setMobileMoreMenu(true);
        }
    }

    // 모바일 민박협회 메뉴 클릭
    function handleClickMobileStay(event) {
        event.preventDefault();

        if (!mobileStayMenuExpand) {
            setMobileStayMenuExpand(!mobileStayMenuExpand);
            setMobileStayMenu(true);

            setMobileTourMenu(false);
            setMobilePensionMenu(false);
            setMobileCourseMenu(false);
            setMobileFarmMenu(false);
            setMobileMountainMenu(false);
            setMobileMoreMenu(false);
        } else {
            setMobileStayMenuExpand(!mobileStayMenuExpand);

            setMobileTourMenu(true);
            setMobilePensionMenu(true);
            setMobileCourseMenu(true);
            setMobileFarmMenu(true);
            setMobileMountainMenu(true);
            setMobileStayMenu(true);
            setMobileMoreMenu(true);
        }
    }

    // 모바일 상세검색 메뉴 클릭
    function handleClickMobileMore(event) {
        event.preventDefault();

        if (!mobileMoreMenuExpand) {
            setMobileMoreMenuExpand(!mobileMoreMenuExpand);
            setMobileMoreMenu(true);

            setMobileTourMenu(false);
            setMobilePensionMenu(false);
            setMobileCourseMenu(false);
            setMobileFarmMenu(false);
            setMobileMountainMenu(false);
            setMobileStayMenu(false);
        } else {
            setMobileMoreMenuExpand(!mobileMoreMenuExpand);

            setMobileTourMenu(true);
            setMobilePensionMenu(true);
            setMobileCourseMenu(true);
            setMobileFarmMenu(true);
            setMobileMountainMenu(true);
            setMobileStayMenu(true);
            setMobileMoreMenu(true);
        }
    }

    // 모바일 농촌체험 메뉴 클릭
    function handleClickMobileFarm(event) {
        event.preventDefault();

        if (!mobileFarmMenuExpand) {
            setMobileFarmMenuExpand(!mobileFarmMenuExpand);
            setMobileFarmMenu(true);

            setMobileTourMenu(false);
            setMobilePensionMenu(false);
            setMobileCourseMenu(false);
            setMobileMountainMenu(false);
            setMobileStayMenu(false);
            setMobileMoreMenu(false);
        } else {
            setMobileFarmMenuExpand(!mobileFarmMenuExpand);

            setMobileTourMenu(true);
            setMobilePensionMenu(true);
            setMobileCourseMenu(true);
            setMobileMountainMenu(true);
            setMobileStayMenu(true);
            setMobileMoreMenu(true);
        }
    }

    // 체크박스 리스트
    // const [options, setOptions] = useState([]);

    const dispatch = useDispatch();
    // 카테고리
    const category = useSelector((store) => store.category);
    let history = useHistory();

    // 체크박스 선택
    function handleChangeCheckbox(event, title) {
        if (event.target.checked) {
            // 카테고리 리덕스 저장
            dispatch(categoryAction.addCategory(title));
        } else {
            // 카테고리 리덕스 삭제
            dispatch(categoryAction.delCategory(title));
        }
    }

    // 검색하기
    function handleClickSearch() {
        if (mobileMenuOpen) {
            setMobileMenuOpen(!mobileMenuOpen);
        }

        if (moreOpen) {
            setMoreOpen(false);
        }

        history.push('/search');
    }

    // 모바일 메뉴 오픈 시 body 스크롤 숨기기
    useEffect(() => {
        if (mobileMenuOpen) {
            document.body.style.overflow = 'hidden';
            document.body.style.paddingRight = '15px';
        } else {
            document.body.style.overflow = 'auto';
            document.body.style.paddingRight = '0';
        }
    }, [mobileMenuOpen]);

    return (
        <>
            <StyledContainer>
                <StyledWrapper maxWidth={props.maxWidth}>
                    <Logo/>

                    <StyledMenu onMouseOver={handleHoverOut}>
                        <ul className="depth1-ul">
                            <li className="depth1-li">
                                <Link to="/tour" onMouseOver={handleHoverTour}>여행정보</Link>
                                <StyledSubMenuTour open={tourSubMenuOpen} onMouseLeave={handleLeaveTour}>
                                    <div className="box-wrap">
                                        <div>
                                            <Link to="/tourlist/자연관광" className="title">자연관광</Link>
                                            <div>
                                                <div><Link to="/tourlist2/자연">· 자연</Link></div>
                                                <div><Link to="/tourlist2/수목원">· 수목원</Link></div>
                                                <div><Link to="/tourlist2/공원">· 공원</Link></div>
                                            </div>
                                        </div>
                                        <div>
                                            <Link to="/tourlist/문화관광" className="title">문화관광</Link>
                                            <div>
                                                <div><Link to="/tourlist2/볼거리">· 볼거리</Link></div>
                                                <div><Link to="/tourlist2/사찰">· 사찰</Link></div>
                                                <div><Link to="/tourlist2/문화재">· 문화재</Link></div>
                                                <div><Link to="/tourlist2/장터">· 장터</Link></div>
                                            </div>
                                        </div>
                                        <div>
                                            <Link to="/tourlist/어린이" className="title">어린이</Link>
                                        </div>
                                        <div>
                                            <Link to="/tourlist/레저관광" className="title">레저관광</Link>
                                        </div>
                                    </div>

                                    <div className="box-wrap">
                                        <div>
                                            <Link to="/tourlist/축제" className="title">축제</Link>
                                            <div>
                                                <div><Link to="/tourdetail2/festival/1">· 정약용문화제</Link></div>
                                                <div><Link to="/tourdetail2/festival/2">· 광릉숲축제</Link></div>
                                                <div><Link to="/tourdetail2/festival/3">· 천마산소나무축제</Link></div>
                                                <div><Link to="/tourdetail2/festival/4">· 왕숙천문화제</Link></div>
                                                <div><Link to="/tourdetail2/festival/5">· 봉선사연꽃축제</Link></div>
                                            </div>
                                        </div>
                                        <div>
                                            <Link to="/tourlist/공연" className="title">공연</Link>
                                            <div>
                                                <div><Link to="/tourdetail2/show/1">· 북한강문화나들이</Link></div>
                                            </div>
                                        </div>
                                        <div>
                                            <Link to="/tourdetail2/citytour/1" className="title">남양주시티투어</Link>
                                        </div>
                                        <div>
                                            <Link to="/tourdetail2/restaurant/1" className="title">모범음식점</Link>
                                        </div>
                                        <div>
                                            <Link to="/tour" className="title">관광안내책자</Link>
                                        </div>
                                    </div>
                                </StyledSubMenuTour>
                            </li>
                            <li className="depth1-li">
                                <Link to="/pension" onMouseOver={handleLeaveTour}>펜션안내</Link>
                            </li>
                            <li className="depth1-li"><Link to="/course" onMouseOver={handleLeaveTour}>코스별숙박안내</Link></li>
                            <li className="depth1-li">
                                <Link to="/farm" onMouseOver={handleHoverFarm}>농촌체험</Link>
                                <StyledSubMenu open={farmSubMenuOpen} onMouseLeave={handleLeaveFarm}>
                                    <div className="box-wrap">
                                        <div>
                                            <Link to="/strawberry" className="title">딸기체험</Link>
                                        </div>
                                        <div>
                                            <Link to="/farm" className="title">농업체험</Link>
                                        </div>
                                    </div>
                                </StyledSubMenu>
                            </li>
                            <li className="depth1-li"><Link to="/mountain" onMouseOver={handleLeaveTour}>등산안내</Link></li>
                            <li className="depth1-li">
                                <Link to="/greetings" onMouseOver={handleHoverStay}>민박협회가입회원</Link>
                                <StyledSubMenuStay open={staySubMenuOpen} onMouseLeave={handleLeaveStay}>
                                    <div className="box-wrap">
                                        <div>
                                            <Link to="/greetings" className="title">인사말</Link>
                                        </div>
                                        <div>
                                            <Link to="/orginfo" className="title">조직도</Link>
                                        </div>
                                        <div>
                                            <Link to="/join" className="title">가입신청</Link>
                                        </div>
                                        <div>
                                            <Link to="#" className="title">협회회원현황</Link>
                                        </div>
                                        <div>
                                            <Link to="/board/notice" className="title">게시판</Link>
                                            <div>
                                                <div><Link to="/board/notice">· 공지사항</Link></div>
                                                <div><Link to="/board/free">· 자유게시판</Link></div>
                                                <div><Link to="/board/member">· 협회원전용</Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </StyledSubMenuStay>
                            </li>
                        </ul>
                    </StyledMenu>

                    <div>
                        <StyledMoreButton onMouseOver={handleHoverButton}>상세검색 <img src={MoreIcon}
                                                                                    alt="more icon"/></StyledMoreButton>
                    </div>

                    {
                        moreOpen &&
                        <StyledMoreContainer onMouseLeave={handleHoverOut}>
                            <div className="category-wrap">
                                <div className="category-title">펜션 스타일</div>
                                <div>
                                    <ul>
                                        {
                                            pensionStyle.map((item, index) => {
                                                return (
                                                    <li key={item.id}>
                                                        <StyledLabel>
                                                            <input type="checkbox"
                                                                   checked={!!category.category.find((cate) => cate === item.title)}
                                                                   onChange={(event) => handleChangeCheckbox(event, item.title)}/>
                                                            <span></span>
                                                            {item.title}
                                                        </StyledLabel>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>

                            <div className="category-wrap">
                                <div className="category-title">펜션 편의시설</div>
                                <div>
                                    <ul>
                                        {
                                            pensionFacilities.map((item, index) => {
                                                return (
                                                    <li key={item.id}>
                                                        <StyledLabel>
                                                            <input type="checkbox"
                                                                   checked={!!category.category.find((cate) => cate === item.title)}
                                                                   onChange={(event) => handleChangeCheckbox(event, item.title)}/>
                                                            <span></span>
                                                            {item.title}
                                                        </StyledLabel>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>

                            <div className="category-wrap last-category-wrap">
                                <div className="category-title">펜션 테마</div>
                                <div>
                                    <ul>
                                        {
                                            pensionTheme.map((item, index) => {
                                                return (
                                                    <li key={item.id}>
                                                        <StyledLabel>
                                                            <input type="checkbox"
                                                                   checked={!!category.category.find((cate) => cate === item.title)}
                                                                   onChange={(event) => handleChangeCheckbox(event, item.title)}/>
                                                            <span></span>
                                                            {item.title}
                                                        </StyledLabel>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>

                            <div>
                                <button onClick={handleClickSearch}><span>검색하기</span></button>
                            </div>
                        </StyledMoreContainer>
                    }

                    <StyledMobileButton onClick={handleClickMobileNav}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </StyledMobileButton>
                </StyledWrapper>

                <StyledSideNav
                    open={mobileMenuOpen}
                    menuonTour={mobileTourMenu}
                    menuonPension={mobilePensionMenu}
                    menuonCourse={mobileCourseMenu}
                    menuonFarm={mobileFarmMenu}
                    menuonMountain={mobileMountainMenu}
                    menuonStay={mobileStayMenu}
                    menuonMore={mobileMoreMenu}
                    mobileTourMenuExpand={mobileTourMenuExpand}
                    mobileStayMenuExpand={mobileStayMenuExpand}
                    mobileMoreMenuExpand={mobileMoreMenuExpand}
                    mobileFarmMenuExpand={mobileFarmMenuExpand}
                >
                    <div className="link-box tour">
                        <Link to="#" onClick={(event) => handleClickMobileTour(event)}>여행정보
                            {
                                mobileTourMenuExpand ? <ExpandLessIcon/> : <ExpandMoreIcon/>
                            }
                        </Link>
                        <div className="sub-menu">
                            <Link to="/tourlist/자연관광" className="title underline" onClick={() => setMobileMenuOpen(false)}>자연관광</Link>
                            <div>
                                <div><Link to="/tourlist2/자연" onClick={() => setMobileMenuOpen(false)} className="underline">· 자연</Link></div>
                                <div><Link to="/tourlist2/수목원" onClick={() => setMobileMenuOpen(false)} className="underline">· 수목원</Link></div>
                                <div><Link to="/tourlist2/공원" onClick={() => setMobileMenuOpen(false)} className="underline">· 공원</Link></div>
                            </div>
                        </div>
                        <div className="sub-menu">
                            <Link to="/tourlist/문화관광" className="title underline" onClick={() => setMobileMenuOpen(false)}>문화관광</Link>
                            <div>
                                <div><Link to="/tourlist2/볼거리" className="underline" onClick={() => setMobileMenuOpen(false)}>· 볼거리</Link></div>
                                <div><Link to="/tourlist2/사찰" className="underline" onClick={() => setMobileMenuOpen(false)}>· 사찰</Link></div>
                                <div><Link to="/tourlist2/문화재" className="underline" onClick={() => setMobileMenuOpen(false)}>· 문화재</Link></div>
                                <div><Link to="/tourlist2/장터" className="underline" onClick={() => setMobileMenuOpen(false)}>· 장터</Link></div>
                            </div>
                        </div>
                        <div className="sub-menu">
                            <Link to="/tourlist/어린이" className="title underline" onClick={() => setMobileMenuOpen(false)}>어린이</Link>
                        </div>
                        <div className="sub-menu">
                            <Link to="/tourlist/레저관광" className="title underline" onClick={() => setMobileMenuOpen(false)}>레저관광</Link>
                        </div>
                        <div className="sub-menu">
                            <Link to="/tourlist/축제" className="title underline" onClick={() => setMobileMenuOpen(false)}>축제</Link>
                            <div>
                                <div><Link to="/tourdetail2/festival/1" className="underline" onClick={() => setMobileMenuOpen(false)}>· 정약용문화제</Link></div>
                                <div><Link to="/tourdetail2/festival/2" className="underline" onClick={() => setMobileMenuOpen(false)}>· 광릉숲축제</Link></div>
                                <div><Link to="/tourdetail2/festival/3" className="underline" onClick={() => setMobileMenuOpen(false)}>· 천마산소나무축제</Link></div>
                                <div><Link to="/tourdetail2/festival/4" className="underline" onClick={() => setMobileMenuOpen(false)}>· 왕숙천문화제</Link></div>
                                <div><Link to="/tourdetail2/festival/5" className="underline" onClick={() => setMobileMenuOpen(false)}>· 봉선사연꽃축제</Link></div>
                            </div>
                        </div>
                        <div className="sub-menu">
                            <Link to="/tourlist/공연" className="title underline" onClick={() => setMobileMenuOpen(false)}>공연</Link>
                            <div>
                                <div><Link to="/tourdetail2/show/1" className="underline" onClick={() => setMobileMenuOpen(false)}>· 북한강문화나들이</Link></div>
                            </div>
                        </div>
                        <div className="sub-menu">
                            <Link to="/tourdetail2/citytour/1" className="title underline" onClick={() => setMobileMenuOpen(false)}>남양주시티투어</Link>
                        </div>
                        <div className="sub-menu">
                            <Link to="/tourdetail2/restaurant/1" className="title underline" onClick={() => setMobileMenuOpen(false)}>모범음식점</Link>
                        </div>
                        <div className="sub-menu">
                            <Link to="/tour" className="title" onClick={() => setMobileMenuOpen(false)}>관광안내책자</Link>
                        </div>
                    </div>
                    <div className="link-box pension">
                        <Link to="/pension">펜션안내</Link>
                    </div>
                    <div className="link-box course">
                        <Link to="/course">코스별숙박안내</Link>
                    </div>
                    <div className="link-box farm">
                        <Link to="#" onClick={(event) => handleClickMobileFarm(event)}>농촌체험
                            {
                                mobileFarmMenuExpand ? <ExpandLessIcon /> : <ExpandMoreIcon />
                            }
                        </Link>
                        <div className="sub-menu">
                            <Link to="/strawberry" className="title underline">딸기체험</Link>
                        </div>
                        <div className="sub-menu">
                            <Link to="/farm" className="title underline">농업체험</Link>
                        </div>
                    </div>
                    <div className="link-box mountain">
                        <Link to="/mountain">등산안내</Link>
                    </div>
                    <div className="link-box stay">
                        <Link to="#" onClick={(event) => handleClickMobileStay(event)}>민박협회가입회원
                            {
                                mobileStayMenuExpand ? <ExpandLessIcon /> : <ExpandMoreIcon />
                            }
                        </Link>
                        <div className="sub-menu">
                            <Link to="/greetings" className="title underline">인사말</Link>
                        </div>
                        <div className="sub-menu">
                            <Link to="/orginfo" className="title underline">조직도</Link>
                        </div>
                        <div className="sub-menu">
                            <Link to="/join" className="title underline">가입신청</Link>
                        </div>
                        <div className="sub-menu">
                            <Link to="#" className="title underline">협회회원현황</Link>
                        </div>
                        <div className="sub-menu">
                            <Link to="#" className="title underline">게시판</Link>
                            <div>
                                <div><Link to="/board/notice" className="underline">· 공지사항</Link></div>
                                <div><Link to="/board/free" className="underline">· 자유게시판</Link></div>
                                <div><Link to="/board/member">· 협회원전용</Link></div>
                            </div>
                        </div>
                    </div>
                    <div className="more-link-box more">
                        <Link to="#" onClick={(event) => handleClickMobileMore(event)}>상세검색
                            {
                                mobileMoreMenuExpand ? <ExpandLessIcon/> : <ExpandMoreIcon/>
                            }
                        </Link>
                        <div className="sub-menu">
                            <div className="title">펜션 스타일</div>
                            <div className="checkbox-wrap">
                                {
                                    pensionStyle.map((item, index) => {
                                        return (
                                            <div key={item.id}>
                                                <StyledLabel2>
                                                    <input type="checkbox"
                                                        checked={!!category.category.find((cate) => cate === item.title)}
                                                        onChange={(event) => handleChangeCheckbox(event, item.title)}
                                                    />
                                                    <span></span>
                                                    {item.title}
                                                </StyledLabel2>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            <div className="title">펜션 편의시설</div>
                            <div className="checkbox-wrap">
                                {
                                    pensionFacilities.map((item, index) => {
                                        return (
                                            <div key={item.id}>
                                                <StyledLabel2>
                                                    <input type="checkbox"
                                                           checked={!!category.category.find((cate) => cate === item.title)}
                                                           onChange={(event) => handleChangeCheckbox(event, item.title)}
                                                    />
                                                    <span></span>
                                                    {item.title}
                                                </StyledLabel2>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            <div className="title">펜션 테마</div>
                            <div className="checkbox-wrap">
                                {
                                    pensionTheme.map((item, index) => {
                                        return (
                                            <div key={item.id}>
                                                <StyledLabel2>
                                                    <input type="checkbox"
                                                           checked={!!category.category.find((cate) => cate === item.title)}
                                                           onChange={(event) => handleChangeCheckbox(event, item.title)}
                                                    />
                                                    <span></span>
                                                    {item.title}
                                                </StyledLabel2>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            <div className="button-wrap">
                                <div>

                                </div>
                                <div>
                                    <button onClick={handleClickSearch}>검색하기</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </StyledSideNav>
                <StyledSideBG open={mobileMenuOpen}/>
            </StyledContainer>
        </>
    );
}

Header_bak.defaultProps = {
    isSearch: true
};

Header_bak.propTypes = {
    isSearch: PropTypes.bool
};

const StyledContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    height: 80px;
    border-bottom: 1px solid #dfdfdf;
    
    @media (max-width: 1250px) {
        padding: 0 20px;
    }
    
    @media (max-width: 940px) {
        height: 60px;
        padding: 0 20px;
        
        position: fixed;
        top: 0;
        left: 0;
        background-color: #fff;
        z-index: 10;
    }
`;

const StyledWrapper = styled.div`
    width: 100%;
    height: 80px;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    
    @media (max-width: 940px) {
        height: 60px;
    }
`;

const StyledMenu = styled.div`
    width: 100%;

    .depth1-ul {
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: space-around;
        padding: 0 50px;
        
        .depth1-li {
            display: inline-block;
            position: relative;
            
            & > a:hover {
                color: #a7be38;
            }
        }
    }
    
    @media (max-width: 940px) {
        display: none;
    }
`;

const StyledMoreButton = styled.button`
    width: 100px;
    height: 80px;
    padding: 0;
    background-color: #a7be38;
    border: none;
    outline: none;
    color: #fff;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    img {
        margin-top: 3px;
        margin-left: 5px;
    }
    
    @media (max-width: 940px) {
        display: none;
    }
`;

const StyledMoreContainer = styled.div`
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 1920px;
    height: 590px;
    background-color: rgba(135, 148, 56, 0.95);
    color: #fff;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .category-wrap {
        width: 100%;
        max-width: 1114px;
        height: 146px;
        border-bottom: 1px solid #c3ca9c;
        display: flex;
        align-items: center;
        
        .category-title {
            font-size: 20px;
            font-weight: 300;
            min-width: 172px;
            height: 76px;
            display: flex;
            align-items: center;
            padding-left: 27px;
        }
        
        ul {
            border-left: 1px solid #c3ca9c;
            list-style: none;
            max-width: 940px;
            height: 76px;
            padding: 0 0 0 50px;
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            
            li {
                display: inline-block;
                width: 155px;
                font-size: 15px;
                font-weight: 300;
                margin-bottom: 15px;
                
                &:nth-child(6n) {
                    width: 110px;
                }
            }
        }
    }
    
    .last-category-wrap {
        border-bottom: none;    
    }
    
    button {
        margin-top: 30px;
        width: 190px;
        height: 50px;
        background-color: #fff;
        font-size: 18px;
        color: #434343;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        cursor: pointer;
        border: none;
        padding: 0;
        box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.15);
        
        span {
            margin-bottom: 4px;
        }
    }
`;

const StyledLabel = styled.label`
    cursor: pointer;
    user-select: none;
    position: relative;
    padding-left: 7px;

    input[type="checkbox"] {
        visibility: hidden;
        
        &:checked + span {
            background-color: #66bb6a;
            border-color: #66bb6a;
        }
        
        &:checked + span:after {
            opacity: 1;
        }
    }
    
    span {
        position: absolute;
        top: 4px;
        left: 0;
        display: inline-block;
        width: 14px;
        height: 14px;
        background-color: #fff;
        border: 1px solid #fff;
        border-radius: 50%;
        
        &:after {
            content: "";
            width: 6px;
            height: 3px;
            border: 2px solid #fff;
            border-top: none;
            border-right: none;
            transform: rotate(-45deg);
            position: absolute;
            top: 3px;
            left: 2px;
            opacity: 0;
        }
    }
`;

const StyledLabel2 = styled.label`
    cursor: pointer;
    user-select: none;
    position: relative;
    padding-left: 7px;
    color: #7d7d7d;

    input[type="checkbox"] {
        visibility: hidden;
        
        &:checked + span {
            background-color: #66bb6a;
            border-color: #66bb6a;
        }
        
        &:checked + span:after {
            opacity: 1;
        }
    }
    
    span {
        position: absolute;
        top: 4px;
        left: 0;
        display: inline-block;
        width: 14px;
        height: 14px;
        background-color: #fff;
        border: 1px solid #d3d3d3;
        border-radius: 50%;
        
        &:after {
            content: "";
            width: 6px;
            height: 3px;
            border: 2px solid #fff;
            border-top: none;
            border-right: none;
            transform: rotate(-45deg);
            position: absolute;
            top: 3px;
            left: 2px;
            opacity: 0;
        }
    }
`;

const StyledSubMenu = styled.div`
    position: absolute;
    min-width: 165px;
    top: 49px;
    left: -41px;
    z-index: 10;
    background-color: rgba(135, 148, 56, 0.95);
    color: #fff;
    margin: 0 auto;
    padding: 20px 10px 10px;
    
    display: ${props => props.open ? 'flex' : 'none'};
    justify-content: space-around;
    
    .box-wrap {
        & > div {
            width: 145px;
            margin-bottom: 20px;
            
            & > div > div {
                margin-bottom: 10px;
            }
        }
    }
    
     .title {
        display: flex;
        margin-bottom: 10px;
        padding-bottom: 5px;
        border-bottom: 1px solid #fff;
    }
    
    a:hover {
        color: yellowgreen;
    }
`;

const StyledSubMenuTour = styled.div`
    position: absolute;
    top: 49px;
    left: -41px;
    z-index: 10;
    width: 380px;
    background-color: rgba(135, 148, 56, 0.95);
    color: #fff;
    margin: 0 auto;
    padding: 20px 10px 10px;
    
    display: ${props => props.open ? 'flex' : 'none'};
    justify-content: space-around;
    
    .box-wrap {
        & > div {
            width: 145px;
            margin-bottom: 20px;
            
            & > div > div {
                margin-bottom: 10px;
            }
        }
    }
    
     .title {
        display: flex;
        margin-bottom: 10px;
        padding-bottom: 5px;
        border-bottom: 1px solid #fff;
    }
    
    a:hover {
        color: yellowgreen;
    }
`;

const StyledSubMenuStay = styled.div`
    position: absolute;
    top: 49px;
    left: -41px;
    z-index: 10;
    width: 185px;
    background-color: rgba(135, 148, 56, 0.95);
    color: #fff;
    margin: 0 auto;
    padding: 20px 10px 10px;
    
    display: ${props => props.open ? 'flex' : 'none'};
    justify-content: space-around;
    
    .box-wrap {
        & > div {
            width: 145px;
            margin-bottom: 20px;
            
            & > div > div {
                margin-bottom: 10px;
            }
        }
    }
    
     .title {
        display: flex;
        margin-bottom: 10px;
        padding-bottom: 5px;
        border-bottom: 1px solid #fff;
    }
    
    a:hover {
        color: yellowgreen;
    }
`;

const StyledMobileButton = styled.div`
    width: 31px;
    height: 28px;
    padding: 5px;
    display: none;

    span {
        display: block;
        width: 21px;
        border-bottom: 2px solid #434343;
        margin-bottom: 6px;
        
        &:last-child {
            margin-bottom: 0;
        }
    }
    
    cursor: pointer;
    
    @media (max-width: 940px) {
        display: block;
    }
`;

const StyledSideNav = styled.div`
    position: fixed;
    top: 60px;
    right: 0;
    z-index: 2;
    background-color: #fff;
    
    width: ${props => props.open ? '88%' : '0'};
    height: calc(100% - 60px);
    overflow-x: hidden;
    transition: 0.5s;
    
    .link-box {
        border-bottom: 1px solid #e5e5e5;
        color: #7d7d7d;
        font-size: 15px;
        
        a {
            display: flex;
            align-items: center;
            height: 60px;
            padding-left: 41px;
            
            svg {
                margin-top: 3px;
            }
        }
        
        .sub-menu {
            padding-left: 30px;
            
            .title {
                font-weight: bold;
            }
            
            .underline {
                border-bottom: 1px solid #e5e5e5;
            }
            
           
        }
        
        & a:hover {
            color: #000;
        }
        
        &.tour {
             display: ${props => props.menuonTour ? 'block' : 'none'};
             
             .sub-menu {
                display: ${props => props.mobileTourMenuExpand ? 'block' : 'none'};
             }
        }
        
        &.pension {
            display: ${props => props.menuonPension ? 'block' : 'none'};
        }
        
        &.course {
            display: ${props => props.menuonCourse ? 'block' : 'none'};
        }
        
        &.farm {
            display: ${props => props.menuonFarm ? 'block' : 'none'};
            
            .sub-menu {
                display: ${props => props.mobileFarmMenuExpand ? 'block' : 'none'};
            }
        }
        
        &.mountain {
            display: ${props => props.menuonMountain ? 'block' : 'none'};
        }
        
        &.stay {
            display: ${props => props.menuonStay ? 'block' : 'none'};
            
            .sub-menu {
                display: ${props => props.mobileStayMenuExpand ? 'block' : 'none'};
             }
        }
    }
    
    .more-link-box {
        background-color: #a7be38;
        border-bottom: 1px solid #e5e5e5;
        color: #fff;
        font-size: 15px;
        
        a {
            display: flex;
            align-items: center;
            height: 60px;
            padding-left: 41px;
            
            svg {
                margin-top: 3px;
            }
        }
        
        & a:hover {
            color: dimgrey;
        }
        
        &.more {
            display: ${props => props.menuonMore ? 'block' : 'none'};
            
            .sub-menu {
                display: ${props => props.mobileMoreMenuExpand ? 'block' : 'none'};
                background-color: #fff;
                
                .title {
                    background-color: #e5e5e5;
                    font-size: 14px;
                    color: #313131;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    padding-left: 40px;
                }
                
                .checkbox-wrap {
                    display: flex;
                    flex-wrap: wrap;
                    border-bottom: 1px solid #e5e5e5;
                    
                    & > div {
                        width: 50%;
                        align-items: center;
                        display: flex;
                        height: 40px;
                        padding-left: 19px;
                        
                        &:first-child, &:nth-child(n+2) {
                            border-right: 1px solid #e5e5e5;
                        }
                        
                        border-bottom: 1px solid #e5e5e5;
                        
                        &:last-child, &:nth-last-child(1) {
                            border-bottom: none;
                        }
                    }
                }
                
                .button-wrap {
                    display: flex;

                    & > div {
                        flex: 1;
                        height: 60px;
                    
                        button {
                            background-color: #a7be38;
                            color: #fff;
                            width: 100%;
                            height: 100%;
                            outline: none;
                            border: none;
                            padding: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                            font-size: 15px;
                        }
                    }
                }
             }
        }
    }
    
    @media (min-width: 941px) {
        display: none;
    }
`;

const StyledSideBG = styled.div`
    position: fixed;
    top: 60px;
    right: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.4);
    width: ${props => props.open ? '100%' : '0'};
    height: 100%;
    transition: 0.5s;
    
    @media (min-width: 941px) {
        display: none;
    }
`;

export default Header_bak;