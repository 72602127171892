import axios from 'axios';
import Cookies from 'universal-cookie';
import {toast} from "react-toastify";

// const baseURL = process.env.REACT_APP_API_HOST + '/v1';
const baseURL = 'https://api.nyjstory.kr/v1';
// const baseURL = 'http://local.nyjstory.kr/v1';
// const baseURL = 'http://localhost:5000/v1';

export function getToken() {
    let cookie = new Cookies();
    var token = cookie.get('token');
    // var token = 'tokentest';
    // headers: { 'Authorization': token },

    if (!token) {
        return null;
    }
    return token;
}

export function consolemsg(dir, msg) {
    if (getHttp() === 'prod') {
        return;
    }

    console.log(dir);
    console.log(msg);
}

export function getHttp() {
    let mode = '';
    if (process.env.NODE_ENV === "production") {
        mode = 'prod';
    } else if (process.env.NODE_ENV === "development") {
        mode = 'stage';
    } else {
        mode = 'local';
    }

    return mode;
}

export function getUrl() {
    let mode = getHttp();

    if (mode === 'stage') {
        return baseURL;
    } else if (mode === 'prod') {
        return baseURL;
    } else if (mode === 'test') {
        return baseURL;
    } else {
        return baseURL;
        // return 'http://localhost:5000/v1';
    }
}

/**
 *
 * 버전 체크
 * 페이지 진입 여부, 모달 공지 여부, 공지 내용 받아온다.
 *
 * **/
export async function versionCheck() {
    const verData = await axios.get(getUrl() + `/version`);

    console.log('api 버전 호출');
    console.log(verData);
    return verData.data;
}

/**
 *
 * api 요청
 * 토큰 불러오고(쿠키에 저장됨), axios 사용했음
 *
 * **/
export async function callApi(uri, type, params, isFile = false) {

    try {
        let token = getToken();

        // if (token == null) {
        //     return;
        // }

        // console.log(params.empty);

        let apiSetting = {};

        let mode = getHttp()
        let url = getUrl() + uri;

        if (type === 'GET') {
            apiSetting = {
                method: type,
                // headers: {'content-type': 'application/x-www-form-urlencoded', 'Authorization': token},
                headers: token ? {
                    'content-type': 'application/json',
                    'Authorization': token
                } : {'content-type': 'application/json'},
                params: params === undefined || params.empty ? JSON.stringify(params) : null,
                url,
            };
        } else if (type === 'JSONPOST') {
            if (token)
                axios.defaults.headers.common['Authorization'] = token;

            return axios.post(url, params);
        } else if (type === 'LOGIN') {
            apiSetting = {
                method: 'POST',
                headers: {'content-type': 'application/json'},
                params: params,
                url,
            };
        } else {
            apiSetting = {
                method: type,
                headers: !isFile ? {
                    'content-type': 'application/json',
                    'Authorization': token
                } : {
                    'content-type': 'multipart/formed-data',
                    'Authorization': token
                },
                // data: qs.stringify(params),
                data: params,
                url,
            };
        }


        // if (func !== '') {
        //     return await axios(apiSetting).then(func);
        // }

        return await axios(apiSetting).then(res => {

            // api 요청에서 응답 값이 왔을 때, _renew_token이 있으면 토큰을 갈아 끼운다.
            // if (Object.keys(res.data).indexOf('_renew_token') !== -1) {
            //     const cookie = new Cookies();
            //
            //     cookie.remove('token');
            //     cookie.set('token', res.data['_renew_token']['token'], {path: '/'});
            // }

            // consolemsg('api call ==========================');
            // consolemsg('api setting :::: ', apiSetting);

            // console.log('API');


            return res.data;

        }).catch(err => {
            // console.log(err.response);
            consolemsg('err response ::: ', err.response);

            if (err.response === undefined) {
                if (mode == 'local') {
                    alert('로컬 설정 확인. 크롬 CROSS-ORIGIN 설정 요망.');
                } else {
                    alert('잘못된 접근입니다.');
                }

                if (type === 'LOGIN') {
                    let cookie = new Cookies();
                    cookie.remove('token');
                    window.location.href = '/login';
                }
                return;
            }

            if (err.response.status === 400) {
                showToast('잘못된 값입니다. 다시 시도해주세요.');
            }

            if (err.response.status === 422) {
                showToast(err.response.data.message);
            }

            if (type === 'LOGIN' && err.response.status === 401) {
                const cookie = new Cookies();
                cookie.remove('token');
                window.alert(err.response.status + ' 잘못된 접근입니다! 다시 로그인 해주세요!');
                window.location.href = '/login';
            }

            return err.response.data;
        });
    } catch (e) {

    }
}


export const showToast = (message) => {
    let toastId = toast(message, {
        position: toast.POSITION.TOP_CENTER,
        type: toast.TYPE.ERROR,
        autoClose: false,
        onOpen: (props) => {
            // console.log(props)
        },
        onClose: (props) => {
            // console.log(props)
        },
        className: 'black-background',
        progressClassName: 'fancy-progress-bar',
    });
    toast.done(toastId);

    window.setTimeout(function () {
        toast.dismiss(toastId);
    }, 3000);
}
