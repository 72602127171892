import React, {useEffect} from 'react';
import {Footer, Header, ScrollTop} from '../components';
import styled from 'styled-components';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';

function Policy(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const htmlCodes = "<p>남양주펜션 예약 가자(이하 “펜션”이라 한다)는 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 “정보통신망법”이라 한다), 개인정보보호법, 통신비밀보호법,\n" +
        "                        전기통신사업법 등 회사가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보취급방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.<br/>\n" +
        "                            펜션은 개인정보취급방침을 통하여 고객이 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지\n" +
        "                            알려드립니다.<br/><br/>\n" +
        "                            1.개인 정보 수집 및 이용<br/>\n" +
        "                            펜션의 예약과 관련하여 본 동의서에 기재된 본인의 정보는 &lt;개인정보보호법&gt; 및 &lt;정보통신망 이용촉진 및 정보보호 관한 법률&gt;의 규정에 따라\n" +
        "                            수집, 이용, 제공, 취급위탁을\n" +
        "                            위하여 본인의 동의를 얻어야 하는 정보입니다.<br/>\n" +
        "                            이에 회원가입 사용자는 다음의 내용을 확인하며, 귀하의 개인정보 수집 이용, 제공 및 취급위탁에 동의 합니다.</p>\n" +
        "                    <table className=\"__se_tbl\"\n" +
        "                           style=\"border-width: 1px 1px 0px 0px; border-style: solid solid none none; border-color: #cccccc rgb(204, 204, 204) currentColor currentColor; border-image: none;\"\n" +
        "                           border=\"0\" cellSpacing=\"0\" cellPadding=\"0\">\n" +
        "                        <tbody>\n" +
        "                        <tr>\n" +
        "                            <td style=\"border-width: 0px 0px 1px 1px; width: 329px; height: 18px; border-bottom-color: #cccccc; border-left-color: #cccccc; border-bottom-style: solid; border-left-style: solid; background-color: #ffffff;\">\n" +
        "                                <p style=\"text-align: center;\"><b>&nbsp;항목</b></p>\n" +
        "                            </td>\n" +
        "                            <td style=\"border-width: 0px 0px 1px 1px; width: 329px; height: 18px; border-bottom-color: #cccccc; border-left-color: #cccccc; border-bottom-style: solid; border-left-style: solid; background-color: #ffffff;\">\n" +
        "                                <p style=\"text-align: center;\"><b>&nbsp;목적</b></p>\n" +
        "                            </td>\n" +
        "                            <td style=\"border-width: 0px 0px 1px 1px; width: 330px; height: 18px; border-bottom-color: #cccccc; border-left-color: #cccccc; border-bottom-style: solid; border-left-style: solid; background-color: #ffffff;\">\n" +
        "                                <p style=\"text-align: center;\"><b>&nbsp;보유기간</b></p>\n" +
        "                            </td>\n" +
        "                        </tr>\n" +
        "                        <tr>\n" +
        "                            <td style=\"border-width: 0px 0px 1px 1px; width: 329px; height: 18px; border-bottom-color: #cccccc; border-left-color: #cccccc; border-bottom-style: solid; border-left-style: solid; background-color: #ffffff;\">\n" +
        "                                <p style=\"text-align: center;\">성명</p>\n" +
        "                            </td>\n" +
        "                            <td style=\"border-width: 0px 0px 1px 1px; width: 329px; height: 18px; border-bottom-color: #cccccc; border-left-color: #cccccc; border-bottom-style: solid; border-left-style: solid; background-color: #ffffff;\">\n" +
        "                                <p style=\"text-align: center;\">이용자 식별 및 본인여부 확인</p>\n" +
        "                            </td>\n" +
        "                            <td style=\"border-width: 0px 0px 1px 1px; width: 330px; height: 18px; border-bottom-color: #cccccc; border-left-color: #cccccc; border-bottom-style: solid; border-left-style: solid; background-color: #ffffff;\">\n" +
        "                                <p style=\"text-align: center;\">탈퇴시 까지</p>\n" +
        "                            </td>\n" +
        "                        </tr>\n" +
        "                        <tr>\n" +
        "                            <td style=\"border-width: 0px 0px 1px 1px; width: 329px; height: 18px; border-bottom-color: #cccccc; border-left-color: #cccccc; border-bottom-style: solid; border-left-style: solid; background-color: #ffffff;\">\n" +
        "                                <p style=\"text-align: center;\">연락처(유선 또는 무선 전화번호)</p>\n" +
        "                            </td>\n" +
        "                            <td style=\"border-width: 0px 0px 1px 1px; width: 329px; height: 18px; border-bottom-color: #cccccc; border-left-color: #cccccc; border-bottom-style: solid; border-left-style: solid; background-color: #ffffff;\">\n" +
        "                                <p style=\"text-align: center;\">민원 등 고객 고충 처리</p>\n" +
        "                            </td>\n" +
        "                            <td style=\"border-width: 0px 0px 1px 1px; width: 330px; height: 18px; border-bottom-color: #cccccc; border-left-color: #cccccc; border-bottom-style: solid; border-left-style: solid; background-color: #ffffff;\">\n" +
        "                                <p style=\"text-align: center;\">탈퇴시 까지</p>\n" +
        "                            </td>\n" +
        "                        </tr>\n" +
        "                        </tbody>\n" +
        "                    </table className=\"__se_tbl\"\n" +
        "                           style=\"border-width: 1px 1px 0px 0px; border-style: solid solid none none; border-color: #cccccc rgb(204, 204, 204) currentColor currentColor; border-image: none;\"\n" +
        "                       >\n" +
        "                    <p><strong><br/>동의 거부권 등에 대한 고지</strong><br/>\n" +
        "                        본 개인정보 제공에 동의하지 않으시는 경우, 동의를 거부할 수 있으며, 이 경우 회원가입에 제한될 수 있습니다.<br/><br/>\n" +
        "                        2.개인정보 파기절차 및 방법<br/>\n" +
        "                        펜션의 개인적인 파기절차 및 파기방법은 다음과 같습니다.<br/>\n" +
        "                        가) 파기절차<br/>\n" +
        "                        법령에 특별한 규정이 있는 경우를 제외한 경우 보유기간 이후 지체없이 파기합니다.<br/>\n" +
        "                        나) 파기방법<br/>\n" +
        "                        (1) 종이(서면)에 작성·출력된 개인정보 : 분쇄기로 분쇄하거나 소각 등의 방법으로 파기<br/>\n" +
        "                        (2) 전자적 파일 형태로 저장된 개인정보 : 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제 <br/><br/>\n" +
        "                        3.수탁사 및 수탁사 업무 내용<br/>\n" +
        "                        수탁업체 : 펜션나라 / 경기 안산시 단원구 광덕대로 187, 605호 / 1544-0758<br/>\n" +
        "                        위탁 업무 : 홈페이지 개발,관리 업체로 회원가입의 개인정보 관리<br/><br/>\n" +
        "                        4.개인정보관리책임자 및 담당자 연락처, 관련 기관 연락처<br/>\n" +
        "                        가) 펜션은 이용자의 의견을 소중하게 생각하며, 이용자의 문의사항에 대해 항상 성실히 답변을 드릴 수 있도록 노력하겠습니다.<br/>\n" +
        "                        나) 개인정보 관리책임자 : 박정민 / 010-9425-1773<br/>\n" +
        "                        다) 개인정보와 관련하여 도움을 받을 수 있는 외부기관은 다음과 같습니다.\n" +
        "                    </p>\n" +
        "                    <table className=\"__se_tbl\"\n" +
        "                           style=\"border-width: 1px 1px 0px 0px; border-style: solid solid none none; border-color: #cccccc rgb(204, 204, 204) currentColor currentColor; border-image: none;\"\n" +
        "                           border=\"0\" cellSpacing=\"0\" cellPadding=\"0\">\n" +
        "                        <tbody>\n" +
        "                        <tr>\n" +
        "                            <td style=\"border-width: 0px 0px 1px 1px; width: 329px; height: 18px; border-bottom-color: #cccccc; border-left-color: #cccccc; border-bottom-style: solid; border-left-style: solid; background-color: #ffffff;\">\n" +
        "                                <p style=\"text-align: center;\"><b>&nbsp;기관</b></p>\n" +
        "                            </td>\n" +
        "                            <td style=\"border-width: 0px 0px 1px 1px; width: 329px; height: 18px; border-bottom-color: #cccccc; border-left-color: #cccccc; border-bottom-style: solid; border-left-style: solid; background-color: #ffffff;\">\n" +
        "                                <p style=\"text-align: center;\"><b>&nbsp;연락처</b></p>\n" +
        "                            </td>\n" +
        "                            <td style=\"border-width: 0px 0px 1px 1px; width: 330px; height: 18px; border-bottom-color: #cccccc; border-left-color: #cccccc; border-bottom-style: solid; border-left-style: solid; background-color: #ffffff;\">\n" +
        "                                <p style=\"text-align: center;\"><b>&nbsp;URL</b></p>\n" +
        "                            </td>\n" +
        "                        </tr>\n" +
        "                        <tr>\n" +
        "                            <td style=\"border-width: 0px 0px 1px 1px; width: 329px; height: 18px; border-bottom-color: #cccccc; border-left-color: #cccccc; border-bottom-style: solid; border-left-style: solid; background-color: #ffffff;\">\n" +
        "                                <p style=\"text-align: center;\">&nbsp;개인정보침해신고센터</p>\n" +
        "                            </td>\n" +
        "                            <td style=\"border-width: 0px 0px 1px 1px; width: 329px; height: 18px; border-bottom-color: #cccccc; border-left-color: #cccccc; border-bottom-style: solid; border-left-style: solid; background-color: #ffffff;\">\n" +
        "                                <p style=\"text-align: center;\">&nbsp;118</p>\n" +
        "                            </td>\n" +
        "                            <td style=\"border-width: 0px 0px 1px 1px; width: 330px; height: 18px; border-bottom-color: #cccccc; border-left-color: #cccccc; border-bottom-style: solid; border-left-style: solid; background-color: #ffffff;\">\n" +
        "                                <p style=\"text-align: center;\">&nbsp;<a href=\"http://www.118.or.kr\"\n" +
        "                                                                        target=\"_blank\">http://www.118.or.kr</a>\n" +
        "                                </p>\n" +
        "                            </td>\n" +
        "                        </tr>\n" +
        "                        <tr>\n" +
        "                            <td style=\"border-width: 0px 0px 1px 1px; width: 329px; height: 18px; border-bottom-color: #cccccc; border-left-color: #cccccc; border-bottom-style: solid; border-left-style: solid; background-color: #ffffff;\">\n" +
        "                                <p style=\"text-align: center;\">&nbsp;대검찰청 인터넷범죄수사센터</p>\n" +
        "                            </td>\n" +
        "                            <td style=\"border-width: 0px 0px 1px 1px; width: 329px; height: 18px; border-bottom-color: #cccccc; border-left-color: #cccccc; border-bottom-style: solid; border-left-style: solid; background-color: #ffffff;\">\n" +
        "                                <p style=\"text-align: center;\">&nbsp;02-3480-3600</p>\n" +
        "                            </td>\n" +
        "                            <td style=\"border-width: 0px 0px 1px 1px; width: 330px; height: 18px; border-bottom-color: #cccccc; border-left-color: #cccccc; border-bottom-style: solid; border-left-style: solid; background-color: #ffffff;\">\n" +
        "                                <p style=\"text-align: center;\">&nbsp;<a href=\"http://www.spo.go.kr\"\n" +
        "                                                                        target=\"_blank\">http://www.spo.go.kr</a>\n" +
        "                                </p>\n" +
        "                            </td>\n" +
        "                        </tr>\n" +
        "                        <tr>\n" +
        "                            <td style=\"border-width: 0px 0px 1px 1px; width: 329px; height: 18px; border-bottom-color: #cccccc; border-left-color: #cccccc; border-bottom-style: solid; border-left-style: solid; background-color: #ffffff;\">\n" +
        "                                <p style=\"text-align: center;\">&nbsp;개인정보분쟁조정위원회</p>\n" +
        "                            </td>\n" +
        "                            <td style=\"border-width: 0px 0px 1px 1px; width: 329px; height: 18px; border-bottom-color: #cccccc; border-left-color: #cccccc; border-bottom-style: solid; border-left-style: solid; background-color: #ffffff;\">\n" +
        "                                <p style=\"text-align: center;\">&nbsp;02-405-4747</p>\n" +
        "                            </td>\n" +
        "                            <td style=\"border-width: 0px 0px 1px 1px; width: 330px; height: 18px; border-bottom-color: #cccccc; border-left-color: #cccccc; border-bottom-style: solid; border-left-style: solid; background-color: #ffffff;\">\n" +
        "                                <p align=\"center\" style=\"text-align: center;\">&nbsp;<a href=\"http://www.kopico.or.kr\"\n" +
        "                                                                                       target=\"_blank\">http://www.kopico.or.kr</a>\n" +
        "                                </p>\n" +
        "                            </td>\n" +
        "                        </tr>\n" +
        "                        </tbody>\n" +
        "                    </table>";
    return (
        <>
            <Header />

            <StyledContainer>
                <div>
                    <h1>개인정보처리방침</h1>
                    <div dangerouslySetInnerHTML={{__html: htmlCodes}}></div>
                </div>
            </StyledContainer>

            <Footer />

            <ScrollTop />
        </>
    );
}

const StyledContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    
    div {
        max-width: 1220px;
        margin: 60px auto;
        
        .info {
            line-height: 30px;        
        }
    }
`;

export default Policy;