import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import LogoIcon from '../assets/images/logo-icon.png';
import StayLogo2 from '../assets/images/stay-logo2.jpg';
import LogoIcon2 from '../assets/images/gaja-logo.png';
import '../assets/css/logo.css';
import GongFont from '../assets/fonts/GongGothicBold.ttf';
import {makeStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const Logo = () => {

    return (
        <StyledDiv>
            <Link to="/">
                <img src={LogoIcon2}/>
                <div className="logo-title">
                    남양주 펜션
                </div>
                {/*<div alt="민박로고" className={classes.logo} >*/}
                {/*</div>*/}
                {/*<img src={LogoIcon2} alt="민박로고" className="stay-logo" />*/}
                {/*<div className="logo-title-wrap">*/}
                {/*    <div className="logo-title-1">*/}
                {/*        <img src={LogoIcon} alt="남양주펜션협회로고" className="city-logo" /> 남양주시*/}
                {/*    </div>*/}
                {/*    <div className="logo-title-2">*/}
                {/*        남양주민박협회*/}
                {/*    </div>*/}
                {/*</div>*/}
            </Link>
        </StyledDiv>
    );
};

const StyledDiv = styled.div`
    position:relative;
    width:145px;
    
    a {
        text-decoration: none;
        color: inherit;
        display: flex;
        align-items: center;
        
        .logo-title-wrap {
            width: 114px;
            color: #3f4141;
        
            .logo-title-1 {
                font-size: 22px;
                font-weight: 600;
                border-bottom: 1px solid #3f4141;
                text-align: center;
                padding-bottom: 5px;
                margin-bottom: 5px;
            }
            
            .logo-title-2 {
                font-size: 14px;
                font-weight: 500;
                text-align: center;
            }
        }
        
        .stay-logo {
            margin-right: 10px;
        }
        
        .city-logo {
            width: 26px;
        }
    }
    
    img {
        width:145px;
    }
    
    .logo-title {
        position:absolute;
        font-size: 11px;
        top:11px;
        right: 15px;
        color: #FFF;
        font-family: "Gong Gothic" !important;
    }
    
    @media (max-width: 940px) {
        width:100px;
        img {
            width:100px;
        }
        
        .logo-title {
            position:absolute;
            font-size: 5px;
            top:7px;
            right: 5px;
            color: #FFF;
            font-family: "Gong Gothic" !important;
        }
        
        a {
            .logo-title-wrap {
                width: 92px;
            
                .logo-title-1 {
                    font-size: 17px;
                    text-align: center;
                    padding-bottom: 3px;
                    margin-bottom: 1px;
                }
                
                .logo-title-2 {
                    font-size: 11px;
                    font-weight: 500;
                    text-align: center;
                }
            }
            
            .stay-logo {
                margin-right: 5px;
            }
            
            .city-logo {
                width: 22px;
            }
        }
    }
`;

export default Logo;
