import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import styled from 'styled-components';
import {Link, useHistory} from 'react-router-dom';
import {device} from '../config/device';
import prevIcon from '../assets/images/slider-prev-icon.png';
import nextIcon from '../assets/images/slider-next-icon.png';
import {useCookies} from 'react-cookie';
import noImage from '../assets/images/noimage.jpg';

const PhotoBox = props => {
    const PrevArrowButton = (props) => {
        return (
            <StyledArrowButton className="slick-arrow prev" onClick={props.onClick}>
                <img src={prevIcon} alt=""/>
            </StyledArrowButton>
        );
    };

    const NextArrowButton = (props) => {
        return (
            <StyledArrowButton className="slick-arrow next" onClick={props.onClick}>
                <img src={nextIcon} alt=""/>
            </StyledArrowButton>
        );
    };

    const SliderSettings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrowButton/>,
        prevArrow: <PrevArrowButton/>
    }

    // 노 이미지
    function defaultSrc(event) {
        event.target.src = noImage;
    }

    return (
        <StyledContainer>
            <Slider {...SliderSettings} className={props.isActive === '' ? '' : 'active'}>
                {
                    props.images.length < 1 ? (
                        <div>
                            <Link to={props.link}>
                                <img onError={defaultSrc} src={noImage} alt={props.title} className="slide-image"/>
                            </Link>
                        </div>
                    ) : (
                        props.images.map((item, index) => {
                            let imgSrc = item.file_url ? item.file_url : noImage;
                            return (
                                <div key={index}>
                                    <Link to={props.link}>
                                        <img onError={defaultSrc} src={imgSrc} alt={props.title} className="slide-image"/>
                                    </Link>
                                </div>
                            )
                        })
                    )
                }
            </Slider>
            <div className="product-name">
                <Link to={props.link}>
                    {props.title}
                </Link>
            </div>
            {/*<div className="produce-info">*/}
            {/*    <Link to={props.link}>*/}
            {/*        <div className="price">*/}
            {/*            <div>*/}
            {/*                test*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </Link>*/}
            {/*</div>*/}
        </StyledContainer>
    );
};

PhotoBox.propTypes = {
    link: PropTypes.string,
    location: PropTypes.string,
    title: PropTypes.string,
    images: PropTypes.array,
    tag: PropTypes.array,
    id: PropTypes.number,
    type: PropTypes.string
};

PhotoBox.defaultProps = {
    type: 'pension'
}

const StyledContainer = styled.div`
    width: 340px;
    height: 357px;
    // width: 420px;
    // height: 441px;
    position: relative;
    margin: 10px;
    
    & > a {
        text-decoration: none;
        color: inherit;
    }
    
    * {
        outline: none;
    }
    
    .sale-per {
        background-color: rgba(135,148,56,0.85);
        color:#FFF;
        position:absolute;
        right:0;
        top:0;
        width:50px;
        height:50px;
        margin:5px;
        display:flex;
        align-items: center;
        justify-content: center;
    }
    
    .product-name {
        font-size: 18px;
        font-weight: 500;
        color: #434343;
        // border-bottom: 1px solid #d9d9d9;
        padding: 10px;
    }
    
    .produce-info {
        padding: 0 10px;
        margin-top: 6px;
        
        br {
            display: block !important;
        }
        a {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;            
        }
        
        .tag {
            padding-top:2px;
            font-size: 12px;
            font-weight: 400;
            color: #f68b1f;
            display: flex;
            
            div {
                border-right: 1px solid #f68b1f;
                padding-right: 7px;
                margin-right: 6px;
                
                &:last-child {
                    border-right: none;
                }
            }
        }
        
        .price {
            font-size: 17px;
            font-weight: 500;
            color: #434343;
            
            .default-price {
                text-decoration-line: line-through;
            }
        }
    }
    
    .slide-image {
        width: 340px;
        // width: 420px;
        height: 254px;
        // height: 314px;
        object-fit: cover;
    }
    
    .slick-slider {
        .slick-arrow {
            display:none !important;
        }

        &:hover {
            .slick-arrow {
                display:flex !important;
            }
        }
        
        opacity: 0;
        visibility: hidden;
        transition: opacity 1s ease;

        &.active {
            visibility: visible;
            opacity: 1;
        }
    }
    
    // @media (max-width: 736px) {
    //     width: 300px;
    //     height: 295px;
    //     margin: 0 0 60px;
    //     padding: 0 20px;
    //    
    //     .slide-image {
    //         width: 300px;
    //         height: 224px;
    //         object-fit: cover;
    //     }
    //    
    //     .product-name {
    //       font-size: 20px;   
    //       color: #000; 
    //     }
    //    
    //     .produce-info {
    //         .tag {
    //             font-size: 15px;
    //         }
    //        
    //         .price {
    //             font-size: 15px;
    //             color: #000;
    //         }
    //     }
    //    
    //     .sale-per {
    //         right: 20px;
    //     }
    // }
`;

const StyledArrowButton = styled.button`
    background: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    
    &.prev {
        left: 10px;
    }
    
    &.next {
        right: 10px;
    }
    
    display: flex;
    border: none;
    outline: none;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    z-index: 2;
    
    @media ${device.small} {
        top: 50%;
        transform: translateY(-50%);
    }
`;

export default PhotoBox;