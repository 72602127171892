import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Portal} from './';

const DetailSearchModal = ({
                   className,
                   onClose,
                   maskClosable,
                   closable,
                   visible,
                   children
               }) => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = '17px';
        return () => {
            document.body.style.overflow = 'auto';
            document.body.style.paddingRight = '0';
        }
    }, []);

    const onMaskClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose(e);
        }
    };

    const close = (e) => {
        if (onClose) {
            onClose(e);
        }
    };

    const handleClickConfirm = (e) => {
        onClose(e);
    };

    return (
        <Portal elementId="modal-root">
            <ModalOverlay visible={visible} />
            <ModalWrapper
                className={className}
                onClick={maskClosable ? onMaskClick : null}
                tabIndex="-1"
                visible={visible}
            >
                <ModalInner tabIndex="0" className="modal-inner">
                    <div className="modal-inner2">
                        <div className="header">
                            <div>상세검색</div>
                            {closable && <CloseButton className="modal-close" onClick={close}><svg
                                width="30px" height="30px">
                                <defs>
                                    <filter id="Filter_0">
                                        <feFlood floodColor="rgb(62, 71, 92)" floodOpacity="1" result="floodOut" />
                                        <feComposite operator="atop" in="floodOut" in2="SourceGraphic" result="compOut" />
                                        <feBlend mode="normal" in="compOut" in2="SourceGraphic" />
                                    </filter>

                                </defs>
                                <g filter="url(#Filter_0)">
                                    <path fillRule="evenodd"  fill="rgb(0, 0, 0)"
                                          d="M29.985,27.861 L27.864,29.982 L15.000,17.119 L2.136,29.983 L0.015,27.862 L12.879,14.998 L0.015,2.134 L2.136,0.012 L15.000,12.876 L27.864,0.012 L29.985,2.133 L17.121,14.998 L29.985,27.861 Z"/>
                                </g>
                            </svg></CloseButton>}
                        </div>

                        <hr className="line" />

                        <div className="option-box">
                            <div className="title">숙박</div>
                            <div className="checkbox-wrap">
                                <div>
                                    <label className="container">
                                        <input type="checkbox" />
                                        <span className="checkmark"></span>
                                        <div>펜션</div>
                                    </label>
                                </div>
                                <div>
                                    <label className="container">
                                        <input type="checkbox" />
                                        <span className="checkmark"></span>
                                        <div>캠핑</div>
                                    </label>
                                </div>
                                <div className="last">
                                    <label className="container">
                                        <input type="checkbox" />
                                        <span className="checkmark"></span>
                                        <div>글램핑</div>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <hr className="line" />

                        <div className="option-box">
                            <div className="title">관광</div>
                            <div className="checkbox-wrap">
                                <div>
                                    <label className="container">
                                        <input type="checkbox" />
                                        <span className="checkmark"></span>
                                        <div>자연관광</div>
                                    </label>
                                </div>
                                <div>
                                    <label className="container">
                                        <input type="checkbox" />
                                        <span className="checkmark"></span>
                                        <div>문화관광</div>
                                    </label>
                                </div>
                                <div>
                                    <label className="container">
                                        <input type="checkbox" />
                                        <span className="checkmark"></span>
                                        <div>어린이</div>
                                    </label>
                                </div>
                                <div>
                                    <label className="container">
                                        <input type="checkbox" />
                                        <span className="checkmark"></span>
                                        <div>레저관광</div>
                                    </label>
                                </div>
                                <div>
                                    <label className="container">
                                        <input type="checkbox" />
                                        <span className="checkmark"></span>
                                        <div>농촌체험</div>
                                    </label>
                                </div>
                                <div>
                                    <label className="container">
                                        <input type="checkbox" />
                                        <span className="checkmark"></span>
                                        <div>축제</div>
                                    </label>
                                </div>
                                <div className="last">
                                    <label className="container">
                                        <input type="checkbox" />
                                        <span className="checkmark"></span>
                                        <div>공연행사</div>
                                    </label>
                                </div>
                                <div className="last">
                                    <label className="container">
                                        <input type="checkbox" />
                                        <span className="checkmark"></span>
                                        <div>체험/교육</div>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <hr className="line" />

                        <div className="option-box">
                            <div className="title">맛집</div>
                            <div className="checkbox-wrap">
                                <div>
                                    <label className="container">
                                        <input type="checkbox" />
                                        <span className="checkmark"></span>
                                        <div>한식</div>
                                    </label>
                                </div>
                                <div>
                                    <label className="container">
                                        <input type="checkbox" />
                                        <span className="checkmark"></span>
                                        <div>레스토랑</div>
                                    </label>
                                </div>
                                <div className="last">
                                    <label className="container">
                                        <input type="checkbox" />
                                        <span className="checkmark"></span>
                                        <div>카페</div>
                                    </label>
                                </div>
                                <div className="last">
                                    <label className="container">
                                        <input type="checkbox" />
                                        <span className="checkmark"></span>
                                        <div>퓨전</div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ConfirmButton onClick={handleClickConfirm}>확인</ConfirmButton>
                </ModalInner>
            </ModalWrapper>
        </Portal>
    );
};

DetailSearchModal.defaultProps = {
    visible: false,
    closable: true,
    maskClosable: true
};

DetailSearchModal.propTypes = {
    visible: PropTypes.bool
};

const ModalWrapper = styled.div`
    box-sizing: border-box;
    display: ${(props) => (props.visible ? 'block' : 'none')};
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    overflow: auto;
    outline: 0;
`;

const ModalOverlay = styled.div`
    box-sizing: border-box;
    display: ${(props) => (props.visible ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 999;
`;

const ModalInner = styled.div`
    box-sizing: border-box;
    position: relative;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
    background-color: #fff;
    width: 720px;
    height: auto;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    outline: 0;
    
    & iframe {
        width: 100%;
        height: 500px;
        border: none;
    }
    
    & .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    .modal-inner2 {
        padding: 0 30px;
        
        .header {
            position: relative;
            font-size: 24px;
            color: #000;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        
        .line {
            margin-top: 0;
            margin-bottom: 0;
            border: 1px solid #dfdfdf;
        }
        
        .option-box {
            padding: 19px 20px 15px;
            
            .title {
                font-size: 24px;
                color: #000;
                margin-bottom: 33px;
            }
            
            .checkbox-wrap {
                display: flex;
                flex-wrap: wrap;
            
                & > div {
                    width: 50%;
                    height: 30px;
                    margin-bottom: 30px;
                }
                
                & > div.last {
                    margin-bottom: 0;
                }
             
                .container {       
                    position: relative;
                    cursor: pointer;
                    font-size: 18px;
                    color: #000;
                    user-select: none;
                    display: block;
                }
                
                .container div {
                    position: absolute;
                    top: 6px;
                    left: 50px;
                }
                
                .container input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                }
                
                .checkmark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 30px;
                    width: 30px;
                    background-color: #dfdfdf;
                }
                
                .container:hover input ~ .checkmark {
                    background-color: #ccc;
                }
                
                .container input:checked ~ .checkmark {
                    background-color: #5fc148;
                }
                
                .checkmark:after {
                    content: "";
                    position: absolute;
                    display: none;
                }
                
                .container input:checked ~ .checkmark:after {
                    display: block;
                }
                
                .container .checkmark:after {
                    left: 10px;
                    top: 5px;
                    width: 7px;
                    height: 13px;
                    border: solid white;
                    border-width: 0 3px 3px 0;
                    transform: rotate(45deg);
                }
            }
        }
    }
`;

const CloseButton = styled.button`
    width: 30px;
    height: 30px;
    font-size: 22px;
    font-weight: bold;
    outline: none;
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 17px;
    right: 6px;
`;

const ConfirmButton = styled.button`
    width: 100%;
    height: 60px;
    font-size: 20px;
    color: #fff;
    background: #5fc148;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    cursor: pointer;
    margin-top: 45px;
`;

export default DetailSearchModal;