import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
// import mainFarmData from '../../dummy/mainFarmData.json';
import {SliderBox} from '../../components';

import {callApi} from '../../api';
import strawberryThumb from '../../assets/images/farm/strawberry-thumb.jpg';

function MainFarm(props) {
    const [farmData, setFarmData] = useState([]);

    useEffect(() => {
        const apiURL = '/company/농장';
        callApi(apiURL, 'GET', {}).then((res) => {
            if (res) {
                setFarmData(res.list);
            }
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    const [farmProductList, setFarmProductList] = useState();
    useEffect(() => {
        // 농장체험정보 랜덤으로 보여주기
        function shuffle(array) {
            let currentIndex = array.length, temporaryValue, randomIndex;

            // While there remain elements to shuffle...
            while (0 !== currentIndex) {

                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex -= 1;

                // And swap it with the current element.
                temporaryValue = array[currentIndex];
                array[currentIndex] = array[randomIndex];
                array[randomIndex] = temporaryValue;
            }

            return array;
        }

        let temp = shuffle(farmData);
        // 8개만 표시
        let randomFarmData = temp.slice(0, 8);

        // 농장체험정보
        try {
            let result = randomFarmData.map((item, index) => {
                return (
                    <SliderBox
                        type="farm"
                        key={item.id}
                        id={item.id}
                        link={item.link}
                        desc={item.farmInfo}
                        title={item.companyName}
                        images={item.images.length === 0 ? [strawberryThumb] : item.images}
                    />
                )
            });
            setFarmProductList(result);
        } catch {
            setFarmProductList(null);
        }
    }, [farmData]);

    return (
        <StyledContainer>
            <div className="category">
                <div></div>
                <div className="active">농촌 체험 정보</div>
                <div></div>
            </div>

            <StyledCategoryContainer>
                {farmProductList}
            </StyledCategoryContainer>
        </StyledContainer>
    );
}

const StyledContainer = styled.div`
    width: 100%;
    max-width: 1440px;
    margin: 140px auto 0;
    
    .category {
        display: flex;
        justify-content: center;
        margin-top: 110px;
        margin-bottom: 30px;
        
        div {
            flex:1;
            max-width: 440px;
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            color: #434343;
            border-bottom: 2px solid #dcdcdc;
            
            &.active {
                max-width: 170px;
                border-bottom: 2px solid #f68b1f;
                padding-bottom: 12px;
            }
        }
    }
    
    @media (max-width: 940px) {
        margin-top: 50px;
        
        .category {
            div {
                width: 145px;
                
                &.active {
                    width: 210px;
                }
            }
        }
    }
`;

const StyledCategoryContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 30px auto 93px;
    justify-content: center;
    
    @media (max-width: 1456px) {
        max-width: 1080px;
        justify-content: start;
    }
    
    @media (max-width: 1096px) {
        max-width: 720px;
        justify-content: center;
    }
    
    @media (max-width: 700px) {
        margin: 30px auto 50px;
    }
`;

export default MainFarm;