import React, {useState} from 'react';
import {Checkbox, Footer, Header} from '../../components';
import CsHeader from './CsHeader';
import CsBody from './CsBody';
import styled from 'styled-components';

function Partnership(props) {
    function handleSubmit() {
        console.log('submit');
    }

    const [agree, setAgree] = useState(false);
    function handleChangeAgree(event) {
        setAgree(event.target.checked);
    }
    function handleClickAgree() {
        setAgree(!agree);
    }

    // 임시
    const textareaText = '[필수정보]\n- 업체명 : \n- 담당자 : \n- 제휴 내용 : ';
    const [textareaValue, setTextareaValue] = useState(textareaText);
    function handleChangeTextarea(event) {
        setTextareaValue(event.target.value);
    }

    return (
        <>
            <Header isSearch={false} />

            <CsHeader />

            <CsBody active='partnership'>
                <StyledContainer>
                    <div className="input-box">
                        <div className="title"><label htmlFor="email">답변받을메일</label></div>
                        <div>
                            <input type="text" id="email" className="input-text" placeholder="입력해주세요" />
                        </div>
                    </div>
                    <div className="input-box">
                        <div className="title"><label htmlFor="phone">핸드폰번호</label></div>
                        <div>
                            <input type="text" id="phone" className="input-text" placeholder="입력해주세요" />
                        </div>
                    </div>
                    <div className="input-box">
                        <div className="title"><label htmlFor="inquiry-title">문의제목</label></div>
                        <div>
                            <input type="text" id="inquiry-title" className="input-text" placeholder="입력해주세요" />
                        </div>
                    </div>
                    <div className="input-box">
                        <textarea
                            value={textareaValue}
                            onChange={handleChangeTextarea}
                        />
                    </div>
                    <div className="input-box">
                        <div className="title">첨부파일</div>
                        <div>
                            파일을 등록해주세요
                        </div>
                    </div>
                    <div className="input-box">
                        <div className="title"><label htmlFor="password">비밀번호</label></div>
                        <div>
                            <input type="password" id="password" className="input-text" placeholder="입력해주세요" />
                        </div>
                    </div>
                    <div className="input-box">
                        <div className="title"><label htmlFor="captcha">문자입력</label></div>
                        <div>
                            964782
                            <input type="text" id="captcha" className="input-text" placeholder="입력해주세요" />
                        </div>
                    </div>
                    <div className="input-box">
                        <div className="title">등록일</div>
                        <div>2020년 01월 01일 / 16시 10분 59초</div>
                    </div>
                </StyledContainer>

                <StyledTerms>
                    <div className="checkbox">
                        <Checkbox checked={agree} onChange={handleChangeAgree} />
                    </div>
                    <div className="text" onClick={handleClickAgree}>
                        <div>개인정보 수집 및 이용에 동의합니다.</div>
                        <div>수집하는 개인정보 항목 : 이메일주소, 계좌정보(환불 시), 서버담당자 휴대폰번호(서버 작업 시), 테스트 아이디/비밀번호(테스트 필요시),
                        기타 접속정보(요청 작업 수행에 필요시), 사업자등록증 등 증빙서류 내 기재정보(본인확인, 위임 등 증빙 필요시)<br />
                        개인정보의 수집 및 이용목적 : 문의 접수/처리, 고객 불편 또는 불만사항 해결, 계정에 대한 처리 이력 관리 및 상담<br />
                        개인정보의 이용기간 : 상담이력 5년, 보안내용 내 기재 정보 3개월(답변 완료된 경우 완료일로부터 3일), 첨부파일 3개월 간 보관. 단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 해당 기간 동안 개인정보를 보관할 수 있습니다.</div>
                    </div>
                </StyledTerms>

                <div>
                    <StyledButton onClick={handleSubmit}>문의하기</StyledButton>
                </div>
            </CsBody>

            <Footer />
        </>
    );
}

const StyledContainer = styled.div`
    border: 1px solid #dfdfdf;
    
    .input-box {
        padding: 20px;
        display: flex;
        color: #000;
        border-bottom: 1px solid #dfdfdf;
        
        .title {
            width: 110px;
            color: #666;
            display: flex;
            align-items: center;
        }
        
        &:last-child {
            border-bottom: 0;
        }
        
        .input-text {
            border: none;
            outline: none;
        }
        
        textarea {
            width: 100%;
            min-height: 275px;
            border: none;
            outline: none;
        }
    }
`;

const StyledTerms = styled.div`
    display: flex;
    padding: 20px;
    
    .checkbox {
        margin-right: 20px;
    }
    
    .text {
        //max-width: 893px;
        cursor: pointer;
        font-size: 16px;
        color: #666;
        
        div:first-child {
            display: flex;
            align-items: center;
            height: 30px;
        }
        
        div:last-child {
            line-height: 24px;
        }
    }
`;

const StyledButton = styled.button`
    width: 100%;
    height: 60px;
    background-color: #5fc148;
    border: none;
    outline: none;
    cursor: pointer;
    color: #fff;
    font-size: 20px;
    margin-top: 40px;
`;

export default Partnership;