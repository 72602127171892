import React from "react";
import styled from "styled-components";

const SortableItemInner = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: grab;
    
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
`;

const SortableMenuButton = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    z-index: 4;
    
    & span {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #3e475c;
        display: inline-block;
        margin: 0 1.5px;
        z-index: 3;
        pointer-events: none;
    }
`;

const SortableMenu = styled.div`
    display: none;
    flex-direction: column;
    width: 120px;
    height: 120px;
    position: absolute;
    top: 50px;
    right: 20px;
    color: #000;
    font-size: 18px;
    background-color: #fff;
    border: 1px solid #dfdfdf;
    
    & .menu-item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      cursor: pointer;
      z-index: 5;
      user-select: none;
    }
    
    & .menu-item:hover {
      background-color: #dfdfdf !important;
    }
`;

const SlideBox = (props) => {
    return (
        <div className="slide-wrap" key={props.id} style={{
            position: "relative",
        }}>
            <div style={{
                position: 'absolute',
                margin: 5,
                zIndex: 3
            }}>
                <div style={{
                    position: 'absolute',
                    height: '100%',
                    backgroundColor: '#F0F0F0',
                    opacity: 0.5,
                    borderRadius: '10px',
                }}></div>
                <div style={{padding: '5px 10px 5px 10px', color: '#F0F0F0', fontSize: 12}}>
                    {(props.idx + 1)} / {props.total}
                </div>
            </div>
            <div style={{display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                {/*<img style={{width: '100%', minHeight: 200}}*/}
                {/*     src={props.image_url}/>*/}
                {props.link ? (
                    <a href={props.link} target={'blank'}>
                        <img style={props.imgStyle} src={props.image_url}/>
                    </a>
                ):(
                    <img style={props.imgStyle} src={props.image_url}/>
                )}

            </div>

        </div>
    );
}

const SlideBox2 = (props) => {
    return (
        <div className="slide-wrap" key={props.id}
             style={{position: 'relative'}}>
            <div className="slide-wrap">
                {/*<img style={{width: '100%', minHeight: 200}}*/}
                {/*     src={props.image_url}/>*/}
                <img style={props.imgStyle} src={props.image_url}/>
            </div>

        </div>
    );
}

export {SlideBox, SlideBox2};