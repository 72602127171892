import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Header, Footer, Checkbox, Breadcrumb} from '../../components';
import {InputBox, InputText} from '../../components/inputs';
import {Link} from 'react-router-dom';
import {useCookies} from 'react-cookie';
import bg from "../../assets/images/member/login-bg.jpg";
import {callApi} from "../../api";

function Login(props) {
    const HUNDRED_DAY = 8640000;

    const [email, setEmail] = useState('');
    function handleChangeEmail(event) {
        setEmail(event.target.value);

        if (isRemember) {
            setCookie('rememberEmail', event.target.value, {maxAge: HUNDRED_DAY});
        }
    }

    const [password, setPassword] = useState();
    function handleChangePassword(event) {
        setPassword(event.target.value);
    }

    // 이메일 저장
    const [isRemember, setIsRemember] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies();

    useEffect(() => {
        if(cookies.rememberEmail !== undefined && cookies.rememberEmail !== '') {
            setEmail(cookies.rememberEmail);
            setIsRemember(true);
        }
    }, [cookies.rememberEmail]);

    function handleChangeSave(event) {
        setIsRemember(event.target.checked);
        if (event.target.checked) {
            setCookie('rememberEmail', email, {maxAge: HUNDRED_DAY});
        } else {
            removeCookie('rememberEmail');
        }
    }

    // 로그인
    function handleClickLogin() {

        // 로그인 정보
        const loginData = {
            email: email,
            password: password
        };

        callApi('/member/login', 'POST', loginData).then((res) => {

            if(res.message !== undefined) {
                alert(res.message);
                return;
            }

            setCookie('user_id', res.user_id, {maxAge: HUNDRED_DAY});
            setCookie('token', res.token, {maxAge: HUNDRED_DAY});
            setCookie('user_name', res.user_name, {maxAge: HUNDRED_DAY});
            props.history.push('/');
        });
    }

    const breadcrumb = [{
        id: 1,
        title: '로그인',
        link: '/login'
    }];

    return (
        <>
            <Header />

            <StyledContainer>
                <div className="title-wrap">
                    <img src={bg} alt="" />
                    <div className="title">
                        <span>로그인</span>
                    </div>
                </div>

                <Breadcrumb data={breadcrumb} />

                <div className="category">
                    <div></div>
                    <div className="active">로그인</div>
                    <div></div>
                </div>

                <StyledCategoryContainer>
                    <InputBox title="아이디" firstBox>
                         <InputText
                             placeholder="아이디를 입력하세요."
                             value={email}
                             onChange={handleChangeEmail}
                         />
                     </InputBox>
                     <InputBox title="비밀번호">
                         <StyledPasswordInput
                             type="password"
                             onChange={handleChangePassword}
                             placeholder="********"
                         />
                     </InputBox>
                    <StyledOptionBox>
                        <label>
                            <Checkbox
                                checked={isRemember}
                                onChange={handleChangeSave}
                            />이메일 저장
                        </label>
                     </StyledOptionBox>
                    <StyledButtonWrap>
                        <StyledButton onClick={handleClickLogin}>로그인</StyledButton>
                    </StyledButtonWrap>
                    <StyledJoin><Link to="/join"><span>회원가입</span></Link>
                        {/*<Link to="/forgot"><span>비밀번호 찾기</span></Link>*/}
                    </StyledJoin>
                </StyledCategoryContainer>
            </StyledContainer>

            <Footer />
        </>




//         <MemberContainer>
//             <MemberHeader
//                 onClick={handleClickGoBack}
//                 title="로그인"
//             />
//
//             <MemberWrap>
//                 <InputBox title="사업자번호" firstBox>
//                     <InputBusinessNumber
//                         value={businessNumber}
//                         onChange={handleChangeBusinessNumber}
//                     />
//                 </InputBox>
//                 <InputBox title="비밀번호">
//                     <StyledPasswordInput type="password" value={password} onChange={handleChangePassword} ref={passwordInput} />
//                 </InputBox>
//                 <StyledOptionBox>
//                     <StyledNumberSave>
//                         <label>
//                             <input type="checkbox" onChange={handleChangeSave} checked={isRemember} /><span><svg
//                             width="18px" height="13px">
// <image  x="0px" y="0px" width="18px" height="13px"  xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAANCAQAAAAOR/aSAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfkCxcRERs/R464AAAAUElEQVQY04XOQQ7AIAhE0c+k9z9IL0kX1kgUhJWYB4y9XMsB1JM78vlQTzD1BJ6/tZqsc16TmMkrAgqHPCdjkx3btoxKYh+9ku99JAS3isAHXFsOyLbVnkkAAAAASUVORK5CYII=" />
// </svg><svg
//                             width="18px" height="13px">
// <image  x="0px" y="0px" width="18px" height="13px"  xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAANCAQAAAAOR/aSAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfkCxcRFCGEPKNPAAAASklEQVQY04XOQQ7AMAgDwTXK/7/sXtoGpQW4IY20lmnPADGTHr2RmAmKmcC6X9Vk51yTvMkVgUgh/xOQz9iHPDl1ZG9STfJwVQQulQsQGuIqCVIAAAAASUVORK5CYII=" />
// </svg></span>사업자번호 저장
//                         </label>
//                     </StyledNumberSave>
//                     <StyledJoin><Link to="/join">· <span>사업자가입</span></Link></StyledJoin>
//                 </StyledOptionBox>
//                 <div>
//                     <StyledButton onClick={handleClickLogin}>로그인</StyledButton>
//                 </div>
//                 <StyledHr />
//                 <StyledPasswordSearch>
//                     <Link to="/forgot">· <span>비밀번호 찾기</span></Link>
//                 </StyledPasswordSearch>
//             </MemberWrap>
//         </MemberContainer>
    );
}

Login.propTypes = {
    isOpen: PropTypes.bool,
    close: PropTypes.func
};

const StyledContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    
    br {
        display: none;
    }
    
    .title-wrap {
        position: relative;
        
        img {
            width: 100%;
        }
        
        .title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 45px;
            font-weight: 200;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
            
            span {
                font-weight: 600;
            }
        }        
    }
    
    .category {
        max-width: 1050px;
        display: flex;
        margin: 110px auto 30px;
        
        div {
            width: 100%;
            max-width: 440px;
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            color: #434343;
            border-bottom: 2px solid #dcdcdc;
            
            &.active {
                max-width: 170px;
                border-bottom: 2px solid #f68b1f;
                padding-bottom: 12px;
            }
        }
    }
    
    @media (max-width: 940px) {
        margin: 60px auto 0;
    }
    
    @media (max-width: 475px) {
        br {
            display: block;
        }
    
        .title-wrap {
            img {
                height: 300px;
                object-fit: cover;
            }
            
            .title {
                font-size: 29px;
                text-align: center;
                width: 85%;
            }
        }
        
        .category {
            margin-top: 45px;
            
            div {
                font-size: 19px;
            }
        }
    }
`;

const StyledCategoryContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 30px auto 93px;
    max-width: 520px;
`;

const StyledOptionBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    
    & > label {
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;
        
        label {
            margin-right: 10px;
            font-size: 14px;
        }
    }
`;

const StyledPasswordInput = styled.input`
    width: 50%;
    border: none;
    outline: none;
`;

const StyledButtonWrap = styled.div`
    width: 100%;
`;

const StyledButton = styled.button`
    width: 100%;
    height: 60px;
    background-color: #a7be38;
    border: none;
    outline: none;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
`;

const StyledJoin = styled.div`
    font-size: 16px;
    color: #000;
    margin: 20px auto 0;
    
    a:first-child span {
        margin-right: 20px;
    }
`;

export default Login;