import React, {useEffect, useState} from 'react';
import {Footer, Header, RecentView, ScrollTop, SliderBox} from '../components';
import pensionBg from '../assets/images/pension/bg.jpg';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {callApi} from '../api';

const addressList = ['수동면', '오남읍', '조안면', '진접&진건읍', '화도읍'];
const address = ['수동', '오남', '조안', '진접:진건', '화도'];

function Search(props) {

    const category = useSelector((store) => store.category);


    const [pensionList, setPensionList] = useState(null);

    const addressTitle = addressList[address.indexOf(props.match.params.address)] === undefined ? "" : addressList[address.indexOf(props.match.params.address)];

    console.log(props.match.params.address, addressTitle);
    useEffect(() => {
        window.scrollTo(0, 0);

        // if(address.indexOf())

        const search = {
            // page: 1, // 기본값 1
            // pageSize: 20, // 현재 기본값 100개.
            category: category.category
        };


        callApi('/company/search/숙박' + (props.match.params.address !== undefined ? "/" + props.match.params.address : "") + props.location.search, 'POST', {}).then((res) => {
            setPensionList(res.list);
        }).catch((err) => {
            console.log(err);
        })

    }, [props.location.search, props.match.params.address]);

    const searchPensionList = pensionList ? pensionList.map((item, index) => {
        return (
            <SliderBox
                key={item.id}
                id={item.id}
                link={item.homepage}
                tag={item.cate1.slice(0, 3)}
                price={item.price}
                salePrice={item.salePrice}
                title={item.companyName}
                images={item.thumb}
            />
        )
    }) : <div>검색결과 없음.</div>;

    return (
        <>
            <Header/>

            <StyledContainer>
                <div className="title-wrap">
                    <img src={pensionBg} alt=""/>
                    <div className="title">
                        <span>나</span>에게 꼭 맞춘<br/> <span>남양주</span>펜션
                    </div>
                </div>

                <div className="category">
                    <div></div>
                    <div className="active">{addressTitle} 펜션 모음</div>
                    <div></div>
                </div>

                <StyledSearchOption>
                    {
                        category.category.map((item, index) => {
                            return (
                                <span key={index}>{item}</span>
                            )
                        })
                    }
                </StyledSearchOption>

                <StyledCategoryContainer>
                    {searchPensionList}
                </StyledCategoryContainer>
            </StyledContainer>

            <RecentView/>

            <Footer/>

            <ScrollTop/>
        </>
    );
}

const StyledContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    
    br {
        display: none;
    }
    
    .title-wrap {
        position: relative;
        
        img {
            width: 100%;
        }
        
        .title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 45px;
            font-weight: 200;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
            
            span {
                font-weight: 600;
            }
        }        
    }
    
    .category {
        max-width: 1050px;
        display: flex;
        margin: 110px auto 30px;
        
        div {
            width: 100%;
            max-width: 440px;
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            color: #434343;
            border-bottom: 2px solid #dcdcdc;
            
            &.active {
                width: 100%;
                max-width: 250px;
                border-bottom: 2px solid #f68b1f;
                padding-bottom: 12px;
            }
        }
    }
    
    @media (max-width: 940px) {
        margin: 60px auto 0;
    }
    
    @media (max-width: 475px) {
        br {
            display: block;
        }
    
        .title-wrap {
            img {
                height: 300px;
                object-fit: cover;
            }
            
            .title {
                font-size: 29px;
                text-align: center;
                width: 85%;
            }
        }
        
        .category {
            margin-top: 45px;
            
            div {
                font-size: 19px;
            }
        }
    }
`;

const StyledSearchOption = styled.div`
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    span {
        margin: 0 5px;
        padding: 5px 20px;
        background-color: #a7be38;
        border-radius: 5px;
        color: #fff;
    }
    
    @media (max-width: 740px) {
        margin-bottom: 20px;
    
        span {
            font-size: 14px;
            margin: 5px 5px;
        }
    }
`;

const StyledCategoryContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 30px auto 93px;
    max-width: 1440px;
    
    @media (max-width: 1460px) {
        max-width: 1080px;
    }
    
    @media (max-width: 1100px) {
        max-width: 720px;
    }
    
    @media (max-width: 740px) {
        max-width: 360px;
        margin: 0 auto 50px;
    }
`;

export default Search;