import React, {useEffect} from 'react';
import {Header, Footer, ScrollTop, RecentView, Breadcrumb} from '../components';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import bg from '../assets/images/tour/bg.jpg';
import img1 from '../assets/images/tour/1.jpg';
import img2 from '../assets/images/tour/2.jpg';
import img3 from '../assets/images/tour/3.jpg';
import img4 from '../assets/images/tour/4.jpg';
// import img5 from '../assets/images/tour/5.jpg';
import img6 from '../assets/images/tour/6.jpg';
import img7 from '../assets/images/tour/7.jpg';
// import img8 from '../assets/images/tour/8.jpg';
import citytour from '../assets/images/tour/citytour.jpg';
import restaurant from '../assets/images/tour/restaurant.jpg';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import GetAppIcon from '@material-ui/icons/GetApp';

const Tour = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const breadcrumbData = [
        {
            id: 1,
            title: '여행정보',
            link: '/tour',
            active: true
        }
    ];

    return (
        <>
            <Header />

            <StyledContainer>
                <div className="title-wrap">
                    <img src={bg} alt="" />
                    <div className="title">
                        <span>낭만</span>과 <span>힐링</span>이있는<br /> <span>남양주</span>로 떠나볼까요?
                    </div>
                </div>

                <Breadcrumb data={breadcrumbData} />

                {/*<div className="category">*/}
                {/*    <div></div>*/}
                {/*    <div className="active">주변 즐길 거리</div>*/}
                {/*    <div></div>*/}
                {/*</div>*/}

                <StyledImageWrap>
                    {/*<div className="box">*/}
                    {/*    <Link to="/tourlist/자연관광">*/}
                    {/*        <img src={img1} alt="자연관광" />*/}
                    {/*        <div>*/}
                    {/*            자연관광*/}
                    {/*        </div>*/}
                    {/*    </Link>*/}
                    {/*</div>*/}

                    {/*<div className="box">*/}
                    {/*    <Link to="/tourlist/문화관광">*/}
                    {/*        <img src={img2} alt="문화관광" />*/}
                    {/*        <div>*/}
                    {/*            문화관광*/}
                    {/*        </div>*/}
                    {/*    </Link>*/}
                    {/*</div>*/}

                    {/*<div className="box">*/}
                    {/*    <Link to="/tourlist/레저관광">*/}
                    {/*        <img src={img4} alt="레저관광" />*/}
                    {/*        <div>*/}
                    {/*            레저관광*/}
                    {/*        </div>*/}
                    {/*    </Link>*/}
                    {/*</div>*/}

                    {/*<div className="guide-book-wrap">*/}
                    {/*    <div className="guide-book">*/}
                    {/*        <div className="title">*/}
                    {/*            <div><ImportContactsIcon /></div>*/}
                    {/*            <div>관광안내책자</div>*/}
                    {/*        </div>*/}
                    {/*        <div className="download-wrap">*/}
                    {/*            <div><a href="https://www.nyj.go.kr/File/Download/8367fda91bfc2966b74af87b9a23c087" target="_blank" rel="noopener noreferrer">관광지도(다운로드)<GetAppIcon /></a></div>*/}
                    {/*            <div><a href="https://www.nyj.go.kr/File/Download/97dcc24faacafec7ec8476b4808baa41" target="_blank" rel="noopener noreferrer">가이드북(다운로드)<GetAppIcon /></a></div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </StyledImageWrap>
            </StyledContainer>

            <RecentView />

            <Footer />

            <ScrollTop />
        </>
    );
};

const StyledContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    
    br {
        display: none;
    }
    
    .title-wrap {
        position: relative;
        
        img {
            width: 100%;
        }
        
        .title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 45px;
            font-weight: 200;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
            
            span {
                font-weight: 600;
            }
        }        
    }
    
    .category {
        max-width: 1050px;
        display: flex;
        margin: 110px auto 30px;
        
        div {
            width: 100%;
            max-width: 440px;
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            color: #434343;
            border-bottom: 2px solid #dcdcdc;
            
            &.active {
                width: 100%;
                max-width: 170px;
                border-bottom: 2px solid #f68b1f;
                padding-bottom: 12px;
            }
        }
    }
    
    @media (max-width: 940px) {
        margin: 60px auto 0;
    }
    
    @media (max-width: 475px) {
        br {
            display: block;
        }
    
        .title-wrap {
            img {
                height: 300px;
                object-fit: cover;
            }
            
            .title {
                font-size: 29px;
                text-align: center;
                width: 74%;
            }
        }
        
        .category {
            margin-top: 45px;
            
            div {
                font-size: 19px;
            }
        }
    }
`;

const StyledImageWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto 140px;
    justify-content: center;
    
    .box {
        margin: 10px;
        width: 280px;
        height: 280px;
        
        a {
            display: block;
            position: relative;
            overflow: hidden;
            width: 280px;
            height: 280px;
            
            &:hover img {
                transform: scale(1.2);
            }
            
            div {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 20px;
                font-weight: 500;
                color: #fff;
                border-bottom: 1px solid rgba(255, 255, 255, 0.5);
                width: 130px;
                padding-bottom: 8px;
                text-align: center;
            }
            
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: transform 0.3s;
            }
        }
    }
    
    .guide-book-wrap {
        width: 100%;
    }
    
    .guide-book {
        max-width: 360px;
        margin: 20px auto 0;
        padding: 10px;
        border: 1px solid #bfbfbf;
        
        display: flex;
        flex-direction: column;
        align-items: center;
        
        .title {
            font-size: 18px;
            display: flex;
            margin-bottom: 10px;
            border-bottom: 1px solid #555555;
            
            div:first-child {
                margin-right: 5px;
            }
        }
        
        .download-wrap {
            display: flex;
            
            div a {
                display: flex;
                border: 1px solid #bfbfbf;
                background-color: #a7be38;
                border-radius: 5px;
                padding: 5px 10px;
                color: #fff;
                font-size: 14px;
                align-items: center;
                
                &:hover {
                    background-color: #86982d;
                }
            }
            
            div:first-child {
                border-right: 1px solid #555;
                margin-right: 10px;
                padding-right: 10px;
            }
        }
    }
    
    @media (max-width: 1220px) {
        max-width: 900px;
    }
    
    @media (max-width: 920px) {
        max-width: 600px;
    }
    
    @media (max-width: 620px) {
        max-width: 300px;
        margin: 0 auto 50px;
        
        .guide-book {
            .title {
                font-size: 16px;
            }
        
             .download-wrap {
                div a {
                    font-size: 11px;
                    padding: 5px;
                }
             }
        }
    }
`;

export default Tour;