import React from 'react';
import styled from 'styled-components';
import {Header, Footer, ScrollTop, RecentView, Breadcrumb} from '../../components';
import bg from '../../assets/images/assoc/orginfo-bg.jpg';
import orgData from './orgData.json';
import '../../assets/css/organization.css';

function OrgInfo(props) {
    const breadcrumb = [{
        id: 1,
        title: '조직도',
        link: '/orginfo'
    }];

    return (
        <>
            <Header/>

            <StyledContainer>
                <div className="title-wrap">
                    <img src={bg} alt=""/>
                    <div className="title">
                        <span>조직도</span>
                    </div>
                </div>

                <Breadcrumb data={breadcrumb}/>

                <div className="category">
                    <div></div>
                    <div className="active">조직도</div>
                    <div></div>
                </div>

                <StyledCategoryContainer>
                    <div className="org-row">
                        <div className="org-box first">
                            <div>사)한국농어촌민박협회</div>
                            <div>경기도지부</div>
                        </div>
                    </div>
                    <div className="col-line"></div>
                    <div className="org-row">
                        <div className="org-box hidden">
                        </div>
                        <div className="row-line-hidden"></div>
                        <div className="org-box important">
                            <div>남양주시지회장</div>
                        </div>
                        <div className="row-line-hidden"></div>
                        <div className="org-box hidden">
                        </div>
                    </div>
                    <div className="col-line"></div>
                    <div className="org-row">
                        <div className="org-box">
                            <div>감사</div>
                        </div>
                        <div className="row-line"></div>
                        <div className="org-box">
                            <div>부회장</div>
                        </div>
                        <div className="row-line-hidden"></div>
                        <div className="org-box hidden">
                        </div>
                    </div>
                    <div className="col-line"></div>
                    <div className="org-row">
                        <div className="org-box hidden">
                        </div>
                        <div className="row-line-hidden"></div>
                        <div className="org-box">
                            <div>사무국장</div>
                        </div>
                        <div className="row-line-hidden"></div>
                        <div className="org-box hidden">
                        </div>
                    </div>
                    <div className="col-line"></div>
                    <div className="org-row">
                        <div className="org-box">
                            <div>기획홍보이사</div>
                        </div>
                        <div className="row-line"></div>
                        <div className="org-box">
                            <div>회원전략이사</div>
                        </div>
                        <div className="row-line"></div>
                        <div className="org-box">
                            <div>안전관리이사</div>
                        </div>
                    </div>

                </StyledCategoryContainer>
                <table className="org-table">
                    <thead>
                    <tr>
                        <th>직 책</th>
                        <th>이 름</th>
                        <th>소 속</th>
                        <th>전 화 번 호</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>협회장</td>
                        <td>김 정 기</td>
                        <td>수안채</td>
                        <td>010-9425-1773</td>
                    </tr>
                    <tr>
                        <td>부회장</td>
                        <td>김 동 섭</td>
                        <td>편센121</td>
                        <td>010-8524-1029</td>
                    </tr>
                    <tr>
                        <td>감 사</td>
                        <td>오 태 섭</td>
                        <td>펜션데일리</td>
                        <td>010-3722-9042</td>
                    </tr>
                    <tr>
                        <td>사무국장</td>
                        <td>박 정 민</td>
                        <td>노블리안하우스</td>
                        <td>010-5303-1615</td>
                    </tr>
                    <tr>
                        <td>기획홍보이사</td>
                        <td>신 보 현</td>
                        <td>마음농장위드스테이</td>
                        <td>010-3298-7779</td>
                    </tr>
                    <tr>
                        <td>회원전략이사</td>
                        <td>김 형 래</td>
                        <td>해마루</td>
                        <td>010-3779-6511</td>
                    </tr>
                    <tr>
                        <td>안전관리이사</td>
                        <td>여 희 준</td>
                        <td>하비비</td>
                        <td>010-5269-2700</td>
                    </tr>
                    </tbody>
                </table>
            </StyledContainer>

            <RecentView/>

            <Footer/>

            <ScrollTop/>
        </>
    );
}

const StyledContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    
    .title-wrap {
        position: relative;
        
        img {
            width: 100%;
        }
        
        .title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 45px;
            font-weight: 200;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
            
            span {
                font-weight: 600;
            }
        }        
    }
    
    .category {
        max-width: 1050px;
        display: flex;
        margin: 110px auto 30px;
        
        div {
            width: 100%;
            max-width: 440px;
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            color: #434343;
            border-bottom: 2px solid #dcdcdc;
            
            &.active {
                max-width: 170px;
                border-bottom: 2px solid #f68b1f;
                padding-bottom: 12px;
            }
        }
    }
    
    @media (max-width: 940px) {
        margin: 60px auto 0;
    }
    
    @media (max-width: 475px) {
    
        .title-wrap {
            img {
                height: 300px;
                object-fit: cover;
            }
            
            .title {
                font-size: 29px;
                text-align: center;
                width: 85%;
            }
        }
        
        .category {
            margin-top: 45px;
            
            div {
                font-size: 19px;
            }
        }
    }
`;

const StyledCategoryContainer = styled.div`
    margin: 100px auto 110px;
    // max-width: 510px;
    max-width: 800px;
    display:flex;
    flex-direction: column;
    align-items: center;
    
    .row-wrap {
        display: flex;
        
        div {
            flex: 1;
            font-size: 18px;
            color: #434343;
            height: 47px;
            display: flex;
            justify-content: center;
            align-items: center;
            
            &.position {
                border-bottom: 3px solid #dcdcdc;
            }
            
            &.name {
                font-weight: 300;
                border-bottom: 1px solid #dcdcdc;
            }
            
            &.phone {
                font-weight: 300;
                border-bottom: 1px solid #dcdcdc;
            }
        }
    }
    
    @media (max-width: 660px) {
        margin: 40px auto;
        
        .row-wrap {
            div {
                font-size: 16px;
            }
        }
    }
`;

export default OrgInfo;