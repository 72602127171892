import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import {Provider} from 'react-redux';
import store, {history} from './redux/configureStore';
import ReactDOM from 'react-dom';
import App from './shared/App';
import {CookiesProvider} from 'react-cookie';
import {ConnectedRouter} from "connected-react-router";

ReactDOM.render(
    <Provider store={store}>
        <CookiesProvider>
            <ConnectedRouter history={history}>
                <App/>
            </ConnectedRouter>
        </CookiesProvider>
    </Provider>,
    document.getElementById('root')
);
