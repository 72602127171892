import React, {useEffect} from 'react';
import {Header, Footer, ScrollTop, RecentView, Breadcrumb} from '../../components';
import styled from 'styled-components';
import bg from "../../assets/images/assoc/greetings-bg.jpg";

function Partners(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const breadcrumb = [{
        id: 1,
        title: '협회정관',
        link: '/articles'
    }];

    return (
        <>
            <Header/>

            <StyledContainer>
                <div className="title-wrap">
                    <img src={bg} alt=""/>
                    <div className="title">
                        <span>협회정관</span>
                    </div>
                </div>

                <Breadcrumb data={breadcrumb}/>

                <div className="category">
                    <div></div>
                    <div className="active">협회정관</div>
                    <div></div>
                </div>


                <StyledCategoryContainer>
                    <h2>사단법인 한국농어촌민박(펜션)협회 남양주시지회 정관</h2><br/><br/>
                    {`목적
    남양주시 수동, 화도, 기타 인접지역 펜션 및 농어촌민박업자들의 단체로 관광산업 활성화와 가입회원의 권익증진 및 친목도모에 기여함을 목적으로 한다.

제 1 장 총     칙
    
        제1조: 본 모임 명칭은 “사단법인 한국농어촌민박(펜션)협회 남양주시지회”(이하 본 협회) 라고 칭한다.
        제2조: 본 협회는 회원 상호간의 공공복지와 친목을 도모하며 회원들의 영업증진 및 남양주시 지역사회에 기여함을 목적으로 한다.
        제3조: 본 협회의 주사무소는 경기도 남양주시 내에 둔다.(회장, 사무국장에게 일임한다)
        
제 2 장  회원
    
        본 협회 회원은 정회원과 준회원으로 구분한다.
    
        제4조: 회원의 자격
            1. 본 협회 회원은 다음의 자격을 가진 자로서 본 협회 설립목적에 동의하고 다음의 의무를 다하는 자로 한다.
       가. 정회원 : 다음의 요건을 모두 충족하는 자
            1) 농어촌민박업법 또는 생활형숙박법에 의거 남양주시로부터 민박업 또는 생활형숙박업으로 지정을 받은자
            2) 협회에서 정한 가입절차에 따라 회원가입을 하고 회비를 납부한자
    
       나. 준회원 : 상기 가-1)항에 해당하는 자
    
        2. 본 협회 정회원의 가입요건, 절차 등은 별도로 정한다.
        3. 본 정관에서 회원이라 함은 별도의 언급이 있지 않는 한 정회원을 말한다.
    
        제5조: 본 협회에 가입 하고자 하는 이는 가입금과 홈페이지 또는 사진을 제출한다.
            1) 입회서가 제출되면 회장은 임원(운영위원)회 열어 입회를 결정한다.
            2) 가입금 - 자율적(회원증 회원패 지급)
            3) 연회비 – 금십오만원(분납가능)
    
        제6조 정회원의 권리와 의무
        본 협회의 회원은 다음과 같은 권리와 의무를 갖는다.
        1. 권리
        가. 총회참석권 및 발언권
        나. 총회에서의 의결권, 선거권, 피선거권
        다. 회계자료, 총회자료, 운영원회자료 등 각종 협회관련 서류의 열람권
        라. 협회에서 결정한 모든 혜택사항에 대하여 공평한 분배를 받을 권리
        2. 의무
        가. 협회의 결정사항에 대한 준수와 참여 및 협조의무
        나. 회원 간의 친목과 공동발전을 위한 노력의무
        다. 협회의 권위와 목적에 해가 되는 언행을 하지 않을 의무
    
        제7조: 자격상실 및 탈퇴
        1. 자격상실(강제탈퇴) : 회원이 다음 중 어느 일부분에 해당하는 경우 자격을 상실한다.
       가. 협회의 명예, 재산, 목적, 설립취지, 정책방향 등에 비추어 심각한 위해를 가한 경우
       나. 협회의 회원 또는 임원의 자격을 이용하여 개인의 이익을 추구한 경우
       다. 회비를 1년 이상 납부하지 않은 경우
    
        2. 탈퇴 : 회원은 본인의 의사에 따라 협회를 탈퇴할 수 있으며, 탈퇴시 기납부한 회비 또는 협회기금을 반환 또는 분배하지 않는다.
    
    제  3  장  임 원
    
        제8조: 본회는 다음의 운영위원을 둔다.
    
            1. 회    장      1인
            2. 사무국장      1인
            3. 부 회 장      2인
            4. 감    사      2인
            5. 이    사      2인
            6. 운영위원      3인
            7. 간 사(유급)    1인
    
        제9조: (임원의 선임, 임기)
            1. 회장은 회원중으로 12월정기총회에서 선출한다.
            2. 본 회의 회장의 임기는 2년으로 중임할 수 있다.
            3. 회장을 제외한 임원은 연임 할 수 있다.
            4. 사무국장, 감사, 이사, 운영위원 은 12월총회에서 선출된 회장이 지명한다.
    
    제  4  장   모   임
    
        제 10조:(소집)
            1. 모임은 정기총회와 정기모임으로 나눈다.
            2. 정기총회 연 1회 12월 소집한다.(필요시 예외일 수 있다)
            3. 정기모임은  친목도모를 위해 년 6회로 정한다.
            4. 정기모임 및 정기총회 때는 사무국장은 1주일 전 전화, 문자, 밴드 S.N,S, 우편물을 통해 회원 및 배우자에게 통보한다.
    
    제  5  장   회   계
    
        제 11조: 회 계
            1. 본 협회의 재정은 회비, 후원금으로 한다.
            2. 회비는 입회시 일시불 납부 할 수 있고 매월 분할 납부 할 수 있다.
            3. 협회의 결산 보고는 2회 분기별로 보고 한다.
            4. 운영위원회의 의결을 거쳐 부대사업을 실시 할 수  있다.
            5. 감사는 6월말 12월 총회 전 감사 보고서에 서명 날인한다.
    
    제  6  장  사 무 부 서
        
        제 12조 (사무국)
        
            1. 본 협회는 목적사업을 수행하기 위하여 사무국을 둘 수 있다.
            2. 사무국에 필요한 직원을 둘 수 있다. (간사는 유급)
            3. 사무국의 부서, 조직 및 운영에 관한 사항은 운영위원회의 의결을
            거쳐 별도로 정한다.
        
        제 13 조 경조사
        
            1. 직계가족(부모, 처부모, 본인, 배우자, 자녀)에 한하며 경조사비는 십만원(화한 및  현금)으로 한다.
    
    제  7  장   부    칙
    
        제 14 조  (내규 및 준용규칙)
            내규는 운영위원회의 의결로 재정 및 개정하여 정관고 내규에 명시되지 않은 사항은 민주주의의 일반 원칙에 따른다.`}
                </StyledCategoryContainer>


            </StyledContainer>

            <RecentView/>

            <Footer/>

            <ScrollTop/>
        </>
    );
}

const StyledContainer = styled.div`
                        width: 100%;
                        max-width: 1920px;
                        margin: 0 auto;

                        .title-wrap {
                        position: relative;

                        img {
                        width: 100%;
                    }

                        .title {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        color: #fff;
                        font-size: 45px;
                        font-weight: 200;
                        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);

                        span {
                        font-weight: 600;
                    }
                    }
                    }

                        .category {
                        max-width: 1050px;
                        display: flex;
                        margin: 110px auto 30px;

                        div {
                        width: 100%;
                        max-width: 440px;
                        text-align: center;
                        font-size: 24px;
                        font-weight: 500;
                        color: #434343;
                        border-bottom: 2px solid #dcdcdc;

                        &.active {
                        max-width: 170px;
                        border-bottom: 2px solid #f68b1f;
                        padding-bottom: 12px;
                    }
                    }
                    }

                        @media (max-width: 940px) {
                        margin: 60px auto 0;
                    }

                        @media (max-width: 475px) {

                        .title-wrap {
                        img {
                        height: 300px;
                        object-fit: cover;
                    }

                        .title {
                        font-size: 29px;
                        text-align: center;
                        width: 85%;
                    }
                    }

                        .category {
                        margin-top: 45px;

                        div {
                        font-size: 19px;
                    }
                    }
                    }
                        `;

const StyledCategoryContainer = styled.div`
                        max-width: 1050px;
                        margin:100px auto 117px;
                        text-align: justify;
                        white-space: pre-wrap;
                        line-height: 30px;

                        .mobile-hide {
                        display: none;
                    }

                        h2 {
                        display:flex;
                        justify-content:center;
                        font-size: 30px;
                    }

                        h3 {
                        font-size: 21px;
                    }

                        div {
                        line-height: 24px;
                        font-size: 16px;
                    }

                        @media (max-width: 660px) {
                        margin: 60px auto 60px;

                        .mobile-hide {
                        display: none;
                    }

                        h2 {
                        font-size: 25px;
                    }

                        h3 {
                        font-size: 17px;
                    }

                        div {
                        margin: 0;
                        font-size: 14px;
                    }
                    }
                        `;

export default Partners;