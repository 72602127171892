import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {SliderBox} from '../../components';

// import mainPensionNewData from '../../dummy/mainPensionNewData.json';
// import mainPensionBestData from '../../dummy/mainPensionBestData.json';
// import mainPensionSaleData from '../../dummy/mainPensionSaleData.json';

import {callApi} from '../../api';
import MapOutlinedIcon from "@material-ui/icons/MapOutlined";
import {useHistory} from "react-router-dom";

function MainRecommend(props) {
    const history = useHistory();

    const [allPensionData, setAllPensionData] = useState([]);
    useEffect(() => {
        callApi('/company/숙박?best=1', 'GET', {}).then((res) => {
            if (res) {
                // setAllPensionData(res.list);
                let temp = res.list;
                // 메인에는 12개의 펜션만 표시
                let temp2 = temp.slice(0, 12);
                setAllPensionData(temp2);
            }
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    // 펜션 랜덤으로 보여주기
    function shuffle(array) {
        let currentIndex = array.length, temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    }

    // const [activeBest, setActiveBest] = useState(true);
    const activeBest = true;

    // const randomNewData = shuffle(mainPensionNewData);
    // const randomBestData = shuffle(mainPensionBestData);
    // const randomSaleData = shuffle(mainPensionSaleData);
    const randomBestData = shuffle(allPensionData);

    // Best Product
    const bestProductList = randomBestData.map((item, index) => {
        return (
            <SliderBox
                key={item.id}
                id={item.id}
                link={item.homepage}
                tag={[item.cate1[0], item.cate1[1], item.cate1[2]]}
                price={item.salePrice ? item.salePrice : item.price}
                salePer={item.salePrice ? 100 - Math.ceil(item.salePrice / item.price * 100) : 0}
                // salePrice={item.salePrice}
                title={item.companyName}
                images={item.thumb}
            />
        )
    });

    // 지도에서 보기
    function handleClickMap() {
        history.push('/map');
    }

    return (
        <StyledContainer>
            <div className="title">
                가자가 추천하는 남양주펜션
            </div>

            <div className="category pc">
                <div></div>
                <div className='active'></div>
                <div></div>
                <button onClick={handleClickMap}><MapOutlinedIcon /> 지도에서 보기</button>
            </div>

            <StyledCategoryContainer className="pc">
                {
                    activeBest &&
                    bestProductList
                }
            </StyledCategoryContainer>

            <div className="category mobile">
                <div></div>
                <div className='active'></div>
                <div></div>
                <button onClick={handleClickMap}><MapOutlinedIcon /> 지도에서 보기</button>
            </div>

            <StyledCategoryContainer className="mobile">
                {
                    bestProductList
                }
            </StyledCategoryContainer>
        </StyledContainer>
    );
}

const StyledContainer = styled.div`
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    
    .title {
        font-size: 24px;
        font-weight: 500;
        color: #434343;
        margin-top: 110px;
        text-align: center;
    }
    
    .category {
        display: flex;
        justify-content: center;
        position: relative;
        max-width: 1440px;
        margin: 0 auto;
        
        &.mobile {
            display: none;
        }
        
        div {
            text-align: center;
            width: 170px;
            height: 26px;
            font-size: 18px;
            font-weight: 500;
            color: #535353;
            border-bottom: 2px solid #dcdcdc;
            
            &.active {
                color: #f68b1f;
                border-bottom: 2px solid #f68b1f;
            }
        }
        
        button {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            height: 30px;
            cursor: pointer;
            position: absolute;
            top: -29px;
            right: 10px;
            background-color: #a7be38;
            color: #fff;
            border: none;
            outline: none;
            
            &:hover {
                background-color: #86982d;
            }
        }
    }
    
    @media (max-width: 1461px) {
        .category {
            max-width: 1080px;
        }
    }
    
    @media (max-width: 1101px) {
        .category {
            max-width: 720px;
        }
    }
    
    @media (max-width: 940px) {
        .title {
            margin-top: 45px;
        }
        
        .category {       
            &.pc {
                display: none;
            }
            
            &.mobile {
                display: flex;
                
                div {
                    cursor: default;
                }
            }
        }
    }
    
    @media (max-width: 736px) {
        .category {
            max-width: 360px;
            
            button {
                top: -28px;
                font-size: 0px;
            }
        }
    }
`;

const StyledCategoryContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    // margin: 30px auto 93px;
    margin: 30px auto 30px;
    //justify-content: center;
    justify-content: space-between;
    
    &.mobile {
        display: none;
    }
    
    @media (max-width: 1456px) {
        // max-width: 1080px;
    }
    
    @media (max-width: 1096px) {
        // max-width: 720px;
    }
    
    @media (max-width: 940px) {
        max-width: 720px;
        justify-content: center;
        &.pc {
            display: none;
        }
        
        &.mobile {
            display: flex;
        }
        
        margin: 30px auto 0;
    }
`;

export default MainRecommend;