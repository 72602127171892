import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import {Header, Footer, DetailSlider, RecentView, ScrollTop, Breadcrumb} from '../components';
import {device} from '../config/device';
import {callApi} from '../api';
import {useParams} from 'react-router-dom';
import '../assets/css/map.css';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import LocalParkingOutlinedIcon from '@material-ui/icons/LocalParkingOutlined';
import HeadsetMicOutlinedIcon from '@material-ui/icons/HeadsetMicOutlined';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import gtIcon from '../assets/images/detail/gt-icon.png';
import squareIcon from '../assets/images/detail/square-icon.png';
import icon1 from '../assets/images/icon/1.barbeque.png';

import icon2 from '../assets/images/icon/2.cafe.png';
import icon3 from '../assets/images/icon/3.camping.png';
import icon4 from '../assets/images/icon/4.couple.png';
import icon5 from '../assets/images/icon/5.family.png';
import icon6 from '../assets/images/icon/6.foot_volleyball.png';
import icon7 from '../assets/images/icon/7.group.png';
import icon8 from '../assets/images/icon/8.kids.png';
import icon9 from '../assets/images/icon/9.lawn_playground.png';
import icon10 from '../assets/images/icon/10.pet.png';
import icon11 from '../assets/images/icon/11.pool.png';
import icon12 from '../assets/images/icon/12.pool_villa_single_house.png';
import icon13 from '../assets/images/icon/13.singing_room.png';
import icon14 from '../assets/images/icon/14.single_house.png';
import icon15 from '../assets/images/icon/15.vallery.png';
import icon16 from '../assets/images/icon/16.whirlpool.png';
import icon17 from '../assets/images/icon/17.zet_spa.png';
import icon18 from '../assets/images/icon/18.workshop.png';
import icon19 from '../assets/images/icon/1.barbeque.png';
import icon20 from '../assets/images/icon/20.png';
import icon21 from '../assets/images/icon/21.check.png';

import IframeResizer from "iframe-resizer-react";
import ReactDOM from 'react-dom'

const Detail = () => {

    let {id} = useParams();

    const iframeRef = useRef(null)

    const [pensionData, setPensionData] = useState(false);

    const [pensionPhone, setPensionPhone] = useState();
    const [pensionImages, setPensionImages] = useState([]);
    const [pensionContents, setPensionContents] = useState(false);

    const [kakaoMap, setKakaoMap] = useState();

    const [breadcrumb, setBreadcrumb] = useState([
        {
            id: 1,
            title: '펜션안내',
            link: '/pension'
        },
    ]);

    const iconList = {
        1: {
            text: "개별바베큐",
            icon: icon1
        },
        2: {
            text: "카페",
            icon: icon2
        },
        3: {
            text: "캠핑",
            icon: icon3
        },
        4: {
            text: "커플",
            icon: icon4
        },
        5: {
            text: "가족",
            icon: icon5
        },
        6: {
            text: "족구장",
            icon: icon6
        },
        7: {
            text: "단체",
            icon: icon7
        },
        8: {
            text: "키즈컨셉",
            icon: icon8
        },
        9: {
            text: "잔디운동장",
            icon: icon9
        },
        10: {
            text: "반려동물가능",
            icon: icon10
        },
        11: {
            text: "수영장",
            icon: icon11
        },
        12: {
            text: "풀빌라/풀빌라독채",
            icon: icon12
        },
        13: {
            text: "노래방",
            icon: icon13
        },
        14: {
            text: "독채",
            icon: icon14
        },
        15: {
            text: "계곡",
            icon: icon15
        },
        16: {
            text: "월풀",
            icon: icon16
        },
        17: {
            text: "제트스파",
            icon: icon17
        },
        18: {
            text: "워크샵",
            icon: icon18
        },
        19: {
            text: "바베큐",
            icon: icon19
        },
        20: {
            text: "리버뷰",
            icon: icon20
        },
        21: {
            text: "협회인증",
            icon: icon21
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        callApi('/company/detail/' + id, 'GET', {}).then((res) => {

            if (res) {
                setPensionData(res);

                let breadcrumbTitle;
                let breadcrumbLink;
                if (res.type === '딸기') {
                    breadcrumbTitle = '딸기체험';
                    breadcrumbLink = '/strawberry';
                } else if (res.type === '농장') {
                    breadcrumbTitle = '농업체험';
                    breadcrumbLink = '/farm';
                } else {
                    breadcrumbTitle = '펜션안내';
                    breadcrumbLink = '/pension';
                }
                setBreadcrumb([
                    {
                        id: 1,
                        title: breadcrumbTitle,
                        link: breadcrumbLink
                    },
                    {
                        id: 2,
                        title: res.companyName,
                        link: '/detail/' + res.id
                    }
                ]);

                // 펜션 전화번호 포맷
                function formatPhoneNumber(str) {
                    // Filter only numbers from the input
                    let cleaned = ('' + str).replace(/\D/g, '');

                    // Check if the input is of correct length
                    let match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);

                    if (match) {
                        return match[1] + '-' + match[2] + '-' + match[3];
                    }

                    return null;
                }

                if (formatPhoneNumber(res.phone) === null) {
                    setPensionPhone(res.phone);
                } else {
                    setPensionPhone(formatPhoneNumber(res.phone));
                }

                setPensionImages(res.images);

                if (res.contents) {
                    setPensionContents(res.contents);
                }
            }
        }).catch((err) => {
            console.log(err);
        });

    }, [id]);

    useEffect(() => {

        if (pensionData.id !== undefined) {
            // 주소로 좌표 검색
            let geocoder = new window.kakao.maps.services.Geocoder();
            geocoder.addressSearch(pensionData.address, function (result, status) {
                // 검색 성공
                if (status === window.kakao.maps.services.Status.OK) {
                    let coords = new window.kakao.maps.LatLng(result[0].y, result[0].x);

                    let mapContainer = document.getElementById('map'),
                        mapOption = {
                            center: coords,
                            level: 3
                        };

                    let map = new window.kakao.maps.Map(mapContainer, mapOption);
                    setKakaoMap(map);

                    let marker = new window.kakao.maps.Marker({
                        map: map,
                        position: coords,
                        title: pensionData.companyName
                    });

                    var content = '<div class="customoverlay">' +
                        '  <a href="/detail/' + pensionData.id + '">' +
                        '    <span class="title">' + pensionData.companyName + '</span>' +
                        '  </a>' +
                        '</div>';

                    let overlay = new window.kakao.maps.CustomOverlay({
                        content: content,
                        map: map,
                        position: marker.getPosition()
                    });

                    overlay.setMap(map);
                } else {
                    // 남양주시청 위도경도
                    const namCityLatLng = {Lat: 37.6359871, Lng: 127.216322};
                    let mapContainer = document.getElementById('map'),
                        mapOption = {
                            center: new window.kakao.maps.LatLng(namCityLatLng['Lat'], namCityLatLng['Lng']),
                            level: 8
                        };
                    let map = new window.kakao.maps.Map(mapContainer, mapOption);
                    setKakaoMap(map);
                }
            });
        }

    }, [pensionData]);


    function zoomIn() {
        kakaoMap.setLevel(kakaoMap.getLevel() - 1);
    }

    function zoomOut() {
        kakaoMap.setLevel(kakaoMap.getLevel() + 1);
    }

    if (!pensionData) {
        return (<div>로딩중..</div>);
    }

    const onLoad = () => {
        window.addEventListener(
            "message",
            (e) => {
                console.log(e);
            },
            false
        );
    }

    return (
        <>
            <Header/>

            <StyledBreadcrumb>
                <Breadcrumb data={breadcrumb}/>
            </StyledBreadcrumb>

            <DetailSlider images={pensionImages}/>

            <DetailContainer>
                <DetailHeader>
                    <span>{pensionData.companyName}</span>
                </DetailHeader>

                <PensionInfo>

                    <div className="row pension-type">
                        <div className="info-title"><img src={squareIcon} alt=""/>&nbsp;펜션유형 / 시설</div>
                        <div className="facility">
                            {
                                pensionData.cate1.map((value, index) => {
                                    // tempPensionData.cate1.map((value, index) => {
                                    return (
                                        <div className="facility-icon" key={index}>
                                            <img src={iconList[value.cate_id].icon} alt=""/>
                                            {iconList[value.cate_id].text}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <GotoHomepageLink href={pensionData.homepage} target="_blank" rel="noopener noreferrer">홈페이지
                        바로가기 &nbsp;
                        <img src={gtIcon} alt=""/></GotoHomepageLink>

                    <div className="row desc">
                        <div className="flex">
                            <div><img src={squareIcon} alt=""/></div>
                            &nbsp;
                            <div>{pensionData.desc}</div>
                        </div>
                        <div className="desc-detail" dangerouslySetInnerHTML={{__html: pensionData.descDetail}}></div>
                    </div>


                    <div className="row mb6">
                        <div className="info-title"><img src={squareIcon} alt=""/>&nbsp;예약문의</div>
                        <div className="info-text phone">{pensionPhone}</div>
                    </div>

                    {/*<IframeResizer*/}
                    {/*    forwardRef={iframeRef}*/}
                    {/*    heightCalculationMethod="lowestElement"*/}
                    {/*    inPageLinks*/}
                    {/*    log*/}
                    {/*    onLoad={(e)=>console.log(e.target.contentWindow)}*/}
                    {/*    autoResize={true}*/}
                    {/*    src="//booking.ddnayo.com/?accommodationId=3830"*/}
                    {/*    style={{minWidth: '100%', overflow: 'auto'}}*/}
                    {/*/>*/}

                    {pensionData.reservationLink !== undefined && pensionData.reservationLink !== "" && (
                        <iframe
                            src={pensionData.reservationLink}
                            style={{minWidth: '100%', height: '1600px'}}
                            frameBorder="0"
                            // onLoad={onLoad}
                            ref={iframeRef}
                        />

                    )}


                    <div className="row mb6">
                        <div className="info-title"><img src={squareIcon} alt=""/>&nbsp;홈페이지</div>
                        <div className="info-text web"><a href={pensionData.homepage} target="_blank"
                                                          rel="noopener noreferrer">{pensionData.homepage}</a></div>
                    </div>
                    <div className="row">
                        <div className="info-title"><img src={squareIcon} alt=""/>&nbsp;오시는 길</div>
                        <div className="info-text">{pensionData.address} {pensionData.addressDetail}</div>
                    </div>

                    <div className="map-wrap">
                        <div id="map"></div>
                        <div className="custom_zoomcontrol radius_border">
                            <span onClick={zoomIn}><img
                                src="https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/ico_plus.png"
                                alt="확대"/></span>
                            <span onClick={zoomOut}><img
                                src="https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/ico_minus.png" alt="축소"/></span>
                        </div>
                    </div>
                </PensionInfo>

                {
                    pensionContents && (
                        <StyledPensionContents
                            dangerouslySetInnerHTML={{__html: pensionContents}}></StyledPensionContents>
                    )
                }
            </DetailContainer>

            <RecentView/>

            <Footer/>

            <ScrollTop/>
        </>
    );
};

const DetailContainer = styled.div`
    max-width: 1210px;
    margin: 0 auto 150px;
    padding: 0;
    
    @media ${device.small} {
        padding: 20px;
        margin: 0 auto;
    }
`;

const DetailHeader = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 30px;
    
    border-bottom: 2px solid #dcdcdc;
    
    span {
        font-size: 24px;
        font-weight: 500;
        color: #434343;
        padding-bottom: 10px;
        padding-left: 50px;
        padding-right: 50px;
        margin-bottom: -2px;
        border-bottom: 2px solid #f68b1f;
    }
    
    @media (max-width: 576px) {
        span {
            padding-left: 0;
            padding-right: 0;
        }
    }
    
    
    
    .title-box {
        div:first-child {
            font-size: 18px;
            color: #666;
        }
        
        div:last-child {
            font-size: 24px;
            color: #000;
        }
    }
    
    .icon-box {
        font-size: 18px;
        color: #000;
        
        button {
            display: flex;
            outline: none;
            background: none;
            border: none;
            cursor: pointer;
            
            span {
                border-bottom: 1px solid #000;
                margin-left: 10px;
            }
        }
    }
    
    @media ${device.small} {
        margin: 0 0 20px;
    
        .title-box {
            div:first-child {
                font-size: 16px;
            }
            
            div:last-child {
                font-size: 22px;
            }
        }
        
        .icon-box {
            font-size: 16px;
            
            img {
                transform: scale(0.8);
            }
            
            button span {
                margin-left: 5px;
            }
        }
    }
`;

const Divider = styled.hr`
    display: block; 
    height: 1px;
    border: 0; 
    border-top: 1px solid #ccc;
    margin: 0; 
    padding: 0;
`;

const PensionInfo = styled.div`
    margin-top: 30px;
    
    .flex {
        display: flex;
        align-items: flex-start;
    }
    
    .row {
        display: flex;
        align-items: center;
        margin-bottom: 0px;
        padding-left: 40px;
        
        @media (max-width: 576px) {
            padding-left: 0;
        }
        
        &.desc {
            padding-top: 40px;
            height: auto;
            flex-direction: column;
            align-items: inherit;
            color: #434343;
            
            .desc-detail {
                margin-top: 20px;
                line-height: 24px;
                
                @media (max-width: 576px) {
                    font-size: 14px;
                }
            }
            
            @media (max-width: 576px) {
                padding-left: 0;
            }
        }
        
        &.pension-type {
            margin: 25px 0 35px;
            flex-direction: column;
            align-items: unset;
            
            .facility {
                margin-top: 18px;
                border: 1px solid rgba(153, 153, 153, 0.4);
                //padding: 35px 30px;
                padding: 22px 20px;
                width: fit-content;
                display: flex;
                flex-wrap: wrap;
                user-select: none;
                
                @media (max-width: 576px) {
                    //width: 100%;
                    //justify-content: space-between;
                    
                    //&:after {
                    //    content: "";
                    //    width: 86px;
                    //    height: 70px;
                    //}
                    
                    max-width: 260px;
                    padding: 0;
                }
            }
        }
        
        &.mb6 {
            margin-bottom: 6px;
        }
        
        .facility-icon {
            display: flex;
            flex-direction: column;
            font-size: 13px;
            color: #434343;
            align-items: center;
            margin: 10px 30px;
            //width: 50px;
            
            img {
                width: fit-content;
            }
            
            @media (max-width: 576px) {
                & {
                    margin: 10px 18px;
                } 
            }
        }
    }
    
    .row:last-child {
        margin-bottom: 0;
    }
    
    .info-title {
        font-size: 16px;
        font-weight: 500;
        color: #434343;
        display: flex;
        align-items: center;
    }
    
    .info-text {
        font-size: 16px;
        color: #434343;
        display: flex;
        align-items: center;
        margin-left: 20px;
        
        &.phone {
            font-weight: 500;
        }
        
        &.web {
            font-size: 14px;
        }
    }
    
    .map-wrap {
        width: 100%;
        position: relative;
        margin-top: 38px;
    
        #map {
            width: 100%;
            height: 500px;
        }
    }
    
    @media (max-width: 576px) {
        .map-wrap {
            width: 100%;
            position: relative;
            margin: 30px auto;
        
            #map {
                width: 100%;
                height: 50vw;
            }
        }
    }
    
    @media ${device.small} {
        margin: 10px 0;
        padding: 0;
        
        .row {
            margin-bottom: 10px;
        }
        
        .info-title {
            font-size: 16px;
        }
        
        .info-text {
            font-size: 14px;
            width: 70%;
            
            button {
                font-size: 14px;
                width: 90px;
                height: 35px;
                margin-left: 10px;
            }
        }
    }
    
    table {
        border: #d9d9d9 1px solid;
        background-color: #fff;
    }
    
    th {
        background-color: #ededed;
    }
`;

const GotoHomepageLink = styled.a`
    width: 150px;
    height: 32px;
    background-color: #f79a3d;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    margin-left: 40px;
    
    img {
        margin-bottom: -1px;
    }
    
    @media (max-width: 576px) {
        margin-left: 0;
    }
`;

const StyledPensionContents = styled.div`
    margin: 30px 0;
`;

const StyledBreadcrumb = styled.div`
    @media (max-width: 940px) { 
        padding-top: 60px;
        
        & > div {
            margin: 20px auto;
        }
    }
`;

export default Detail;