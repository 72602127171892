const size = {
    small: '576px',
    medium: '768px',
    large: '992px',
    extraLarge: '1200px'
};

export const device = {
    small: `(max-width: ${size.small})`,
    medium: `(max-width: ${size.medium})`,
    large: `(max-width: ${size.large})`,
    extraLarge: `(max-width: ${size.extraLarge})`
};
