import React, {useEffect} from 'react';
import {Header, Footer, ScrollTop, RecentView, Breadcrumb} from '../../components';
import styled from 'styled-components';
import bg from "../../assets/images/assoc/greetings-bg.jpg";
import '../../assets/css/major.css';
import img1 from '../../assets/images/major/1.png';
import img2 from '../../assets/images/major/2.png';
import img3 from '../../assets/images/major/3.png';
import img4 from '../../assets/images/major/4.png';
import img5 from '../../assets/images/major/5.png';
import img6 from '../../assets/images/major/6.png';
import img7 from '../../assets/images/major/7.png';
import img8 from '../../assets/images/major/8.png';
import img9 from '../../assets/images/major/9.png';
import img10 from '../../assets/images/major/10.png';
import img11 from '../../assets/images/major/11.png';
import img12 from '../../assets/images/major/12.png';

function Major(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const breadcrumb = [{
        id: 1,
        title: '주요사업',
        link: '/major'
    }];

    return (
        <>
            <Header/>

            <StyledContainer>
                <div className="title-wrap">
                    <img src={bg} alt=""/>
                    <div className="title">
                        <span>주요사업</span>
                    </div>
                </div>

                <Breadcrumb data={breadcrumb}/>

                <div className="category">
                    <div></div>
                    <div className="active">주요사업</div>
                    <div></div>
                </div>


                <div className="major">
                    <div className="major-row">
                        <div className="major-col">
                            <div>숙박업(민박, 펜션)의 창달을 위하여 필요한 조사연구 및 지도</div>
                            <img src={img1}/>
                        </div>
                        <div className="major-col">
                            <div>중앙 & 지방 정부의 지시사항 등의 주지 실천</div>
                            <img src={img2}/>
                        </div>
                    </div>
                    <div className="major-row">
                        <div className="major-col">
                            <div>숙박업(민박, 펜션) 업소의 위생수준 향상과 친절서비스 및<br/>
                                시설 개선을 위한 홍보</div>
                            <img src={img3}/>
                        </div>
                        <div className="major-col">
                            <div>무허가(무신고) 업소 등에 대한 단속 협조</div>
                            <img src={img4}/>
                        </div>
                    </div>
                    <div className="major-row">
                        <div className="major-col">
                            <div>농어촌민박, 숙박업에 관련된 관계 법규의 개정 건의</div>
                            <img src={img5}/>
                        </div>
                        <div className="major-col">
                            <div>전국 관광진흥, 숙박업(민박, 펜션) 방문, 연구 및 개발</div>
                            <img src={img6}/>
                        </div>
                    </div>
                    <div className="major-row">
                        <div className="major-col">
                            <div>위생교육 등 관계법령에 의하여 위탁 및 요청받은 업무 및 수행</div>
                            <img src={img7}/>
                        </div>
                        <div className="major-col">
                            <div>영업상 필요한 물자의 공동 구입 및 지원</div>
                            <img src={img8}/>
                        </div>
                    </div>
                    <div className="major-row">
                        <div className="major-col">
                            <div>숙박업(민박, 펜션)의 자율지도검사 및 자율 기도원 교육</div>
                            <img src={img9}/>
                        </div>
                        <div className="major-col">
                            <div>기타 본회 목적 달성에 필요한 사항</div>
                            <img src={img10}/>
                        </div>
                    </div>
                    <div className="major-row">
                        <div className="major-col">
                            <div>협회 발전을 위하여 임직원 등 교육</div>
                            <img src={img11}/>
                        </div>
                        <div className="major-col">
                            <div>사회 공헌활동 사업</div>
                            <img src={img12}/>
                        </div>
                    </div>
                </div>


            </StyledContainer>

            <RecentView/>

            <Footer/>

            <ScrollTop/>
        </>
    );
}

const StyledContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    
    .title-wrap {
        position: relative;
        
        img {
            width: 100%;
        }
        
        .title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 45px;
            font-weight: 200;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
            
            span {
                font-weight: 600;
            }
        }        
    }
    
    .category {
        max-width: 1050px;
        display: flex;
        margin: 110px auto 30px;
        
        div {
            width: 100%;
            max-width: 440px;
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            color: #434343;
            border-bottom: 2px solid #dcdcdc;
            
            &.active {
                max-width: 170px;
                border-bottom: 2px solid #f68b1f;
                padding-bottom: 12px;
            }
        }
    }
    
    @media (max-width: 940px) {
        margin: 60px auto 0;
    }
    
    @media (max-width: 475px) {
    
        .title-wrap {
            img {
                height: 300px;
                object-fit: cover;
            }
            
            .title {
                font-size: 29px;
                text-align: center;
                width: 85%;
            }
        }
        
        .category {
            margin-top: 45px;
            
            div {
                font-size: 19px;
            }
        }
    }
`;

const StyledCategoryContainer = styled.div`
    margin: 100px auto 100px;
    // max-width: 643px;
    max-width: 800px;
    padding: 0 20px;
    text-align: justify;
    
    .mobile-hide {
        display: none;
    }
    
    h2 {
        font-size: 30px;
    }
    
    h3 {
        font-size: 21px;
    }
    
    div {
        line-height: 24px;
        font-size: 16px;
    }
    
    @media (max-width: 660px) {
        margin: 60px auto 60px;
    
        .mobile-hide {
            display: none;
        }
    
        h2 {
            font-size: 25px;
        }
        
        h3 {
            font-size: 17px;
        }
    
        div {
            margin: 0;
            font-size: 14px;
        }
    }
`;

export default Major;