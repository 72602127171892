import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import banner1 from '../../assets/images/main/banner/nature.jpg';
import banner1Hover from '../../assets/images/main/banner/nature_hover.jpg';
import banner2 from '../../assets/images/main/banner/culture.jpg';
import banner2Hover from '../../assets/images/main/banner/culture_hover.jpg';
import banner3 from '../../assets/images/main/banner/leisure.jpg';
import banner3Hover from '../../assets/images/main/banner/leisure_hover.jpg';
import banner4 from '../../assets/images/main/banner/festival.jpg';
import banner4Hover from '../../assets/images/main/banner/festival_hover.jpg';
import {Link} from 'react-router-dom';

function MainBanner(props) {
    const [banner1State, setBanner1State] = useState(true);
    const [banner2State, setBanner2State] = useState(false);
    const [banner3State, setBanner3State] = useState(false);
    const [banner4State, setBanner4State] = useState(false);

    function handleMouseEnter(page) {
        switch (page) {
            case 'nature':
                setBanner1State(true);
                setBanner2State(false);
                setBanner3State(false);
                setBanner4State(false);
                break;
            case 'culture':
                setBanner1State(false);
                setBanner2State(true);
                setBanner3State(false);
                setBanner4State(false);
                break;
            case 'leisure':
                setBanner1State(false);
                setBanner2State(false);
                setBanner3State(true);
                setBanner4State(false);
                break;
            case 'festival':
                setBanner1State(false);
                setBanner2State(false);
                setBanner3State(false);
                setBanner4State(true);
                break;
        }

        setCount(0);
    }

    function handleMouseLeave(page) {
        switch (page) {
            case 'nature':
                setBanner1State(false);
                setBanner2State(false);
                setBanner3State(false);
                setBanner4State(false);

                setCount(2);
                break;
            case 'culture':
                setBanner1State(false);
                setBanner2State(false);
                setBanner3State(false);
                setBanner4State(false);

                setCount(3);
                break;
            case 'leisure':
                setBanner1State(false);
                setBanner2State(false);
                setBanner3State(false);
                setBanner4State(false);

                setCount(4);
                break;
            case 'festival':
                setBanner1State(false);
                setBanner2State(false);
                setBanner3State(false);
                setBanner4State(false);

                setCount(1);
                break;
        }
    }

    // count 값이 0이면 멈춤
    const [count, setCount] = useState(1);

    useEffect(() => {

        const bannerChange = setInterval(() => {
            if (count === 1) {
                setBanner1State(true);
                setBanner2State(false);
                setBanner3State(false);
                setBanner4State(false);

                setCount(count + 1);
            } else if (count === 2) {
                setBanner1State(false);
                setBanner2State(true);
                setBanner3State(false);
                setBanner4State(false);

                setCount(count + 1);
            } else if (count === 3) {
                setBanner1State(false);
                setBanner2State(false);
                setBanner3State(true);
                setBanner4State(false);

                setCount(count + 1);
            } else if (count === 4) {
                setBanner1State(false);
                setBanner2State(false);
                setBanner3State(false);
                setBanner4State(true);

                // 카운트 초기화
                setCount(1);
            }
        }, 1500);
        return () => clearInterval(bannerChange);
    }, [count]);

    return (
        <StyledContainer>
            <div className="box">
                <Link to="/tourlist/자연관광"
                    onMouseEnter={() => handleMouseEnter('nature')}
                    onMouseLeave={() => handleMouseLeave('nature')}
                >
                    <img src={banner1State ? banner1Hover : banner1} alt="" />
                    <div className="title">
                        자연관광
                    </div>
                </Link>
            </div>
            <div className="box">
                <Link to="/tourlist/문화관광"
                    onMouseEnter={() => handleMouseEnter('culture')}
                    onMouseLeave={() => handleMouseLeave('culture')}
                >
                    <img src={banner2State ? banner2Hover : banner2} alt="" />
                    <div className="title">
                        문화관광
                    </div>
                </Link>
            </div>
            <div className="box">
                <Link to="/tourlist/레저관광"
                    onMouseEnter={() => handleMouseEnter('leisure')}
                    onMouseLeave={() => handleMouseLeave('leisure')}
                >
                    <img src={banner3State ? banner3Hover : banner3} alt="" />
                    <div className="title">
                        레저관광
                    </div>
                </Link>
            </div>
            <div className="box">
                <Link to="/tourlist/축제"
                    onMouseEnter={() => handleMouseEnter('festival')}
                    onMouseLeave={() => handleMouseLeave('festival')}
                >
                    <img src={banner4State ? banner4Hover : banner4} alt="" />
                    <div className="title">
                        축제
                    </div>
                </Link>
            </div>
        </StyledContainer>
    );
}

const StyledContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    
    .box {
        position: relative;
        width: 50%;
        overflow: hidden;
        
        a {
            display: block;
            height: 100%;
        }
        
        .title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 200px;
            text-align: center;
            
            font-size: 28px;
            color: #fff;
            font-weight: 500;
            
            border-bottom: 1px solid rgba(255, 255, 255, 0.7);
            
            padding-bottom: 7px;
            
            transition: 0.5s;
        }
        
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 0.6s;
        }
        
        &:hover {
            img {
                transform: scale(1.1);
            }
        }
    }
    
    @media (max-width: 576px) {
        & {
            flex-direction: column;
        }
        
        .box {
            width: 100%;
            
            a {
                display: flex;
            }
            
            .title {
                font-size: 20px;
                width: 150px;
            }
        }
    }
`;

export default MainBanner;