import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Slider from 'react-slick';

const Carousel = props => {
    return (
        <StyledSlider {...props.settings}>
            {
                props.data.map((item, index) => {
                    if (item.type === 'image') {
                        return (
                            <div className="slide-wrap" key={index}>
                                <img src={item.imgSrc} alt={item.imgAlt} />
                                <div className="slide-title" dangerouslySetInnerHTML={{__html: item.title}}></div>
                            </div>
                        )
                    } else if (item.type === 'video') {
                        return (
                            <div className="slide-wrap" key={index}>
                                <video autoPlay muted playsInline>
                                    <source src={item.videoSrc} type="video/mp4" />
                                </video>
                            </div>
                        )
                    } else {
                        return (
                            <div className="slide-wrap" key={index}>
                                <img src={item.imgSrc} alt={item.imgAlt} />
                                <div className="slide-title" dangerouslySetInnerHTML={{__html: item.title}}></div>
                            </div>
                        )
                    }
                })
            }
        </StyledSlider>
    );
};

Carousel.propTypes = {
    data: PropTypes.array,
    settings: PropTypes.object
};

const StyledSlider = styled(Slider)`
    height: 100%;
    
    .slick-list, .slick-track {
        height: 100%;
    }

    .slick-initialized { 
        overflow: hidden;
    }
    
    .slick-dots {
        width: auto;
        left: 50%;
        bottom: 135px;
        transform: translateX(-50%);
    }
    
    .slick-dots li.slick-active button:before {
        opacity: 1;
        color: #fff;
    }
    
    .slick-dots li {
        width: 12px;
        height: 12px;
        margin: 0 9px;
    }
    
    .slick-dots li button {
        width: 12px;
        height: 12px;
        padding: 0;
    }
    
    .slick-dots li button:before {
        width: 12px;
        height: 12px;
        font-size: 12px;
        line-height: 12px;
        opacity: 0.6;
        color: #fff;
    }
    
    .slick-slide > div {
        height: 100%;
    }

    .slick-slide img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .slick-slide video {
        background-color: #fff;
        width: 100%;
        height: 100%;
    }
    
    .slide-wrap {
        position: relative;
        height: 100%;
    }
    
    .slide-title {
        position: absolute;
        top: 215px;
        left: 50%;
        transform: translateX(-50%);
        color: #fff;
        font-size: 45px;
        font-weight: 200;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
        
        span {
            font-weight: 600;
        }
    }
    
    @media (max-width: 850px) {
        .slick-dots {
            bottom: 165px;
        }
    }
    
    @media (max-width: 1250px) {
        .slide-title {
            top: 225px;
            font-size: 35px;
        }
    }
    
    @media (max-width: 940px) {
        .slide-title {
            top: 229px;
            font-size: 28px;
        }
    }
    
    @media (max-width: 767px) {
        .slide-title {
            top: 234px;
            font-size: 22px;
        }
    }
    
    @media (max-width: 576px) {
        .slick-dots {
            bottom: 120px;
            width: 100%;
        }
        
        .slick-dots li button:before {
            font-size: 10px;   
        }
    }
`;

export default Carousel;
