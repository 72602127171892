import React from 'react';
import {Route, BrowserRouter as Router, Switch} from 'react-router-dom';
import {
    Main,
    Stay,
    Tour,
    Restaurant,
    List,
    Detail,
    Login,
    Join,
    Forgot,
    Notice,
    Faq,
    Qna,
    CompanyManagement,
    Partnership,
    Pension,
    Farm,
    Search,
    EmailSecurity,
    Policy,
    Mountain,
    Course,
    Map,
    Strawberry,
    TourList,
    TourDetail,
    TourDetail2,
    Greetings,
    OrgInfo,
    Major,
    Partners,
    Articles,
    BoardList,
    BoardPhoto,
    MyPage,
    BoardView,
    PhotoView,
    BoardWrite
} from '../pages/index';
import {createGlobalStyle} from 'styled-components';
import {normalize} from 'styled-normalize';
import "pretendard/dist/web/static/pretendard.css";

const GlobalStyle = createGlobalStyle`
    ${normalize}

    * {
        font-family: "Pretendard" !important;
        box-sizing: border-box;
    }
    
    body {
        min-width: 360px;
        overflow-x: hidden !important;
        
        @media (max-width: 500px) {
            overflow-x: auto !important;
        }
    }
    
    object {
        pointer-events: none;
    }
    
    .slick-slide * {
        outline: none
    }
    
    a {
        color: inherit;
        text-decoration: none;
    }
`;

const App = () => {

    return (
        <>
            <GlobalStyle/>
            <Router>
                <Route exact path="/" component={Main}/>
                <Route exact path="/tour" component={Tour}/>
                <Route exact path="/pension" component={Pension}/>
                <Route path="/pension/:type" component={Pension}/>
                <Route path="/farm" component={Farm}/>
                <Route exact path="/search" component={Search}/>
                <Route path="/search/:address" component={Search}/>
                <Route path="/email" component={EmailSecurity}/>
                <Route path="/policy" component={Policy}/>
                <Route path="/stay" component={Stay}/>
                <Route path="/restaurant" component={Restaurant}/>
                <Route path="/list" component={List}/>
                <Route path="/detail/:id" component={Detail}/>
                <Route path="/login" component={Login}/>
                <Route path="/join" component={Join}/>
                <Route path="/mypage" component={MyPage}/>
                <Route path="/forgot" component={Forgot}/>
                <Route path="/notice" component={Notice}/>
                <Route path="/faq" component={Faq}/>
                <Route path="/qna" component={Qna}/>
                <Route path="/companymanagement" component={CompanyManagement}/>
                <Route path="/partnership" component={Partnership}/>
                <Route path="/mountain" component={Mountain}/>
                <Route path="/course" component={Course}/>
                <Route path="/map" component={Map}/>
                <Route path="/strawberry" component={Strawberry}/>
                <Route path="/tourlist/:type" component={TourList}/>
                <Route path="/tourlist2/:type" component={TourList}/>
                <Route path="/tourdetail/:type/:id" component={TourDetail}/>
                <Route path="/tourdetail2/:type/:id" component={TourDetail2}/>
                <Route path="/greetings" component={Greetings}/>
                <Route path="/orginfo" component={OrgInfo}/>
                <Route path="/major" component={Major}/>
                <Route path="/partners" component={Partners}/>
                <Route path="/articles" component={Articles}/>
                <Route exact path="/photo/:type" component={BoardPhoto}/>
                <Route exact path="/photo/:type/:id" component={PhotoView}/>
                <Route exact path="/board/:type" component={BoardList}/>
                <Route exact path="/board/:type/:id" component={BoardView}/>
                <Route exact path="/boardwrite/:type" component={BoardWrite}/>
            </Router>
        </>
    );
}

export default App;
