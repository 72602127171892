import React from 'react';
import PropTypes from 'prop-types';
import {FormattedInput} from '@buttercup/react-formatted-input';
import styled from 'styled-components';

InputBusinessNumber.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func
};

function InputBusinessNumber(props) {
    const businessNumberPattern = [
        {char: /\d/, repeat: 3},
        {exactly: "-"},
        {char: /\d/, repeat: 2},
        {exactly: "-"},
        {char: /\d/, repeat: 5}
    ];

    return (
        <StyledFormattedInput
            className="formatted-input"
            format={businessNumberPattern}
            placeholder="___-__-_____"
            value={props.value}
            onChange={props.onChange}
        />
    );
}

const StyledFormattedInput = styled(FormattedInput)`
    width: 50%;
    font-size: 18px;
    color: #000;
    border: none;
    outline: none;
    
    &::placeholder {
        color: #aaaaaa;
    }
`;

export default InputBusinessNumber;
