import React, {useState, useEffect} from 'react';
import {Header, Footer, ScrollTop, RecentView, Breadcrumb} from '../../components';
import styled from "styled-components";
import bg from "../../assets/images/tour/bg.jpg";
import {callApi} from '../../api';
import {Link, useParams} from 'react-router-dom';
import festivalData from '../Tour/festivalData.json';
import showData from '../Tour/showData.json';
import breadcrumbData from './breadcrumbData.json';
import natureData from '../../dummy/tourNature.json';
import cultureTourData from './cultureTourData.json';

const natureTourData = [
    {
        id: 1,
        title: '자연',
        img_url: '/uploads/tour/nature.jpg',
    },
    {
        id: 2,
        title: '수목원',
        img_url: '/uploads/tour/aboretum.jpg',
    },
    {
        id: 3,
        title: '공원',
        img_url: '/uploads/tour/park.jpg',
    }
];

// const cultureTourData = [
//     {
//         id: 1,
//         title: '볼거리',
//         img_url: '/uploads/tour/see.jpg',
//     },
//     {
//         id: 2,
//         title: '사찰',
//         img_url: '/uploads/tour/temple.jpg',
//     },
//     // {
//     //     id: 3,
//     //     title: '문화재',
//     //     img_url: '/uploads/tour/culturalassets.jpg',
//     // },
//     {
//         id: 4,
//         title: '장터',
//         img_url: '/uploads/tour/market.jpg',
//     }
// ];



function TourList(props) {
    let {type} = useParams();

    const [tourList, setTourList] = useState([]);
    const paramType = type;

    const [breadcrumb, setBreadcrumb] = useState(breadcrumbData[type]);

    useEffect(() => {
        window.scrollTo(0, 0);

        if (type === '자연관광') {
            setTourList(natureData);
        } else if (type === '문화관광') {
            setTourList(cultureTourData);
        } else if (type === '축제') {
            setTourList(festivalData);
        } else if (type === '공연') {
            setTourList(showData);
        } else {
            let uri = '/namyangju?type=' + encodeURIComponent(type);
            callApi(uri, 'GET', {}).then((res) => {
                if (res)
                    setTourList(res.list);
            }).catch((err) => {
                console.log(err);
            });
        }
    }, [type]);

    return (
        <>
            <Header />

            <StyledContainer>
                <div className="title-wrap">
                    <img src={bg} alt="" />
                    <div className="title">
                        <span>낭만</span>과 <span>힐링</span>이있는<br /> <span>남양주</span>로 떠나볼까요?
                    </div>
                </div>

                <Breadcrumb data={breadcrumb} />

                <div className="category">
                    <div></div>
                    <div className="active">{type}</div>
                    <div></div>
                </div>

                <StyledImageWrap>
                    {
                        tourList.map((item, index) => {
                            if (paramType === '자연관광') {
                                let link = '/tourdetail/자연관광/' + item.id;

                                return (
                                    <div className="box" key={item.id}>
                                        <Link to={link} replace>
                                            <img src={item.img_url} alt={item.title} />
                                            <div>
                                                {item.title}
                                            </div>
                                        </Link>
                                    </div>
                                )
                            } else if (paramType === '문화관광') {
                                // let link = '/tourlist2/' + item.title;
                                let link = '/tourdetail/문화관광/' + item.id;

                                return (
                                    <div className="box" key={item.id}>
                                        <Link to={link} replace>
                                            <img src={item.img_url} alt={item.title} />
                                            <div>
                                                {item.title}
                                            </div>
                                        </Link>
                                    </div>
                                )
                            } else if (paramType === '축제') {
                                let link = '/tourdetail2/festival/' + item.id;

                                return (
                                    <div className="box" key={item.id}>
                                        <Link to={link}>
                                            <img src={item.img_url} alt={item.title} />
                                            <div>
                                                {item.title}
                                            </div>
                                        </Link>
                                    </div>
                                )
                            } else if (paramType === '공연') {
                                let link = '/tourdetail2/show/' + item.id;

                                return (
                                    <div className="box" key={item.id}>
                                        <Link to={link}>
                                            <img src={item.img_url} alt={item.title} />
                                            <div>
                                                {item.title}
                                            </div>
                                        </Link>
                                    </div>
                                )
                            } else if (paramType === '레저관광') {
                                if (item.id === 16 || item.id === 62 || item.id === 37) {
                                    return null;
                                }

                                let link = '/tourdetail/' + type + '/' + item.id;

                                return (
                                    <div className="box" key={item.id}>
                                        <Link to={link}>
                                            <img src={item.img_url} alt={item.title} />
                                            <div>
                                                {item.title}
                                            </div>
                                        </Link>
                                    </div>
                                )
                            } else {
                                let link = '/tourdetail/' + type + '/' + item.id;

                                return (
                                    <div className="box" key={item.id}>
                                        <Link to={link}>
                                            <img src={item.img_url} alt={item.title} />
                                            <div>
                                                {item.title}
                                            </div>
                                        </Link>
                                    </div>
                                )
                            }
                        })
                    }
                </StyledImageWrap>
            </StyledContainer>

            <RecentView />

            <Footer />

            <ScrollTop />
        </>
    );
}

const StyledContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    
    br {
        display: none;
    }
    
    .title-wrap {
        position: relative;
        
        img {
            width: 100%;
        }
        
        .title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 45px;
            font-weight: 200;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
            
            span {
                font-weight: 600;
            }
        }        
    }
    
    .category {
        max-width: 1050px;
        display: flex;
        margin: 110px auto 30px;
        
        div {
            width: 100%;
            max-width: 440px;
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            color: #434343;
            border-bottom: 2px solid #dcdcdc;
            
            &.active {
                width: 100%;
                max-width: 170px;
                border-bottom: 2px solid #f68b1f;
                padding-bottom: 12px;
            }
        }
    }
    
    @media (max-width: 940px) {
        margin: 60px auto 0;
    }
    
    @media (max-width: 475px) {
        br {
            display: block;
        }
    
        .title-wrap {
            img {
                height: 300px;
                object-fit: cover;
            }
            
            .title {
                font-size: 29px;
                text-align: center;
                width: 74%;
            }
        }
        
        .category {
            margin-top: 45px;
            
            div {
                font-size: 19px;
            }
        }
    }
`;

const StyledImageWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto 140px;
    justify-content: center;
    
    .box {
        margin: 10px;
        width: 280px;
        height: 280px;
        
        a {
            display: block;
            position: relative;
            overflow: hidden;
            width: 280px;
            height: 280px;
            
            &:hover img {
                transform: scale(1.2);
            }
            
            div {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 20px;
                font-weight: 500;
                color: #fff;
                border-bottom: 1px solid rgba(255, 255, 255, 0.5);
                width: max-content;
                padding-bottom: 8px;
                text-align: center;
            }
            
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: transform 0.3s;
            }
        }
    }
    
    @media (max-width: 1220px) {
        max-width: 900px;
    }
    
    @media (max-width: 920px) {
        max-width: 600px;
    }
    
    @media (max-width: 620px) {
        max-width: 300px;
        margin: 0 auto 50px;
    }
`;

export default TourList;