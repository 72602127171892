import React, {useEffect} from 'react';
import {Header, Footer, ScrollTop, RecentView, SliderBox, Breadcrumb} from '../components';
import styled from 'styled-components';
import mountainBg from '../assets/images/mountain/bg2.jpg';
import mountain1img from '../assets/images/mountain/갑산금대산.jpg';
import mountain2img from '../assets/images/mountain/고래산.jpg';
import mountain3img from '../assets/images/mountain/관음봉.jpg';
import mountain4img from '../assets/images/mountain/국사봉.jpg';
import mountain5img from '../assets/images/mountain/금남산.jpg';
import mountain6img from '../assets/images/mountain/도정산.jpg';
import mountain7img from '../assets/images/mountain/문안산.jpg';
import mountain8img from '../assets/images/mountain/백봉산양골산.jpg';
import mountain9img from '../assets/images/mountain/불암산.jpg';
import mountain10img from '../assets/images/mountain/송라산.jpg';
import mountain11img from '../assets/images/mountain/수락산.jpg';
import mountain12img from '../assets/images/mountain/수리봉.jpg';
import mountain13img from '../assets/images/mountain/예봉산운길산.jpg';
import mountain14img from '../assets/images/mountain/주금산.jpg';
import mountain15img from '../assets/images/mountain/천마산.jpg';
import mountain16img from '../assets/images/mountain/철마산.jpg';
import mountain17img from '../assets/images/mountain/축령산.jpg';
import mountain18img from '../assets/images/mountain/퇴뫼산곱돌산.jpg';
import mountain19img from '../assets/images/mountain/황금산.jpg';

const mountainData = [
    {
        id: 1,
        title: "갑산.금대산",
        desc: "",
        images: [mountain1img],
        link: mountain1img,
    },
    {
        id: 2,
        title: "고래산",
        desc: "",
        images: [mountain2img],
        link: mountain2img,
    },
    {
        id: 3,
        title: "관음봉",
        desc: "",
        images: [mountain3img],
        link: mountain3img,
    },
    {
        id: 4,
        title: "국사봉",
        desc: "",
        images: [mountain4img],
        link: mountain4img,
    },
    {
        id: 5,
        title: "금남산",
        desc: "",
        images: [mountain5img],
        link: mountain5img,
    },
    {
        id: 6,
        title: "도정산",
        desc: "",
        images: [mountain6img],
        link: mountain6img,
    },
    {
        id: 7,
        title: "문안산",
        desc: "",
        images: [mountain7img],
        link: mountain7img,
    },
    {
        id: 8,
        title: "백봉산.양골산",
        desc: "",
        images: [mountain8img],
        link: mountain8img,
    },
    {
        id: 9,
        title: "불암산",
        desc: "",
        images: [mountain9img],
        link: mountain9img,
    },
    {
        id: 10,
        title: "송라산",
        desc: "",
        images: [mountain10img],
        link: mountain10img,
    },
    {
        id: 11,
        title: "수락산",
        desc: "",
        images: [mountain11img],
        link: mountain11img,
    },
    {
        id: 12,
        title: "수리봉",
        desc: "",
        images: [mountain12img],
        link: mountain12img,
    },
    {
        id: 13,
        title: "예봉산.운길산",
        desc: "",
        images: [mountain13img],
        link: mountain13img,
    },
    {
        id: 14,
        title: "주금산",
        desc: "",
        images: [mountain14img],
        link: mountain14img,
    },
    {
        id: 15,
        title: "천마산",
        desc: "",
        images: [mountain15img],
        link: mountain15img,
    },
    {
        id: 16,
        title: "철마산",
        desc: "",
        images: [mountain16img],
        link: mountain16img,
    },
    {
        id: 17,
        title: "축령산",
        desc: "",
        images: [mountain17img],
        link: mountain17img,
    },
    {
        id: 18,
        title: "퇴뫼산.곱돌산",
        desc: "",
        images: [mountain18img],
        link: mountain18img,
    },
    {
        id: 19,
        title: "황금산",
        desc: "",
        images: [mountain19img],
        link: mountain19img,
    }
];

function Mountain(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const mountainList = mountainData.map((item, index) => {
        return (
            <SliderBox
                type="mountain"
                key={item.id}
                id={item.id}
                link={item.link}
                desc={item.desc}
                title={item.title}
                images={item.images}
            />
        )
    });

    const breadcrumb = [{
        id: 1,
        title: '등산안내',
        link: '/mountain'
    }];

    return (
        <>
           <Header />

           <StyledContainer>
               <div className="title-wrap">
                   <img src={mountainBg} alt="" />
                   <div className="title">
                       <span>남양주</span>의 <br /> 아름다운 <span>산</span>
                   </div>
               </div>

               <Breadcrumb data={breadcrumb} />

               <div className="category">
                   <div></div>
                   <div className="active">등산안내</div>
                   <div></div>
               </div>

               <StyledCategoryContainer>
                   {mountainList}
               </StyledCategoryContainer>
           </StyledContainer>


            <RecentView />

           <Footer />

           <ScrollTop />
        </>
    );
}

const StyledContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    
    br {
        display: none;
    }
    
    .title-wrap {
        position: relative;
        
        img {
            width: 100%;
        }
        
        .title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 45px;
            font-weight: 200;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
            
            span {
                font-weight: 600;
            }
        }        
    }
    
    .category {
        display: flex;
        justify-content: center;
        margin-top: 110px;
        margin-bottom: 30px;
        
        div {
            flex:1;
            max-width: 440px;
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            color: #434343;
            border-bottom: 2px solid #dcdcdc;
            
            &.active {
                max-width: 170px;
                border-bottom: 2px solid #f68b1f;
                padding-bottom: 12px;
            }
        }
    }
    
    @media (max-width: 940px) {
        margin: 60px auto 0;
    }
    
    @media (max-width: 475px) {
        br {
            display: block;
        }
    
        .title-wrap {
            img {
                height: 300px;
                object-fit: cover;
            }
            
            .title {
                font-size: 29px;
                text-align: center;
                width: 85%;
            }
        }
        
        .category {
            margin-top: 45px;
            
            div {
                font-size: 19px;
            }
        }
    }
`;

const StyledCategoryContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 30px auto 93px;
    max-width: 1440px;
    
    @media (max-width: 1460px) {
        max-width: 1080px;
    }
    
    @media (max-width: 1100px) {
        max-width: 720px;
    }
    
    @media (max-width: 740px) {
        max-width: 360px;
        margin: 0 auto 50px;
    }
`;

export default Mountain;