import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import '../assets/css/map.css';
import styled from 'styled-components';

function Map(props) {
    const [kakaoMap, setKakaoMap] = useState();

    useEffect(() => {
        let mapContainer = document.getElementById('map'),
            mapOption = {
                center: new window.kakao.maps.LatLng(props.lat, props.lng),
                level: props.level
            };

        let map = new window.kakao.maps.Map(mapContainer, mapOption);
        setKakaoMap(map);


        if (props.isMarker) {
            let marker = new window.kakao.maps.Marker({
                map: map,
                position: new window.kakao.maps.LatLng(props.lat, props.lng),
                title: props.title
            });

            let content = '<div class="customoverlay">' +
                '   <a href="javascript:void(0);">' +
                '       <span class="title">' + props.title + '</span>' +
                '   </a>' +
                '</div>';

            let overlay = new window.kakao.maps.CustomOverlay({
                content: content,
                map: map,
                position: marker.getPosition()
            });

            overlay.setMap(map);
        }

    }, [props]);

    function zoomIn() {
        kakaoMap.setLevel(kakaoMap.getLevel() - 1);
    }

    function zoomOut() {
        kakaoMap.setLevel(kakaoMap.getLevel() + 1);
    }

    return (
        <StyledContainer>
            <div id="map"></div>
            <div className="custom_zoomcontrol radius_border">
                <span onClick={zoomIn}><img src="https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/ico_plus.png" alt="확대" /></span>
                <span onClick={zoomOut}><img src="https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/ico_minus.png" alt="축소" /></span>
            </div>
        </StyledContainer>
    );
}

Map.propTypes = {
    lat: PropTypes.number,
    lng: PropTypes.number,
    level: PropTypes.number,
    title: PropTypes.string,
    isMarker: PropTypes.bool
};

Map.defaultProps = {
    level: 3,
    isMarker: true
};

const StyledContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto 30px;
    max-width: 1440px;
    position: relative;
    
    #map {
        width: 100%;
        height: 500px;
    }
    
    @media (max-width: 1100px) {
        width: 60%;
        
        #map {
            height: 50vw;
        }
    }
    
    @media (max-width: 740px) {
        width: 60%;
        margin: 0 auto 50px;
    }
`;

export default Map;