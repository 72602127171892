import React, {useState, useLayoutEffect} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {device} from '../config/device';

const Pagination = (props) => {
    // 전체페이지
    const totalPage = props.totalPage;

    // 현재페이지
    const [currentPage, setCurrentPage] = useState(1);
    const handleChangeCurrentPage = (e) => {
        const inputPage = (e.target.validity.valid) ? e.target.value : currentPage;
        if (inputPage === '') {
            setCurrentPage(0);
        } else {
            setCurrentPage(parseInt(inputPage));
        }
    };

    // 이전 페이지로 이동
    const handleClickPrevArrow = (e) => {
        const temp = currentPage - 1;
        if (currentPage === 1 || currentPage === 0 || currentPage > totalPage) {
            e.preventDefault();
            // alert('가장 최신 페이지 입니다.');
        } else if (temp < 1 || temp > totalPage) {
            alert('유효하지 않는 페이지 입니다.');
        } else {
            setCurrentPage(temp);
        }
    };

    // 다음 페이지로 이동
    const handleClickNextArrow = (e) => {
        const temp = currentPage + 1;
        if (currentPage === totalPage || currentPage > totalPage) {
            e.preventDefault();
            // alert('마지막 페이지 입니다.');
        } else if (temp < 1 || temp > totalPage) {
            alert('유효하지 않는 페이지 입니다.(b)');
        } else {
            setCurrentPage(temp);
        }
    };

    // 페이지 입력 후 Enter Key
    const handlePressCurrentPage = (e) => {
        if (e.key === "Enter") {
            console.log('enter!!');

            // 전체페이지보다 큰 페이지 입력 시 실행
            if (currentPage > totalPage) {
                alert('page is big!');
            } else {
                console.log('Move page~')
            }
        }
    };

    // 이전 페이지 화살표 색상
    const [prevArrowColor, setPrevArrowColor] = useState("rgb(62, 71, 92)");

    // 다음 페이지 화살표 색상
    const [nextArrowColor, setNextArrowColor] = useState("rgb(62, 71, 92)");
    const [isNextPage, setIsNextPage] = useState(false);

    // 이전, 다음 페이지 이동 화살표 색상 변경
    useLayoutEffect(() => {
        if (totalPage === 1 && currentPage === totalPage) {
            setIsNextPage(false);

            setPrevArrowColor("#aaa");
            setNextArrowColor("#aaa");
        } else if (currentPage > totalPage) {
            setIsNextPage(false);

            setPrevArrowColor("#aaa");
            setNextArrowColor("#aaa");
        } else if (totalPage > 1 && currentPage === totalPage) {
            setIsNextPage(false);

            setPrevArrowColor("rgb(62, 71, 92)");
            setNextArrowColor("#aaa");
        } else if (currentPage === 1 || currentPage === 0) {
            setIsNextPage(true);

            setPrevArrowColor("#aaa");
            setNextArrowColor("rgb(62, 71, 92)");
        } else {
            setIsNextPage(true);

            setPrevArrowColor("rgb(62, 71, 92)");
            setNextArrowColor("rgb(62, 71, 92)");
        }
    }, [currentPage, totalPage]);

    return (
        <PageContainer isNextPage={isNextPage} >
            <button className="next-btn" onClick={handleClickNextArrow}>다음페이지</button>
            <div className="arrow-btn" onClick={handleClickPrevArrow}>
                <svg
                    width="11px" height="17px">
                    <path fillRule="evenodd" fill={prevArrowColor}
                          d="M10.164,14.925 L8.750,16.340 L0.972,8.562 L1.290,8.244 L0.972,7.925 L8.750,0.147 L10.164,1.562 L3.482,8.244 L10.164,14.925 Z"/>
                </svg>
            </div>

            <input
                type="text"
                pattern="[0-9]*"
                className="current-page"
                onInput={handleChangeCurrentPage}
                onKeyPress={handlePressCurrentPage}
                value={currentPage}
            />

            <div className="total-page">
                / {totalPage}
            </div>
            <div className="arrow-btn" onClick={handleClickNextArrow}>
                <svg
                    width="11px" height="17px">
                    <path fillRule="evenodd" fill={nextArrowColor}
                          d="M10.152,8.561 L2.374,16.339 L0.960,14.925 L7.642,8.243 L0.960,1.561 L2.374,0.147 L10.152,7.925 L9.835,8.243 L10.152,8.561 Z"/>
                </svg>
            </div>
        </PageContainer>
    );
}

const PageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    
    .next-btn {
        width: 104px;
        height: 60px;
        border: 1px solid #dfdfdf;
        background: #fff;
        color: ${props => props.isNextPage ? '#000' : '#aaa'};
        outline: none;
        cursor: pointer;
        box-shadow: 2px 3px 7px 0px rgba(0, 0, 0, 0.15);
        font-size: 18px;
    }
    
    .arrow-btn {
        width: 60px;
        height: 60px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        
        position: relative;
        overflow: hidden;
    }
    
    .current-page {
        width: 60px;
        height: 60px;
        border: 1px solid #dfdfdf;
        background: #fff;
        font-size: 18px;
        margin-right: 10px;
        outline: none;
        text-align: center;
    }
    
    .total-page {
        font-size: 18px;
        margin-top: 3px;
        user-select: none;
    }
    
    @media ${device.small} {
        .next-btn {
            width: 100px;
            height: 50px;
            font-size: 14px;
        }
        
        .arrow-btn {
            width: 40px;
            height: 50px;
        }
        
        .current-page {
            width: 50px;
            height: 50px;        
        }
    }
`;

Pagination.propTypes = {
    totalPage: PropTypes.number
};

Pagination.defaultProps = {
    totalPage: 12
};

export default Pagination;