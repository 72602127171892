import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {callApi} from "../../api";
import Cookies from 'universal-cookie';
import {useCookies} from "react-cookie";

const Popup = (props) => {

    const HUNDRED_DAY = 86400;

    const [popupList, setPopupList] = useState([]);
    const [box, setBox] = useState([]);
    const cookie = new Cookies();
    const [cookies, setCookie, removeCookie] = useCookies();

    useEffect(() => {
        callApi('/popup/list', 'GET').then((res) => {
            if (res.success) {
                const popupBox = res.list.map(list => {
                    return {popupId: list.id, open: true}
                });
                setBox(popupBox);
                setPopupList(res.list);

            }
        }).catch((e) => {
            console.log(e);
        });
    }, []);

    const rememberPopup = (id) => {
        setCookie('popup'+id, true, {maxAge: HUNDRED_DAY});
    }

    if (popupList.length < 1)
        return false;

    return (
        <PopupContainer>
            <div className="popup-inner">
                {popupList.map(list => {
                    const popupCookie = cookie.get('popup'+list.id);

                    const popupBox = box.filter((b) => b.popupId === list.id);
                    if(popupCookie !== undefined) {
                        return false;
                    }

                    return (
                        <div key={list.id} className={"popup" + (popupBox[0].open ? "" : " hide")}>
                            <a href={list.link === "" ? false : list.link} target="_blank">
                                <img src={list.image} width={list.width}/>
                            </a>
                            <div className="popup-footer">
                                <button onClick={()=>rememberPopup(list.id)}>오늘 하루 닫기</button>
                                <button onClick={() => {
                                    const newBox = box.map((b) => {
                                        return b.popupId === list.id ? {popupId: b.popupId, open: false} : b;
                                    });
                                    setBox(newBox);
                                }}>
                                    닫기
                                </button>
                            </div>
                        </div>
                    )
                })}
            </div>
        </PopupContainer>
    );
}

const PopupContainer = styled.div`
    position: absolute;
    display: flex;
    z-index: 5;
    // width: 100%;
    justify-content: center;
    
    & .popup-inner {
        margin: 50px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        // width: 1400px;
        // min-width: 400px;
        
        & .popup {
            background-color:#FFF;
            margin-right:10px;
            margin-bottom:40px;
            // min-width:360px;
            // padding:25px;
            
            & a {
                border:1px solid #F0F0F0;
                display:flex;
                width:100%;
            }
            
            & .popup-footer {
                position:relative;
                display:flex;
                // margin: 25px -25px -25px -25px;
                
                & button {
                    border:1px solid #F0F0F0;
                    border-radius:0;
                    background-color:#FFF;
                    padding:5px 0 5px 0;
                    width: 50%;
                }
            }
        }
        
        .hide {
            display:none;
        }
    }
`;

export default Popup;