import React, {useState, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import {device} from '../../config/device';
import {Carousel} from '../../components';
import mainCarouselData from '../../dummy/mainCarouselData.json';
import prevIcon from '../../assets/images/main/main-slide-prev-icon.png';
import nextIcon from '../../assets/images/main/main-slide-next-icon.png';
import {useHistory} from "react-router-dom";
import {InputBox, InputText} from "../../components/inputs";
import {InputBase} from '@material-ui/core';
import Popup from "../../components/popup/Popup";

const MainCarousel = () => {
    const PrevArrowButton = (props) => {
        return (
            <StyledArrowButton className="slick-arrow prev" onClick={props.onClick}>
                <img src={prevIcon} alt="prev"/>
            </StyledArrowButton>
        );
    };

    const NextArrowButton = (props) => {
        return (
            <StyledArrowButton className="slick-arrow next" onClick={props.onClick}>
                <img src={nextIcon} alt="next"/>
            </StyledArrowButton>
        );
    };

    const slideSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrowButton/>,
        prevArrow: <PrevArrowButton/>
    };

    const pensionLocationList = [
        {
            id: 0,
            name: "전체",
            value: "",
        },
        {
            id: 1,
            name: "수동면",
            value: "수동",
        },
        {
            id: 2,
            name: "오남읍",
            value: "오남",
        },
        {
            id: 3,
            name: "조안면",
            value: "조안",
        },
        {
            id: 4,
            name: "진접읍",
            value: "진접",
        },
        {
            id: 5,
            name: "화도읍",
            value: "화도",
        }
    ];

    const pensionTypeList = [
        {
            id: 0,
            name: "전체",
        },
        {
            id: 21,
            name: '협회인증',
        },
        {
            id: 1,
            name: '개별바베큐장',
        },
        {
            id: 19,
            name: '바베큐',
        },
        {
            id: 2,
            name: '카페',
        },
        {
            id: 3,
            name: '캠핑',
        },
        {
            id: 4,
            name: '커플',
        },
        {
            id: 5,
            name: '가족',
        },
        {
            id: 6,
            name: '족구',
        },
        {
            id: 7,
            name: '단체',
        },
        {
            id: 8,
            name: '키즈컨셉',
        },
        {
            id: 9,
            name: '잔디운동장',
        },
        {
            id: 10,
            name: '반려동물가능',
        },
        {
            id: 11,
            name: '수영장',
        },
        {
            id: 20,
            name: '리버뷰',
        },
        {
            id: 12,
            name: '풀빌라/풀빌라독채',
        },
        {
            id: 13,
            name: '노래방',
        },
        {
            id: 14,
            name: '독채형',
        },
        {
            id: 15,
            name: '계곡',
        },
        {
            id: 16,
            name: '월풀/스파',
        },
        // {
        //     id: 17,
        //     name: '스파',
        // },
        {
            id: 18,
            name: '워크샵',
        },
    ];

    const [company_name, setCompany_name] = useState('');
    const [location, setLocation] = useState('');
    const [pensionType, setPensionType] = useState('');
    let history = useHistory();

    function handleClickSearch() {

        history.push('/search/' + location + '?cate=' + (pensionType === "전체" ? "" : pensionType) + (company_name !== "" ? '&company_name=' + company_name : ''));
        // console.log(location, pensionType);
        // console.log('search');
    }

    // 펜션 랜덤으로 보여주기
    function shuffle(array) {
        let currentIndex = array.length, temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    }

    const carouselMemo = useMemo(() => {
        return (
            <Carousel data={shuffle(mainCarouselData)} settings={slideSettings}/>
        );
    }, []);

    return (
        <StyledContainer>
            <Popup/>
            {carouselMemo}
            <StyledSearch>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <StyledSelectContainer>
                        <div>
                            <StyledSelectTitle>지역선택</StyledSelectTitle>

                            <CustomSelect
                                defaultText=""
                                optionList={pensionLocationList}
                                selectValue={setLocation}
                            />
                        </div>

                        <div>
                            <StyledSelectTitle>펜션유형</StyledSelectTitle>

                            <CustomSelect
                                defaultText=""
                                optionList={pensionTypeList}
                                selectValue={setPensionType}
                            />
                        </div>
                    </StyledSelectContainer>
                    <StyledSelectContainer>
                        <div>
                            <StyledSelectTitle>펜션검색</StyledSelectTitle>
                            <div className="search-input-box">
                                <InputBase placeholder="펜션명을 입력하세요." value={company_name} onKeyPress={(e) => {
                                    e.key === 'Enter' && company_name !== "" && handleClickSearch();
                                }}
                                           onChange={(e) => setCompany_name(e.target.value)} className="search-input"/>
                            </div>
                        </div>
                    </StyledSelectContainer>
                </div>

                <StyledSearchButton onClick={handleClickSearch}>
                    검색하기
                </StyledSearchButton>
            </StyledSearch>
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    height: 590px;
    margin: 0 auto;
    //overflow: hidden;
    position: relative;
    
    & div {
        outline: none;
    }
    
    
    
    @media ${device.small} {
        margin-top: 60px;
        height: 400px;
        
       .slick-slider {
            height: 400px;
       }
       
       .slide-title {
            top: 124px;
            left: 50%;
            color: #fff;
            font-size: 19px;
            width: fit-content;
            
            //& p {
            //    font-size: 28px;
            //    margin-bottom: 5px;
            //}
            //
            //& p.small-text {
            //    font-size: 15px;
            //    margin-top: 5px;
            //}
        }
       
    }
`;

const StyledSelectContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 5px;
    
    & > div {
        display: flex;
        align-items: center;
    }
    
    .search-input-box {
        display: block;
        border-radius: 4px;
        background-color:#FFF;
        width:550px;
        padding-left:20px;
    }
    
    .search-input {
        width: 100%;
        background-color:#FFF;
        border-radius: 4px;
        height: 35px;
    }
    
    @media (max-width: 850px) {
    
        .search-input-box {
            width:205px;
        }
        
        .search-input {
            width:205px;
        }
    
        flex-direction: column;
        
        & > div:first-child {
            margin-bottom: 10px;
        }
    }
`;

const StyledArrowButton = styled.button`
    width: 36px;
    height: 73px;
    background: none;
    position: absolute;
    top: 210px;
    opacity: 0.6;

    &.prev {
        //left: 358px;
        left: 18%;
    }

    &.next {
        //right: 358px;
        right: 18%;
    }

    display: flex;
    border: none;
    outline: none;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    z-index: 2;
    
    @media (max-width: 1250px) {
        & img {
            width: 80%;
        }
    }
    
    @media (max-width: 576px) {
        & {
            top: 99px;
            opacity: 0.8;
        }
    
        & img {
            width: 15px;
        }
        
        &.prev {
            left: 2%;
        }
    
        &.next {
            right: 2%;
        }
    }
`;

const StyledSearch = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: rgba(135, 148, 56, 0.85);
    font-size: 18px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    
    @media (max-width: 850px) {
        height: 150px;
    }
`;

const StyledSelectTitle = styled.div`
    margin-left: 40px;
    margin-right: 14px;
    
    @media (max-width: 576px) {
        font-size: 14px;
        width: 54px;
        margin-left: 14px;
    }
`;

const StyledSearchButton = styled.button`
    width: 120px;
    height: 100px;
    background-color: #a7be38;
    color: #fff;
    outline: none;
    border: none;
    cursor: pointer;
    margin-left: 50px;
    
    @media (max-width: 576px) {
        & {
            font-size: 14px;
            
        }
    }
`;

const StyledSelect = styled.div`
    position: relative;
    width: 210px;
    
    select {
      //display: none;
    }
`;

export default MainCarousel;

function CustomSelect(props) {
    const [optionList, setOptionList] = useState(props.optionList);
    const [showOptionList, setShowOptionList] = useState(false);
    const [defaultSelectText, setDefaultSelectText] = useState(props.defaultText);

    function handleListDisplay() {
        setShowOptionList(!showOptionList);
    }

    function handleOptionClick(e) {
        setDefaultSelectText(e.target.getAttribute("data-name"));
        props.selectValue(e.target.getAttribute("data-value"));
        setShowOptionList(false);
    }

    function handleClickOutside(e) {
        if (
            !e.target.classList.contains("custom-select-option") &&
            !e.target.classList.contains("selected-text")
        ) {
            setShowOptionList(false);
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <StyledCustomSelectDiv className="custom-select-container">
            <div
                className={showOptionList ? "selected-text active" : "selected-text"}
                onClick={handleListDisplay}
            >
                {defaultSelectText}
            </div>

            {
                showOptionList &&
                <ul className="select-options">
                    {optionList.map(option => {
                        return (
                            <li
                                className="custom-select-option"
                                data-name={option.name}
                                data-value={option.value === undefined ? option.name : option.value}
                                key={option.id}
                                onClick={handleOptionClick}
                            >
                                {option.name}
                            </li>
                        )
                    })}
                </ul>
            }
        </StyledCustomSelectDiv>
    )
}

const StyledCustomSelectDiv = styled.div`
    &.custom-select-container {
      display: block;
      width: 205px;
      height: 35px;
      position: relative;
    }
    
    @media (max-width: 576px) {
        &.custom-select-container {
              width: 140px;
        }
    }
    
    .selected-text {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: #fff;
      //border-bottom: 1px solid #37b0b0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 5px;
      cursor: default;
      user-select: none;
      color: #313131;
      font-size: 13px;
      box-sizing: content-box;
      padding-left: 20px;
      
      &.active {
          border: 1px solid #a7be38;
          border-radius: 5px 5px 0 0;
          margin: -1px;
      }
    }
    
    .selected-text::after {
      content: "";
      position: absolute;
      right: 10px;
      top: 12px;
      display: inline-block;
      border: solid #697a17;
      border-width: 0 2px 2px 0;
      padding: 3px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
    
    //.selected-text.active::after {
    //  top: 8px;
    //  border-color: transparent transparent #697a17 transparent;
    //}
    
    ul {
      margin: -1px;
      padding: 0;
      text-align: left;
      border-left: 1px solid #a7be38;
      border-right: 1px solid #a7be38;
      border-bottom: 1px solid #a7be38;
      border-radius: 0 0 5px 5px;
      background-color: #fff;
      box-sizing: content-box;
      font-size: 13px;
      color: #313131;
      z-index: 2;
    }
    
    .select-options {
      position: absolute;
      width: 109.6%;
    }
    
    @media (max-width: 576px) {
        .select-options {
              position: absolute;
              width: 160px;
        }
    }
    
    li {
      list-style-type: none;
      padding: 6px 20px;
      cursor: pointer;
    }
    
    li:hover {
      background-color: #a7be38;
      color: #ffffff;
    }

`;