import React from 'react';
import styled from 'styled-components';
import HomeIcon from '@material-ui/icons/Home';
import {Link} from 'react-router-dom';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PropTypes from 'prop-types';

function Breadcrumb(props) {

    const lastIndex = props.data.length - 1;

    return (
        <StyledDiv>
            <Link to="/" className="home">
                <HomeIcon color="action" fontSize="small" /> 홈
            </Link>
            {
                props.data.map((item, index) => {
                    return (
                        <React.Fragment key={item.id}>
                            <ChevronRightIcon color="action" />
                            <StyledLink
                                to={item.link}
                                active={lastIndex === index ? "true" : "false"}
                            >
                                {item.title}
                            </StyledLink>
                        </React.Fragment>
                    )
                })
            }
        </StyledDiv>
    );
}

Breadcrumb.propTypes = {
    data: PropTypes.array
};

const StyledDiv = styled.div`
    max-width: 1250px;
    margin: 30px auto;
    padding: 0 20px;
    display: flex;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.7);

    a {
        display: flex;
        align-items: center;
    }
    
    .home {
        svg {
            margin-right: 7px;
        }
    }
`;

const StyledLink = styled(Link)`
    color: ${props => props.active === "true" ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0.7)'};
    font-weight: ${props => props.active === "true" ? 'bold' : 'normal'};
`;

export default Breadcrumb;