import React from 'react';

const Stay = () => {
    return (
        <div>
            Stay
        </div>
    );
};

export default Stay;