import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Portal} from './';

const Modal = ({
   className,
   onClose,
   maskClosable,
   closable,
   visible,
   children
}) => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = '15px';
        return () => {
            document.body.style.overflow = 'auto';
            document.body.style.paddingRight = '0';
        }
    }, []);

    const onMaskClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose(e);
        }
    };

    const close = (e) => {
        if (onClose) {
            onClose(e);
        }
    };

    return (
        <Portal elementId="modal-root">
            <ModalOverlay visible={visible} />
            <ModalWrapper
                className={className}
                onClick={maskClosable ? onMaskClick : null}
                tabIndex="-1"
                visible={visible}
            >
                <ModalInner tabIndex="0" className="modal-inner">
                    {closable && <CloseButton className="modal-close" onClick={close}>x</CloseButton>}
                    {children}
                </ModalInner>
            </ModalWrapper>
        </Portal>
    );
};

Modal.defaultProps = {
    visible: false,
    closable: true,
    maskClosable: true
};

Modal.propTypes = {
    visible: PropTypes.bool
};

const ModalWrapper = styled.div`
    box-sizing: border-box;
    display: ${(props) => (props.visible ? 'block' : 'none')};
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    overflow: auto;
    outline: 0;
`;

const ModalOverlay = styled.div`
    box-sizing: border-box;
    display: ${(props) => (props.visible ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 999;
`;

const ModalInner = styled.div`
    box-sizing: border-box;
    position: relative;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
    background-color: #fff;
    border-radius: 10px;
    width: 80%;
    height: 600px;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    padding: 40px 20px;
    outline: 0;
    overflow: auto;
    
    & iframe {
        width: 100%;
        height: 500px;
        border: none;
    }
    
    & .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

const CloseButton = styled.button`
    width: 30px;
    height: 30px;
    font-size: 22px;
    font-weight: bold;
    outline: none;
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
`;

export default Modal;