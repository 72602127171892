import React, {useEffect, useState} from 'react';
import {Logo} from './';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Link, useHistory} from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {List, ListItem, ListItemText, Collapse, Divider} from "@material-ui/core";

import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    list: {
        paddingLeft: theme.spacing(4),
        color: '#7d7d7d'
    },
    nested: {
        paddingLeft: theme.spacing(6),
        borderBottom: '1px solid #F0F0F0',
        color: '#7d7d7d',
        '&:last-child': {
            borderBottom: 0,
        }
    },
}));

function Header(props) {

    const classes = useStyles();

    const [moreOpen, setMoreOpen] = useState({
        sub1: false,
        sub2: false,
        sub3: false,
        sub4: false,
        sub5: false
    });

    const handleHover = (menu) => {
        const menuStatus = {
            sub1: false,
            sub2: false,
            sub3: false,
            sub4: false,
            sub5: false
        };

        setMoreOpen({...menuStatus, ...menu})
    }
    const handleHoverSub = (menu) => {

        setMoreOpen({...moreOpen, ...menu})
    }

    function handleHoverOut() {
        setMoreOpen({
            sub1: false,
            sub2: false,
            sub3: false,
            sub4: false,
            sub5: false
        });
    }

    // 모바일 메뉴 버튼 클릭
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    function handleClickMobileNav() {
        setMobileMenuOpen(!mobileMenuOpen);
    }

    let history = useHistory();

    // 모바일 메뉴 오픈 시 body 스크롤 숨기기
    useEffect(() => {
        if (mobileMenuOpen) {
            document.body.style.overflow = 'hidden';
            document.body.style.paddingRight = '15px';
        } else {
            document.body.style.overflow = 'auto';
            document.body.style.paddingRight = '0';
        }
    }, [mobileMenuOpen]);

    return (
        <>
            <StyledContainer>
                <StyledWrapper maxWidth={props.maxWidth} onMouseLeave={handleHoverOut}>
                    <Logo/>

                    <StyledMenu>
                        <ul className="depth1-ul">
                            <li className="depth1-li">
                                지역별 펜션 안내
                            </li>
                            <li className="depth1-li">
                                <Link to="/search/수동">수동면</Link>
                            </li>
                            <li className="depth1-li">
                                <Link to="/search/오남">오남읍</Link>
                            </li>
                            <li className="depth1-li">
                                <Link to="/search/조안">조안면</Link>
                            </li>
                            <li className="depth1-li">
                                <Link to="/search/진접:진건">진접&진건읍</Link>
                            </li>
                            <li className="depth1-li">
                                <Link to="/search/화도">화도읍</Link>
                            </li>
                            <li className="depth1-li">
                                <a href="https://www.nyj.go.kr/culture/index.do" target="_blank">남양주관광지</a>
                                {/*<Link to="/tour" onMouseOver={() => handleHover({sub1: true})}>남양주관광지</Link>*/}
                                {/*<StyledSubMenu open={moreOpen.sub1} onMouseLeave={handleHoverOut}>*/}
                                {/*    <div className="box-wrap">*/}
                                {/*        <div>*/}
                                {/*            <Link to="/tourlist/자연관광" className="title">자연관광</Link>*/}
                                {/*        </div>*/}
                                {/*        <div>*/}
                                {/*            <Link to="/tourlist/문화관광" className="title">문화관광</Link>*/}
                                {/*        </div>*/}
                                {/*        <div>*/}
                                {/*            <Link to="/tourlist/레저관광" className="title">레저관광</Link>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</StyledSubMenu>*/}
                            </li>
                            <li className="depth1-li">
                                <Link to="/photo/event">EVENT</Link>
                                {/*<Link to="/tourlist/축제" onMouseOver={() => handleHover({sub2: true})}>남양주축제</Link>*/}
                                {/*<StyledSubMenu open={moreOpen.sub2} onMouseLeave={handleHoverOut}>*/}
                                {/*    <div className="box-wrap">*/}
                                {/*        <div>*/}
                                {/*            <Link to="/tourdetail2/festival/1" className="title">정약용문화제</Link>*/}
                                {/*        </div>*/}
                                {/*        <div>*/}
                                {/*            <Link to="/tourdetail2/festival/2" className="title">광릉숲축제</Link>*/}
                                {/*        </div>*/}
                                {/*        <div>*/}
                                {/*            <Link to="/tourdetail2/festival/3" className="title">천마산소나무축제</Link>*/}
                                {/*        </div>*/}
                                {/*        <div>*/}
                                {/*            <Link to="/tourdetail2/festival/5" className="title">봉선사연꽃축제</Link>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</StyledSubMenu>*/}
                            </li>
                            <li className="depth1-li">
                                <Link to="/board/notice" onMouseOver={() => handleHover({sub3: true})}>커뮤니티</Link>
                                <StyledSubMenu open={moreOpen.sub3} onMouseLeave={handleHoverOut}>
                                    <div className="box-wrap">
                                        <div>
                                            <Link to="/board/notice" className="title">공지사항</Link>
                                        </div>
                                        <div>
                                            <Link to="/board/data" className="title">보도자료</Link>
                                        </div>
                                        {/*<div>*/}
                                        {/*    <Link to="/board/gallery" className="title">갤러리</Link>*/}
                                        {/*</div>*/}
                                    </div>
                                </StyledSubMenu>
                            </li>
                            <li className="depth1-li">
                                <Link to="/greetings" onMouseOver={() => handleHover({sub4: true})}>협회소개</Link>
                                <StyledSubMenu open={moreOpen.sub4} onMouseLeave={handleHoverOut}>
                                    <div className="box-wrap">
                                        <div>
                                            <Link to="/greetings" className="title">인사말</Link>
                                        </div>
                                        <div>
                                            <Link to="/orginfo" className="title">조직도</Link>
                                        </div>
                                        <div>
                                            <Link to="/major" className="title">주요사업</Link>
                                        </div>
                                        <div>
                                            <Link to="/partners" className="title">협력업체</Link>
                                        </div>
                                        <div>
                                            <Link to="/articles" className="title">협회정관</Link>
                                        </div>
                                        <div>
                                            <Link to="/join" className="title">협회원가입</Link>
                                        </div>
                                        <div>
                                            <Link to="#" className="title">협회전용</Link>
                                            <div>
                                                <div>
                                                    <Link to="/board/guide" className="title">&bull; 협회 안내사항</Link>
                                                </div>
                                                <div>
                                                    <Link to="/board/member" className="title">&bull; 회원의 소리</Link>
                                                </div>
                                                <div>
                                                    <Link to="/board/brochure" className="title">&bull; 홍보물신청</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </StyledSubMenu>
                            </li>
                            {/*<li className="depth1-li">*/}
                            {/*    <Link to="#" onMouseOver={() => handleHover({sub5: true})}>협회전용</Link>*/}
                            {/*    <StyledSubMenu open={moreOpen.sub5} onMouseLeave={handleHoverOut}>*/}
                            {/*        <div className="box-wrap">*/}
                            {/*            <div>*/}
                            {/*                <Link to="#" className="title">협회 안내사항</Link>*/}
                            {/*            </div>*/}
                            {/*            <div>*/}
                            {/*                <Link to="/board/member" className="title">회원의 소리</Link>*/}
                            {/*            </div>*/}
                            {/*            <div>*/}
                            {/*                <Link to="#" className="title">홍보물신청</Link>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </StyledSubMenu>*/}
                            {/*</li>*/}
                        </ul>
                    </StyledMenu>

                    <StyledMobileButton onClick={handleClickMobileNav}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </StyledMobileButton>
                </StyledWrapper>

                <StyledSideNav open={mobileMenuOpen}>

                    <List component="nav">
                        <ListItem className={classes.list} button onClick={() => {
                            history.push('/search/수동');
                            setMobileMenuOpen(false);
                        }}>
                            <ListItemText primary={"수동면펜션"}/>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.list} button onClick={() => {
                            history.push('/search/오남');
                            setMobileMenuOpen(false);
                        }}>
                            <ListItemText primary={"오남읍펜션"}/>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.list} button onClick={() => {
                            history.push('/search/조안');
                            setMobileMenuOpen(false);
                        }}>
                            <ListItemText primary={"조안면펜션"}/>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.list} button onClick={() => {
                            history.push('/search/진접');
                            setMobileMenuOpen(false);
                        }}>
                            <ListItemText primary={"진접읍펜션"}/>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.list} button onClick={() => {
                            history.push('/search/화도');
                            setMobileMenuOpen(false);
                        }}>
                            <ListItemText primary={"화도읍펜션"}/>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.list} button onClick={() => handleHover({sub1: !moreOpen.sub1})}>
                            <ListItemText primary={"남양주관광지"}/>
                            {moreOpen.sub1 ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                        </ListItem>
                        <Collapse in={moreOpen.sub1} timeout="auto" unmountOnExit>
                            <List component="nav" disablePadding>
                                <ListItem className={classes.nested} button onClick={() => {
                                    history.push('/tourlist/자연관광');
                                    setMobileMenuOpen(false);
                                }}>
                                    <ListItemText primary="자연관광"/>
                                </ListItem>
                                <ListItem className={classes.nested} button onClick={() => {
                                    history.push('/tourlist/문화관광');
                                    setMobileMenuOpen(false);
                                }}>
                                    <ListItemText primary="문화관광"/>
                                </ListItem>
                                <ListItem className={classes.nested} button onClick={() => {
                                    history.push('/tourlist/레저관광');
                                    setMobileMenuOpen(false);
                                }}>
                                    <ListItemText primary="레저관광"/>
                                </ListItem>
                                {/*<ListItem className={classes.nested} button onClick={() => {*/}
                                {/*    history.push('/tourlist2/수목원');*/}
                                {/*    setMobileMenuOpen(false);*/}
                                {/*}}>*/}
                                {/*    <ListItemText primary="수목원"/>*/}
                                {/*</ListItem>*/}
                                {/*<ListItem className={classes.nested} button onClick={() => {*/}
                                {/*    history.push('/tourlist2/공원');*/}
                                {/*    setMobileMenuOpen(false);*/}
                                {/*}}>*/}
                                {/*    <ListItemText primary="공원"/>*/}
                                {/*</ListItem>*/}
                                {/*<ListItem className={classes.nested} button onClick={() => {*/}
                                {/*    history.push('/tourlist2/볼거리');*/}
                                {/*    setMobileMenuOpen(false);*/}
                                {/*}}>*/}
                                {/*    <ListItemText primary="볼거리"/>*/}
                                {/*</ListItem>*/}
                            </List>
                        </Collapse>
                        <Divider/>
                        <ListItem className={classes.list} button onClick={() => handleHover({sub2: !moreOpen.sub2})}>
                            <ListItemText primary={"남양주축제"}/>
                            {moreOpen.sub2 ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                        </ListItem>
                        <Collapse in={moreOpen.sub2} timeout="auto" unmountOnExit>
                            <List component="nav" disablePadding>
                                <ListItem className={classes.nested} button onClick={() => {
                                    history.push('/tourdetail2/festival/1');
                                    setMobileMenuOpen(false);
                                }}>
                                    <ListItemText primary="정약용문화제"/>
                                </ListItem>
                                <ListItem className={classes.nested} button onClick={() => {
                                    history.push('/tourdetail2/festival/2');
                                    setMobileMenuOpen(false);
                                }}>
                                    <ListItemText primary="광릉숲축제"/>
                                </ListItem>
                                <ListItem className={classes.nested} button onClick={() => {
                                    history.push('/tourdetail2/festival/3');
                                    setMobileMenuOpen(false);
                                }}>
                                    <ListItemText primary="천마산소나무축제"/>
                                </ListItem>
                                <ListItem className={classes.nested} button onClick={() => {
                                    history.push('/tourdetail2/festival/5');
                                    setMobileMenuOpen(false);
                                }}>
                                    <ListItemText primary="봉선사연꽃축제"/>
                                </ListItem>
                            </List>
                        </Collapse>
                        <Divider/>
                        <ListItem className={classes.list} button onClick={() => handleHover({sub3: !moreOpen.sub3})}>
                            <ListItemText primary={"커뮤니티"}/>
                            {moreOpen.sub3 ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                        </ListItem>
                        <Collapse in={moreOpen.sub3} timeout="auto" unmountOnExit>
                            <List component="nav" disablePadding>
                                <ListItem className={classes.nested} button onClick={() => {
                                    history.push('/board/event');
                                    setMobileMenuOpen(false);
                                }}>
                                    <ListItemText primary="EVENT"/>
                                </ListItem>
                                <ListItem className={classes.nested} button onClick={() => {
                                    history.push('/board/notice');
                                    setMobileMenuOpen(false);
                                }}>
                                    <ListItemText primary="공지사항"/>
                                </ListItem>
                                <ListItem className={classes.nested} button onClick={() => {
                                    history.push('/board/data');
                                    setMobileMenuOpen(false);
                                }}>
                                    <ListItemText primary="보도자료"/>
                                </ListItem>
                                <ListItem className={classes.nested} button onClick={() => {
                                    history.push('/board/gallery');
                                    setMobileMenuOpen(false);
                                }}>
                                    <ListItemText primary="갤러리"/>
                                </ListItem>
                            </List>
                        </Collapse>
                        <Divider/>
                        <ListItem className={classes.list} button onClick={() => handleHover({sub4: !moreOpen.sub4})}>
                            <ListItemText primary={"협회소개"}/>
                            {moreOpen.sub4 ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                        </ListItem>
                        <Collapse in={moreOpen.sub4} timeout="auto" unmountOnExit>
                            <List component="nav" disablePadding>
                                <ListItem className={classes.nested} button onClick={() => {
                                    history.push('/greetings');
                                    setMobileMenuOpen(false);
                                }}>
                                    <ListItemText primary="인사말"/>
                                </ListItem>
                                <ListItem className={classes.nested} button onClick={() => {
                                    history.push('/orginfo');
                                    setMobileMenuOpen(false);
                                }}>
                                    <ListItemText primary="조직도"/>
                                </ListItem>
                                <ListItem className={classes.nested} button onClick={() => {
                                    history.push('/major');
                                    setMobileMenuOpen(false);
                                }}>
                                    <ListItemText primary="주요사업"/>
                                </ListItem>
                                <ListItem className={classes.nested} button onClick={() => {
                                    history.push('/partners');
                                    setMobileMenuOpen(false);
                                }}>
                                    <ListItemText primary="협력업체"/>
                                </ListItem>
                                <ListItem className={classes.nested} button onClick={() => {
                                    history.push('/articles');
                                    setMobileMenuOpen(false);
                                }}>
                                    <ListItemText primary="협회정관"/>
                                </ListItem>
                                <ListItem className={classes.nested} button onClick={() => {
                                    history.push('/join');
                                    setMobileMenuOpen(false);
                                }}>
                                    <ListItemText primary="협회원가입"/>
                                </ListItem>
                            </List>
                        </Collapse>
                        <Divider/>
                        <ListItem className={classes.list} button onClick={() => handleHover({sub5: !moreOpen.sub5})}>
                            <ListItemText primary={"협회전용"}/>
                            {moreOpen.sub5 ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                        </ListItem>
                        <Collapse in={moreOpen.sub5} timeout="auto" unmountOnExit>
                            <List component="nav" disablePadding>
                                <ListItem className={classes.nested} button onClick={() => {
                                    history.push('/board/guide');
                                    setMobileMenuOpen(false);
                                }}>
                                    <ListItemText primary="협회 안내사항"/>
                                </ListItem>
                                <ListItem className={classes.nested} button onClick={() => {
                                    history.push('/board/member');
                                    setMobileMenuOpen(false);
                                }}>
                                    <ListItemText primary="회원의 소리"/>
                                </ListItem>
                                <ListItem className={classes.nested} button onClick={() => {
                                    history.push('/board/brochure');
                                    setMobileMenuOpen(false);
                                }}>
                                    <ListItemText primary="홍보물신청"/>
                                </ListItem>
                            </List>
                        </Collapse>
                    </List>

                </StyledSideNav>
                <StyledSideBG open={mobileMenuOpen}/>
            </StyledContainer>
        </>
    );
}

Header.defaultProps = {
    isSearch: true
};

Header.propTypes = {
    isSearch: PropTypes.bool
};

const StyledContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    height: 80px;
    border-bottom: 1px solid #dfdfdf;
    padding: 0 10px;
    
    @media (max-width: 1250px) {
        // padding: 0 20px;
    }
    
    @media (max-width: 940px) {
    
        height: 60px;
        // padding: 0 20px;
        
        position: fixed;
        top: 0;
        left: 0;
        background-color: #fff;
        z-index: 10;
    }
`;

const StyledWrapper = styled.div`

    width: 100%;
    height: 80px;
    max-width: 1400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    
    @media (max-width: 940px) {
        height: 60px;
    }
`;

const StyledMenu = styled.div`
    width: 100%;

    .depth1-ul {
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: space-around;
        padding: 0 0 0 50px;
        
        .depth1-li {
            display: flex;
            flex:0.7;
            position: relative;
            justify-content: center;
            align-items: center;
            // padding: 2px 0;
            font-weight: 300;
            &:first-child {
                flex:0.8;
                min-width: 115px;
                font-weight: 500;
            }
            &:nth-child(2) {
                margin-left:20px;
                padding-left:10px;
                border-left:1px solid #000;
            }
            &:nth-child(6) {
                padding-right:10px;
                border-right:1px solid #000;
            }
            &:nth-child(7) {
                margin-left:20px;
                min-width: 91px;
                flex:1;
                font-weight: 400;
            }
            &:nth-child(8) {
                min-width: 80px;
                flex:1;
                font-weight: 400;
            }
            &:nth-child(9) {
                min-width: 59px;
                flex:1;
                font-weight: 400;
            }
            &:nth-child(10) {
                min-width: 59px;
                flex:1;
                font-weight: 400;
            }
            
            
            & > a:hover {
                color: #a7be38;
            }
            
            & > div {
                font-size: 15px;
                color:#fff;
                margin-left:10px;
            }
        }
        
    }

    @media (max-width: 940px) {
        display: none;
    }
`;

const StyledSubMenu = styled.div`
    position: absolute;
    width:110%;
    top: 49px;
    left: 0;

    z-index: 10;
    background-color: rgba(135, 148, 56, 0.95);
    color: #fff;
    padding: 20px 10px 0;
    
    display: ${props => props.open ? 'flex' : 'none'};
    // justify-content: space-around;
    
    .title {
        display: flex;
        margin-bottom: 10px;
        padding-bottom: 5px;
        border-bottom: 1px solid rgba(255,255,255, .4);
    }
    
    .box-wrap {
        position:relative;
        display: flex;
        flex-direction: column;
        flex:1;
        & > div {
            margin-bottom: 20px;
            
            & > div > div {
                
                margin-bottom: 10px;
                
                .title {
                    border-bottom: 1px solid rgba(255,255,255, .4);
                    padding-left:15px;
                }
            }
        }
    }
    
    a:hover {
        color: yellowgreen;
    }
`;


const StyledMobileButton = styled.div`
    width: 31px;
    height: 28px;
    padding: 5px;
    display: none;

    span {
        display: block;
        width: 21px;
        border-bottom: 2px solid #434343;
        margin-bottom: 6px;
        
        &:last-child {
            margin-bottom: 0;
        }
    }
    
    cursor: pointer;
    
    @media (max-width: 940px) {
        display: block;
    }
`;

const StyledSideNav = styled.div`
    position: fixed;
    top: 60px;
    right: 0;
    z-index: 2;
    background-color: #fff;
    
    width: ${props => props.open ? '88%' : '0'};
    height: calc(100% - 60px);
    overflow-x: hidden;
    transition: 0.5s;
    
    .link-box {
        border-bottom: 1px solid #e5e5e5;
        color: #7d7d7d;
        font-size: 15px;
        
        a {
            display: flex;
            align-items: center;
            height: 60px;
            padding-left: 41px;
            
            svg {
                margin-top: 3px;
            }
        }
        
        .sub-menu {
            padding-left: 30px;
            
            .title {
                font-weight: bold;
            }
            
            .underline {
                border-bottom: 1px solid #e5e5e5;
            }
            
           
        }
        
        & a:hover {
            color: #000;
        }
        
    }
    
    .more-link-box {
        background-color: #a7be38;
        border-bottom: 1px solid #e5e5e5;
        color: #fff;
        font-size: 15px;
        
        a {
            display: flex;
            align-items: center;
            height: 60px;
            padding-left: 41px;
            
            svg {
                margin-top: 3px;
            }
        }
        
        & a:hover {
            color: dimgrey;
        }
    }
    
    @media (min-width: 941px) {
        display: none;
    }
`;

const StyledSideBG = styled.div`
    position: fixed;
    top: 60px;
    right: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.4);
    width: ${props => props.open ? '100%' : '0'};
    height: 100%;
    transition: 0.5s;
    
    @media (min-width: 941px) {
        display: none;
    }
`;

export default Header;