import React from 'react';
import {Header, Footer, RecentView, ScrollTop} from '../../components';

import MainBanner from './MainBanner';
import MainRecommend from './MainRecommend';
import MainFarm from './MainFarm';
import MainCarousel from './MainCarousel';
import Banner from "../../components/banner/Banner";
import YoutubeBanner from "../../components/banner/YoutubeBanner";

const Main = () => {
    return (
        <>
            <Header/>
            <MainCarousel/>
            {/*<YoutubeBanner/>*/}
            <Banner/>
            <MainRecommend/>
            <Banner type={true}/>
            {/*<MainBanner/>*/}
            {/*<MainFarm />*/}
            <RecentView/>
            <Footer/>
            <ScrollTop/>
        </>
    );
};

export default Main;