import React, {useState, useEffect} from 'react';
import {Header, Footer, Breadcrumb, PhotoBox} from '../../components';
import {useParams, Link, useLocation} from 'react-router-dom';
import styled from 'styled-components';
import mapBg from "../../assets/images/map/bg.jpg";

import apiBoardData from '../../dummy/boardData.json';
import {callApi} from "../../api";
import Cookies from "universal-cookie";

function BoardPhoto(props) {
    const cookie = new Cookies();

    const user_id = cookie.get('user_id') !== undefined ? cookie.get('user_id') : null;

    let {type} = useParams();

    const location = useLocation();
    const params = new URLSearchParams(location.search);

    let boardTitle = 'EVENT';

    const breadcrumb = [{
        id: 1,
        title: boardTitle,
        link: '/photo/' + type
    }];

    const [pageSize, setPageSize] = useState([]);
    const [lastPage, setLastPage] = useState(1);
    const [boardData, setBoardData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(isNaN(params.get('page')) ? params.get('page') : 1);
    // const [page, setPage] = useState(33);

    useEffect(() => {
        // 번호 내림차순
        // apiBoardData.sort(function(a, b) {
        //     return b.number - a.number;
        // });

        callApi('/board/' + type + '?page=' + page, 'GET', {}).then((res) => {
            if (res.success) {
                setBoardData(res.list);
                setPage(res.page);

                let pageArray = [];
                let starNum = Math.floor(page / 10) * 10;
                let lastPage = Math.ceil(res.totalCount / res.pageSize);
                let lastNum = lastPage > (starNum + 9) ? (starNum + 9) : lastPage;
                for (let i = starNum + 1; i <= lastNum; i++) {
                    pageArray.push(i);
                }
                setPageSize(pageArray);
                setLastPage(lastPage);
            }
        })

        setIsLoading(true);
    }, [type, page]);

    const addQuery = (key, value) => {
        if (key !== 'page')
            return false;

        if (value == page)
            return false;

        let pathname = props.location.pathname;
        // returns path: '/app/books'
        let searchParams = new URLSearchParams(props.location.search);
        // returns the existing query string: '?type=fiction&author=fahid'
        searchParams.set(key, value);
        setPage(value);

        props.history.push({
            pathname: pathname,
            search: searchParams.toString()
        });
    };

    return (
        <>
            <Header/>

            <StyledContainer>
                <div className="title-wrap">
                    <img src={mapBg} alt=""/>
                    <div className="title">
                        <span>{boardTitle}</span>
                    </div>
                </div>

                <Breadcrumb data={breadcrumb}/>

                <div className="category">
                    <div></div>
                    <div className="active">{boardTitle}</div>
                    <div></div>
                </div>

                <StyledCategoryContainer>
                    {
                        isLoading &&
                        boardData.map((item, index) => {
                            return (
                                <PhotoBox key={item.id}
                                          id={item.id}
                                          link={"/photo/" + type + "/" + item.id}
                                          title={item.title}
                                          images={item.files}
                                />
                                // <tr key={item.id}>
                                //     <td className="td-number">{item.number}</td>
                                //     <td className="td-title">
                                //         <Link to={"/board/" + type + "/" + item.id}>
                                //             {item.title}
                                //         </Link>
                                //     </td>
                                //     <td className="td-writer">{item.writer}</td>
                                //     <td className="td-date">{item.date.substring(0,10)}</td>
                                //     <td className="td-hit">{item.hit}</td>
                                // </tr>
                            );
                        })
                    }

                    <StyledWriterButton>
                        {
                            type === 'free' &&
                            <Link to={"/boardwrite/" + type}>
                                글쓰기
                            </Link>
                        }
                        {
                            type === 'member' && user_id &&
                            <Link to={"/boardwrite/" + type}>
                                글쓰기
                            </Link>
                        }
                    </StyledWriterButton>
                    <StyledDiv>
                        <>
                            <div onClick={() => addQuery('page', 1)}>&#60;&#60;</div>
                            <div onClick={() => addQuery('page', (page - 10) < 1 ? 1 : (page - 10))}>&#60;</div>
                        </>
                        {pageSize.map((v, i) => {
                            return page == v ?
                                (<div key={i} className={'active'}>{v}</div>) :
                                (<div key={i} onClick={() => addQuery('page', v)}>{v}</div>)
                        })}
                        {lastPage > 10 ? (
                            <>
                                <div onClick={() => addQuery('page', (page + 10) > lastPage ? (page + 10) : lastPage)}>&#62;</div>
                                <div onClick={() => addQuery('page', lastPage)}>&#62;&#62;</div>
                            </>
                        ) : null}
                    </StyledDiv>
                </StyledCategoryContainer>
            </StyledContainer>

            <Footer/>
        </>
    );
}

const StyledDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    div {
        cursor: pointer;
        padding: 5px;
        width:30px;
        text-align:center;
        // display:flex;
        // align-items: center;
        // justify-contents: center;
    }
    
    div.active {
        cursor: normal;
        font-weight:bold;
        border-bottom:1px solid #F6F6F6;
    }
`;

const StyledContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    
    br {
        display: none;
    }
    
    .title-wrap {
        position: relative;
        user-select: none;
        
        img {
            width: 100%;
        }
        
        .title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 45px;
            font-weight: 200;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
            
            span {
                font-weight: 600;
            }
        }        
    }
    
    .category {
        max-width: 1050px;
        display: flex;
        margin: 110px auto 30px;
        
        div {
            width: 100%;
            max-width: 440px;
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            color: #434343;
            border-bottom: 2px solid #dcdcdc;
            
            &.active {
                width: 100%;
                max-width: 170px;
                border-bottom: 2px solid #f68b1f;
                padding-bottom: 12px;
            }
        }
    }
    
    @media (max-width: 940px) {
        margin: 60px auto 0;
    }
    
    @media (max-width: 475px) {
        br {
            display: block;
        }
    
        .title-wrap {
            img {
                height: 300px;
                object-fit: cover;
            }
            
            .title {
                font-size: 29px;
                text-align: center;
                width: 85%;
            }
        }
        
        .category {
            margin-top: 45px;
            
            div {
                font-size: 19px;
            }
        }
    }
`;

const StyledCategoryContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 30px auto 93px;
    // border:1px solid red;
    max-width: 1120px;
    padding: 0 20px;
`;

const StyledTable = styled.table`
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    border-top: 1px solid #d0d0d0;
    border-bottom: none;
    
    thead th {
        font-size: 14px;
        font-weight: normal;
    }
    
    .th-number {
        width: 10%;
    }
    
    .th-title {
        width: 50%;   
    }
    
    .th-writer {
    
    }
    
    .th-date {
        
    }
    
    .th-hit {
        
    }
    
    tbody td {
        font-size: 13px;
        height: 35px;
    }
    
    .td-number {
        text-align: center;
    }
    
    .td-title {
           
    }
    
    .td-writer {
        text-align: center;
    }
    
    .td-date {
        text-align: center;
    }
    
    .td-hit {
        text-align: center;
    }
    
    @media (max-width: 700px) {
        thead th {
            font-size: 12px;
        }
    }
`;

const StyledWriterButton = styled.div`
    width: 100%;
    max-width: 900px;
    margin: 20px auto 0;
    text-align: right;
    
    a {
            margin-top: 10px;
            padding: 10px;
            font-size: 14px;
            background-color: #fff;
            border: 1px solid #dcdcdc;
        }
`;

export default BoardPhoto;