import React, {useEffect, useState} from "react";
import YouTube, {YouTubeProps} from 'react-youtube';

const YoutubeBanner = (props) => {

    const urlSearch = new URLSearchParams(props.list[0].link);

    const videoId = urlSearch.get('v') ?? "_wjkIJflXSs";
    const videoUrl = "https://www.youtube.com/watch?v=_wjkIJflXSs";
    const [size, setSize] = useState({
        width: window.innerWidth,
        height: window.innerWidth > 1440 ? (1440 * 9) / 16 : (window.innerWidth * 9) / 16
    });

    const opts = {
        // height: size.height,
        height: '100%',
        width: '100%',
        playerVars: {
            autoplay: 1,
            loop: 1,
            mute: 1,
            controls: 0,
            disablekb: 0,
            modestbranding: 0,
            rel: 0,
            showinfo: 0,
            fs: 0,
            playlist: videoId,
            vq: 'hd1080'
        },
    };

    const handleResize = () => {
        setSize({
            width: window.innerWidth,
            height: window.innerWidth > 1440 ? (1440 * 9) / 16 : (window.innerWidth * 9) / 16
        });
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
        // callYoutube();
    }, []);

    const callYoutube = () => {
        // callApi('/banner', 'GET', {}).then((res) => {
        //     if (res.success) {
        //         const left = res.list.filter((list) => list.type === 'left');
        //         const right = res.list.filter((list) => list.type === 'right');
        //
        //         setLeftBanner(left);
        //         setRightBanner(right);
        //     }
        // }).catch((msg) => {
        //     console.log(msg);
        // });
    }


    const onPlayerReady = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }

    return (
        <YouTube
            style={{height:'100%'}}
            key={videoId}
            videoId={videoId}
            opts={opts}
            // onReady={readyOpts}
        />
    );
    // return (
    //     <ReactPlayer url={videoUrl}
    //                  playing={true}        // 자동 재생 on
    //                  loop={true}
    //                  muted={true}          // 자동 재생 on
    //                  controls={false}       // 플레이어 컨트롤 노출 여부
    //                  light={false}         // 플레이어 모드
    //                  pip={false}
    //                  width={'100%'}
    //                  height={'100%'}
    //                  style={{position: 'absolute', top: 0, left: 0}}
    //     />
    // );
}

export default YoutubeBanner;