import React from 'react';
import {Footer, Header} from '../../components';
import {Link} from 'react-router-dom';
import CsHeader from './CsHeader';

function Faq(props) {
    return (
        <>
            <Header isSearch={false} />

            <CsHeader />

            <div>
                <h1>공지사항</h1>
                <h1 style={{background: "green"}}>자주 묻는 질문</h1>

                <Link to="/notice">공지사항</Link>
                <Link to="/faq">자주 묻는 질문</Link>
                <Link to="/qna">질문과 답변</Link>
            </div>

            <Footer />
        </>
    );
}

export default Faq;