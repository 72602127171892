import React, {useState} from 'react';
import {Header, Footer, Breadcrumb, WysiwygEditor} from '../../components';
import {Link, useParams} from 'react-router-dom';
import mapBg from "../../assets/images/map/bg.jpg";
import styled from "styled-components";
import {stateToHTML} from "draft-js-export-html";
import Cookies from 'universal-cookie';
import {callApi} from "../../api";

function BoardWrite(props) {
    const cookie = new Cookies();

    const user_id = cookie.get('user_id') !== undefined ? cookie.get('user_id') : null;
    const user_name = cookie.get('user_name') !== undefined ? cookie.get('user_name') : null;

    let {type} = useParams();

    let boardTitle;
    if (type === 'notice') {
        boardTitle = '공지사항';
    } else if (type === 'free') {
        boardTitle = '자유게시판';
    } else if (type === 'member') {
        boardTitle = '협회원전용';
    } else {
        boardTitle = '기타';
    }

    const breadcrumb = [{
        id: 1,
        title: boardTitle,
        link: '/board/' + type
    }];

    // 제목
    const [title, setTitle] = useState();

    function handleChangeTitle(event) {
        setTitle(event.target.value);
    }

    // 작성자
    const [writer, setWriter] = useState();

    function handleChangeWriter(event) {
        setWriter(event.target.value);
    }

    // 비밀번호
    const [password, setPassword] = useState();

    function handleChangePassword(event) {
        setPassword(event.target.value);
    }

    // 내용
    const [content, setContent] = useState('');

    function handleClickSubmit() {

        if (title === undefined) {
            alert('제목을 입력해주세요.');
            return null;
        }

        let submitData = {};

        if (user_id) {
            submitData = {
                type: type,
                title: title,
                user_id: user_id,
                user_name: user_name,
                password: null,
                content: stateToHTML(content.getCurrentContent())
            };

        } else {
            if (writer === undefined) {
                alert('작성자를 입력해주세요.');
                return null;
            }

            if (password === undefined) {
                alert('비밀번호를 입력해주세요.');
                return null;
            }

            submitData = {
                type: type,
                title: title,
                user_id: null,
                user_name: writer,
                password: password,
                content: stateToHTML(content.getCurrentContent())
            };

        }
        callApi('/board', 'POST', submitData).then((res) => {
            // console.log(res);
        });
    }


    return (
        <>
            <Header/>

            <StyledContainer>
                <div className="title-wrap">
                    <img src={mapBg} alt=""/>
                    <div className="title">
                        <span>{boardTitle}</span>
                    </div>
                </div>

                <Breadcrumb data={breadcrumb}/>

                <div className="category">
                    <div></div>
                    <div className="active">{boardTitle}</div>
                    <div></div>
                </div>

                <StyledCategoryContainer>
                    <div>
                        <h2>글쓰기</h2>
                    </div>

                    <StyledForm>
                        <div>
                            <label>
                                제목 <input type="text" onChange={handleChangeTitle}/>
                            </label>
                        </div>
                        <div>
                            <label>
                                작성자
                                {
                                    user_name ?
                                        <input type="text" value={user_name} readOnly/> :
                                        <input type="text" onChange={handleChangeWriter}/>
                                }

                            </label>
                        </div>
                        {
                            !user_id && (
                                <div>
                                    <label>
                                        비밀번호
                                        <input type="password" onChange={handleChangePassword}/>
                                    </label>
                                </div>
                            )
                        }
                        <div>
                            <div className="content-title">내용</div>
                            <WysiwygEditor
                                contents={content}
                                onChange={setContent}
                            />
                        </div>
                    </StyledForm>

                    <div className="bottom">
                        <div>
                            <button onClick={handleClickSubmit} className="default-button confirm">등록하기</button>
                        </div>

                        <Link to={"/board/" + type} className="default-button">
                            목록
                        </Link>
                    </div>
                </StyledCategoryContainer>
            </StyledContainer>

            <Footer/>
        </>
    );
}

const StyledContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;

    .title-wrap {
        position: relative;
        user-select: none;

        img {
            width: 100%;
        }

        .title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 45px;
            font-weight: 200;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);

            span {
                font-weight: 600;
            }
        }
    }

    .category {
        display: flex;
        justify-content: center;
        margin-top: 110px;
        margin-bottom: 30px;
        
        div {
            flex:1;
            max-width: 440px;
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            color: #434343;
            border-bottom: 2px solid #dcdcdc;
            
            &.active {
                max-width: 170px;
                border-bottom: 2px solid #f68b1f;
                padding-bottom: 12px;
            }
        }
    }

    @media (max-width: 940px) {
        margin: 60px auto 0;
    }

    @media (max-width: 475px) {
        br {
            display: block;
        }

        .title-wrap {
            img {
                height: 300px;
                object-fit: cover;
            }

            .title {
                font-size: 29px;
                text-align: center;
                width: 85%;
            }
        }

        .category {
            margin-top: 45px;

            div {
                font-size: 19px;
            }
        }
    }
`;

const StyledCategoryContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 30px auto 93px;
    max-width: 900px;
    padding: 0 20px;

    .info {
        display: flex;
        background-color: #ecebe2;
        padding: 10px;
        margin-bottom: 20px;

        svg {
            margin-right: 5px;
        }

        div {
            display: flex;
            align-items: center;
            font-size: 12px;
            line-height: 12px;
            margin-right: 20px;
        }
    }

    .bottom {
        //border-top: 1px solid #dcdcdc;
        padding-top: 20px;
        display: flex;
        justify-content: space-between;

        .default-button {
            padding: 10px;
            font-size: 14px;
            background-color: #fff;
            border: 1px solid #dcdcdc;
            cursor: pointer;
            outline: none;

            &.confirm {
                background-color: #a7be38;
                color: #fff;
            }
        }
    }

    @media (max-width: 450px) {
        .info {
            div {
                margin-right: 10px;
            }
        }
    }
`;

const StyledForm = styled.div`
    input {
        width: 100%;
        height: 40px;
        border: 1px solid #dcdcdc;
        margin: 10px 0;
        padding: 10px;
        font-size: 14px;
    }
    
    .content-title {
        margin-bottom: 10px;
    }
`;

export default BoardWrite;