import React, {useState, useEffect} from 'react';
import topIcon from '../assets/images/top-icon.png';
import styled from 'styled-components';

function ScrollTop(props) {
    const [showScroll, setShowScroll] = useState(false);

    useEffect(() => {
        function checkScrollTop() {
            if (!showScroll && window.pageYOffset > 600) {
                setShowScroll(true);
            } else if (showScroll && window.pageYOffset <= 600) {
                setShowScroll(false);
            }
        }

        if (!showScroll) {
            checkScrollTop();
        }

        window.addEventListener('scroll', checkScrollTop);
        return () => window.removeEventListener('scroll', checkScrollTop);
    }, [showScroll]);



    function scrollTop() {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    return (
        <StyledTopButton onClick={scrollTop} showScroll={showScroll}>
            <img src={topIcon} alt="" />
        </StyledTopButton>
    );
}

const StyledTopButton = styled.button`
    display: ${props => props.showScroll ? 'flex' : 'none'};
    position: fixed;
    bottom: 10%;
    right: 162px;
    
    border: none;
    background: none;
    outline: none;
    padding: 0;
    cursor: pointer;
    z-index: 1000;
    
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    
    @media (max-width: 940px) {
        display: none;
    }
`;

export default ScrollTop;