import React, {useEffect} from 'react';
import {Header, Footer, ScrollTop, RecentView, Breadcrumb} from '../../components';
import styled from 'styled-components';
import bg from "../../assets/images/assoc/greetings-bg.jpg";
import '../../assets/css/greetings.css';

function Greetings(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const breadcrumb = [{
        id: 1,
        title: '인사말',
        link: '/greetings'
    }];

    return (
        <>
            <Header/>

            <StyledContainer>
                <div className="title-wrap">
                    <img src={bg} alt=""/>
                    <div className="title">
                        <span>인사말</span>
                    </div>
                </div>

                <Breadcrumb data={breadcrumb}/>

                <div className="category">
                    <div></div>
                    <div className="active">인사말</div>
                    <div></div>
                </div>

                <StyledCategoryContainer>
                    <h2>안녕하십니까?</h2>
                    <h3>
                        사) 남양주시 농어촌민박협회에서 운영하는 브랜드 남양주펜션 가자 입니다.
                    </h3>
                    <div>
                        가자는 남양주시 조례에 따라 농어촌 정비법 제86조 제2항의 의거하여 남양주시 내에서 민박(펜션), 숙박사업을 운영하는 회원들의 권익 보호와 소득증대를 통하여 국가와 지역
                        경제 활성화에 이바지하고자 설립되었습니다.
                    </div>
                    <br/>
                    <div>
                        가자는 안정적인 민박(펜션), 관광산업 성장을 위해 국내 외 남양주 관광객 유치활동을 지속 추진하고 있으며, 아울러 민박(펜션) 사업체 대외 경쟁력 강화와 경영 환경개선을
                        위해 다양한 지원 사업과 남양주시를 오시는 관광객들을 위해 다양한 사업을 추진하고 있습니다.
                    </div>
                    <br/>
                    <div>
                        업계의 자정노력을 주도하여 남양주시의 관광과 숙박의 채질개선에도 앞장서고 있습니다.<br/>
                        브랜드 남양주펜션 가자는 남양주시에서 지원받아 제작한 농어촌민박(펜션) 홍보 홈페이지로 남양주 시내 주요 거리와 관광 홍보 및 안내체계를 확보하여 저희 민박(펜션)의
                        방문객이 보다 쉽게 남양주시 문화 관광에 대한 추가적인 정보를 얻는데 어려움이 없도록 홈페이지를 제작하였습니다.<br/>
                        또한 민박업(펜션), 숙박업계 많은 사업자분들이 홍보에 들어가는 많은 비용 지출로 인해 어려움을 겪는 사장님들을 위해 이번 브랜드 가자 런칭 계기로 개별 홍보 비용과
                        관광객분들의 숙박 요금을 조금이나마 절감하는 데 도움이 되고자 제작하였습니다.
                    </div>
                    <br/>
                    <div>
                        저희 브랜드 남양주펜션 가자는 무허가, 불법으로 운영하는 곳을 막아내고자 노력하고 있으며,
                        보다 저희 홈페이지를 방문해 주신 방문객과 협회원님들 숙박고객 분들의 많은 관심과 조언 부탁드리며, 남야주시 민박(펜션) 숙박업계 사업자분들의 사업에 무궁한 발전이 있기를 기원합니다.
                    </div>

                </StyledCategoryContainer>

                <div className="category">
                    <div></div>
                    <div className="active">연혁</div>
                    <div></div>
                </div>
                <div className="greeting">
                    <div className="greeting-row-first">
                        <div className="greeting-circle">
                            현재
                        </div>
                        <div className="greeting-col-first">

                            <div className="greeting-dot"></div>
                            <div className="greeting-col-line2"></div>
                        </div>
                    </div>

                    <div className="greeting-row">
                        <div className="greeting-text-left">남양주시 농어촌민박사업자지원 심의위원회 개최</div>
                        <div className="greeting-col">
                            <div className="greeting-col-line2"></div>
                            <div className="greeting-dot"></div>
                            <div className="greeting-col-line2"></div>
                        </div>
                        <div className="greeting-text-right bold">2022.02.16</div>
                    </div>

                    <div className="greeting-row">
                        <div className="greeting-text-left bold">2022.01.28</div>
                        <div className="greeting-col">
                            <div className="greeting-col-line2"></div>
                            <div className="greeting-dot"></div>
                            <div className="greeting-col-line2"></div>
                        </div>
                        <div className="greeting-text-right">
                            <br/>
                            (사) 한국농어촌민박협회 남양주시지회 남양주시복지재단 동부희망케이센터
                            <br/>
                            KF94 방역마스크(15000매) 기부
                        </div>
                    </div>

                    <div className="greeting-row">
                        <div className="greeting-text-left">
                            <br/>
                            (사) 한국농어촌민박협회 경기도지부 남양주시지회장 제2대 지회장 취임식
                            <br/>
                            (취임 김정기)
                        </div>
                        <div className="greeting-col">
                            <div className="greeting-col-line2"></div>
                            <div className="greeting-dot"></div>
                            <div className="greeting-col-line2"></div>
                        </div>
                        <div className="greeting-text-right bold">2022.01.25</div>
                    </div>

                    <div className="greeting-row">
                        <div className="greeting-text-left bold">2021.12.07</div>
                        <div className="greeting-col">
                            <div className="greeting-col-line2"></div>
                            <div className="greeting-dot"></div>
                            <div className="greeting-col-line2"></div>
                        </div>
                        <div className="greeting-text-right">(사) 한국농어촌민박협회 남양주시지회 정기총회</div>
                    </div>

                    <div className="greeting-row">
                        <div className="greeting-text-left">(사) 한국농어촌민박협회 남양주시지회 브랜드 "GAZA" 펜션 홍보홈페이지 오픈</div>
                        <div className="greeting-col">
                            <div className="greeting-col-line2"></div>
                            <div className="greeting-dot"></div>
                            <div className="greeting-col-line2"></div>
                        </div>
                        <div className="greeting-text-right bold">2021.03.01</div>
                    </div>

                    <div className="greeting-row">
                        <div className="greeting-text-left bold">2020.05.28</div>
                        <div className="greeting-col">
                            <div className="greeting-col-line2"></div>
                            <div className="greeting-dot"></div>
                            <div className="greeting-col-line2"></div>
                        </div>
                        <div className="greeting-text-right">남양주시 농어촌민박사업자지원 심의위원회 개최</div>
                    </div>
                    <div className="greeting-row">
                        <div className="greeting-text-left">(사) 한국농어촌민박협회 남양주시지회 임원회와 남양주시장 간담회 개최</div>
                        <div className="greeting-col">
                            <div className="greeting-col-line2"></div>
                            <div className="greeting-dot"></div>
                            <div className="greeting-col-line2"></div>
                        </div>
                        <div className="greeting-text-right bold">2020.05.18</div>
                    </div>

                    <div className="greeting-row">
                        <div className="greeting-text-left bold">2020.05</div>
                        <div className="greeting-col">
                            <div className="greeting-col-line2"></div>
                            <div className="greeting-dot"></div>
                            <div className="greeting-col-line2"></div>
                        </div>
                        <div className="greeting-text-right">농어촌민박 안전 사고예방 및 활성화 지원사업(1회 추경) 편성</div>
                    </div>
                    <div className="greeting-row">
                        <div className="greeting-text-left">(사) 한국농어촌민박협회 남양주시지회장 이,취임식(취임 김정기)</div>
                        <div className="greeting-col">
                            <div className="greeting-col-line2"></div>
                            <div className="greeting-dot"></div>
                            <div className="greeting-col-line2"></div>
                        </div>
                        <div className="greeting-text-right bold">2019.12.12</div>
                    </div>
                    <div className="greeting-row">
                        <div className="greeting-text-left bold">2019.11.21</div>
                        <div className="greeting-col">
                            <div className="greeting-col-line2"></div>
                            <div className="greeting-dot"></div>
                            <div className="greeting-col-line2"></div>
                        </div>
                        <div className="greeting-text-right">
                            <br/>
                            남양주시 농어촌민박사업자지원 심의위원회 위원 위촉 및 심의회 참석<br/>
                            남양주시 농어촌민박사업 대상자 안전, 위생, 서비스 의무교육 협조
                        </div>
                    </div>
                    <div className="greeting-row">
                        <div className="greeting-text-left">(사) 한국농어촌민박협회 경기도지부 남양주시지회 사업자등록</div>
                        <div className="greeting-col">
                            <div className="greeting-col-line2"></div>
                            <div className="greeting-dot"></div>
                            <div className="greeting-col-line2"></div>
                        </div>
                        <div className="greeting-text-right bold">2019.07</div>
                    </div>
                    <div className="greeting-row">
                        <div className="greeting-text-left bold">2019.03.14</div>
                        <div className="greeting-col">
                            <div className="greeting-col-line2"></div>
                            <div className="greeting-dot"></div>
                            <div className="greeting-col-line2"></div>
                        </div>
                        <div className="greeting-text-right">남양주시 농어촌민박사업지원조례 제정(제1618호)</div>
                    </div>
                    <div className="greeting-row">
                        <div className="greeting-text-left">
                            <br/>(사) 한국농어촌민박협회 정관 제정 및 농림축산식품부장관 인가<br/>
                            (사) 한국농어촌민박협회 남양주시지회 취임식(취임 박상규)
                        </div>
                        <div className="greeting-col-last">
                            <div className="greeting-col-line2"></div>
                            <div className="greeting-dot"></div>
                        </div>
                        <div className="greeting-text-right bold">2018.03.23</div>
                    </div>
                </div>
            </StyledContainer>

            <RecentView/>

            <Footer/>

            <ScrollTop/>
        </>
    );
}

const StyledContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    
    .title-wrap {
        position: relative;
        
        img {
            width: 100%;
        }
        
        .title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 45px;
            font-weight: 200;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
            
            span {
                font-weight: 600;
            }
        }        
    }
    
    .category {
        max-width: 1050px;
        display: flex;
        margin: 110px auto 30px;
        
        div {
            width: 100%;
            max-width: 440px;
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            color: #434343;
            border-bottom: 2px solid #dcdcdc;
            
            &.active {
                max-width: 170px;
                border-bottom: 2px solid #f68b1f;
                padding-bottom: 12px;
            }
        }
    }
    
    @media (max-width: 940px) {
        margin: 60px auto 0;
    }
    
    @media (max-width: 475px) {
    
        .title-wrap {
            img {
                height: 300px;
                object-fit: cover;
            }
            
            .title {
                font-size: 29px;
                text-align: center;
                width: 85%;
            }
        }
        
        .category {
            margin-top: 45px;
            
            div {
                font-size: 19px;
            }
        }
    }
`;

const StyledCategoryContainer = styled.div`
    margin: 100px auto 100px;
    // max-width: 643px;
    max-width: 800px;
    padding: 0 20px;
    text-align: justify;
    
    .mobile-hide {
        display: none;
    }
    
    h2 {
        font-size: 30px;
    }
    
    h3 {
        font-size: 21px;
    }
    
    div {
        line-height: 24px;
        font-size: 16px;
    }
    
    @media (max-width: 660px) {
        margin: 60px auto 60px;
    
        .mobile-hide {
            display: none;
        }
    
        h2 {
            font-size: 25px;
        }
        
        h3 {
            font-size: 17px;
        }
    
        div {
            margin: 0;
            font-size: 14px;
        }
    }
`;

export default Greetings;