import React from 'react';
import styled from 'styled-components';

function Checkbox(props) {
    return (
        <StyledLabel>
            <input type="checkbox" checked={props.checked} onChange={props.onChange} />
            <span></span>
        </StyledLabel>
    );
}

const StyledLabel = styled.label`
    position: relative;
    cursor: pointer;
    user-select: none;
    display: block;
    width: 30px;
    height: 30px;
    
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        
        &:checked ~ span {
            display: block;
            background-color: #5fc148;
        }
    }
    
    span {
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 30px;
        background-color: #dfdfdf;
    }
    
    span:after {
        content: "";
        position: absolute;
        top: 5px;
        left: 10px;
        width: 7px;
        height: 13px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
    }
    
    &:hover input ~ span {
        background-color: #ccc;
    }
    
    &:hover input:checked ~ span {
        background-color: #5fc148;
    }
`;

export default Checkbox;