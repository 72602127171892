import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import styled from 'styled-components';
import {Link, useHistory} from 'react-router-dom';
import {device} from '../config/device';
import prevIcon from '../assets/images/slider-prev-icon.png';
import nextIcon from '../assets/images/slider-next-icon.png';
import {useCookies} from 'react-cookie';
import noImage from '../assets/images/noimage.jpg';

const SliderBox = props => {
    const PrevArrowButton = (props) => {
        return (
            <StyledArrowButton className="slick-arrow prev" onClick={props.onClick}>
                <img src={prevIcon} alt=""/>
            </StyledArrowButton>
        );
    };

    const NextArrowButton = (props) => {
        return (
            <StyledArrowButton className="slick-arrow next" onClick={props.onClick}>
                <img src={nextIcon} alt=""/>
            </StyledArrowButton>
        );
    };

    const SliderSettings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrowButton/>,
        prevArrow: <PrevArrowButton/>
    }

    // NumberFormat
    let nf = new Intl.NumberFormat();

    // type === pension
    function typePension() {
        return (
            <>
                <div className="tag">
                    {
                        props.tag.map((item, index) => {
                            if (item === undefined) {
                                return <React.Fragment key={index}></React.Fragment>;
                            } else {
                                return (
                                    <div key={index}>
                                        {item}
                                    </div>
                                )
                            }
                        })
                    }
                </div>
                <div className="price">
                    {props.price > props.salePrice && props.salePrice !== 0 ? (
                        <div>
                            <span className="default-price">{nf.format(props.price)}원</span><br/>
                            {nf.format(props.salePrice)}원~
                        </div>
                    ) : (
                        <div>
                            {nf.format(props.price)}원~
                        </div>
                    )}
                </div>
            </>
        )
    }

    // type === farm
    function typeFarm() {
        return (
            <>
                {props.desc}
            </>
        )
    }

    // 최근 본 펜션으로 등록 (쿠키 사용)
    const [cookies, setCookie] = useCookies(['pension']);

    // 기존 쿠키 정보 가져오기
    let pensionCookie = cookies.pension;

    let history = useHistory();

    function handleClickLink(event, link) {
        event.preventDefault();

        if (props.type === 'pension') {
            // 기존 쿠기 정보가 존재하지 않으면 새로운 배열에 객체값 저장
            if (pensionCookie === undefined) {
                pensionCookie = [
                    {
                        id: props.id,
                        title: props.title,
                        link: props.link,
                        image: props.images[0]
                    }
                ];
            } else {
                // 기존 쿠기 정보에 존재하는 펜션이면 쿠키 저장하지 않음
                const pensionCheck = (element) => element.id === props.id;
                if (pensionCookie.findIndex(pensionCheck) === -1) {
                    // 기존 배열에 새로운 객체값 추가
                    pensionCookie.push({
                        id: props.id,
                        title: props.title,
                        link: props.link,
                        image: props.images[0]
                    });
                }
            }

            // 매일 밤 자정에 쿠키 초기화
            let midnight = new Date();
            midnight.setHours(23, 59, 59, 0);

            setCookie('pension', pensionCookie, {path: '/', expires: midnight});

            history.push("/detail/" + props.id);
        } else if (props.type === 'farm') {
            history.push("/detail/" + props.id);
        }
    }

    // 노 이미지
    function defaultSrc(event) {
        event.target.src = noImage;
    }

    const slideImage = (item) => {
        let imgSrc = item ? item : noImage;

        if (props.type === 'pension' || props.type === 'farm') {
            return (
                <Link to={"/detail/" + props.id} onClick={(event) => handleClickLink(event, props.link)}>
                    <img onError={defaultSrc} src={imgSrc} alt={item} className="slide-image"/>
                </Link>
            )
        } else if (props.type === 'mountain') {
            return (
                <a href={props.link} target="_blank" rel="noopener noreferrer">
                    <img onError={defaultSrc} src={imgSrc} alt={item} className="slide-image"/>
                </a>
            )
        }
    };

    const productName = () => {
        if (props.type === 'pension' || props.type === 'farm') {
            return (
                <Link to={"/detail/" + props.id} onClick={(event) => handleClickLink(event, props.link)}>
                    {props.title}
                </Link>
            )
        } else if (props.type === 'mountain') {
            return (
                <a href={props.link} target="_blank" rel="noopener noreferrer">
                    {props.title}
                </a>
            )
        }
    };

    const produceInfo = () => {
        if (props.type === 'pension' || props.type === 'farm') {
            return (
                <Link to={"/detail/" + props.id} onClick={(event) => handleClickLink(event, props.link)}>
                    {props.type === 'pension' ? typePension() : typeFarm()}
                </Link>
            )
        } else if (props.type === 'mountain') {
            return (
                <a href={props.link} target="_blank" rel="noopener noreferrer">
                    {props.type === 'pension' ? typePension() : typeFarm()}
                </a>
            )
        }
    };

    return (
        <StyledContainer>
            <Slider {...SliderSettings} className={props.isActive === '' ? '' : 'active'}>
                {
                    props.images.length < 1 ? (
                        <div>
                            {slideImage(null)}
                        </div>
                    ) : (
                        props.images.map((item, index) => {
                            return (
                                <div key={index}>
                                    {slideImage(item)}
                                </div>
                            )
                        })
                    )
                }
            </Slider>
            {
                props.salePer > 0 && (
                    <div className="sale-per">
                        <div>{props.salePer}%</div>
                    </div>
                )
            }
            <div className="product-name">
                {productName()}
            </div>
            <div className="produce-info">
                {produceInfo()}
            </div>
        </StyledContainer>
    );
};

SliderBox.propTypes = {
    link: PropTypes.string,
    location: PropTypes.string,
    title: PropTypes.string,
    images: PropTypes.array,
    tag: PropTypes.array,
    id: PropTypes.number,
    type: PropTypes.string
};

SliderBox.defaultProps = {
    type: 'pension'
}

const StyledContainer = styled.div`
    width: 340px;
    height: 357px;
    // width: 420px;
    // height: 441px;
    position: relative;
    margin: 10px;
    
    & > a {
        text-decoration: none;
        color: inherit;
    }
    
    * {
        outline: none;
    }
    
    .sale-per {
        background-color: rgba(135,148,56,0.85);
        color:#FFF;
        position:absolute;
        right:0;
        top:0;
        width:50px;
        height:50px;
        margin:5px;
        display:flex;
        align-items: center;
        justify-content: center;
    }
    
    .product-name {
        font-size: 18px;
        font-weight: 500;
        color: #434343;
        border-bottom: 1px solid #d9d9d9;
        padding: 10px;
    }
    
    .produce-info {
        padding: 0 10px;
        margin-top: 6px;
        
        br {
            display: block !important;
        }
        a {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;            
        }
        
        .tag {
            padding-top:2px;
            font-size: 12px;
            font-weight: 400;
            color: #f68b1f;
            display: flex;
            
            div {
                border-right: 1px solid #f68b1f;
                padding-right: 7px;
                margin-right: 6px;
                
                &:last-child {
                    border-right: none;
                }
            }
        }
        
        .price {
            font-size: 17px;
            font-weight: 500;
            color: #434343;
            
            .default-price {
                text-decoration-line: line-through;
            }
        }
    }
    
    .slide-image {
        width: 340px;
        // width: 420px;
        height: 254px;
        // height: 314px;
        object-fit: cover;
    }
    
    .slick-slider {
        .slick-arrow {
            display:none !important;
        }

        &:hover {
            .slick-arrow {
                display:flex !important;
            }
        }
        
        opacity: 0;
        visibility: hidden;
        transition: opacity 1s ease;

        &.active {
            visibility: visible;
            opacity: 1;
        }
    }
    
    @media (max-width: 736px) {
        width: 300px;
        height: 295px;
        // width: 100%;
        // height: auto;
        //margin: 15px 30px;
        margin: 0 0 60px;
        padding: 0 20px;
        
        .slide-image {
            width: 300px;
            height: 224px;
            
            // width: 100%;
            // height: 100%;
            
            object-fit: cover;
        }
        
        .product-name {
          font-size: 20px;   
          color: #000; 
        }
        
        .produce-info {
            .tag {
                font-size: 15px;
            }
            
            .price {
                font-size: 15px;
                color: #000;
            }
        }
        
        .sale-per {
            right: 20px;
        }
    }
`;

const StyledArrowButton = styled.button`
    background: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    
    &.prev {
        left: 10px;
    }
    
    &.next {
        right: 10px;
    }
    
    display: flex;
    border: none;
    outline: none;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    z-index: 2;
    
    @media ${device.small} {
        top: 50%;
        transform: translateY(-50%);
    }
`;

export default SliderBox;


export const ListSliderBox = (props) => {
    const PrevArrowButton = (props) => {
        return (
            <ListStyledArrowButton className="slick-arrow prev" onClick={props.onClick}>
                <svg
                    width="18px" height="31px">
                    <path fillRule="evenodd" fill="rgb(255, 255, 255)"
                          d="M17.160,28.245 L15.039,30.365 L0.192,15.521 L0.328,15.385 L0.192,15.248 L15.039,0.403 L17.160,2.525 L4.298,15.384 L17.160,28.245 Z"/>
                </svg>
            </ListStyledArrowButton>
        );
    };

    const NextArrowButton = (props) => {
        return (
            <ListStyledArrowButton className="slick-arrow next" onClick={props.onClick}>
                <svg
                    width="18px" height="31px">
                    <path fillRule="evenodd" fill="rgb(255, 255, 255)"
                          d="M17.125,15.528 L2.278,30.372 L0.157,28.251 L13.019,15.391 L0.157,2.531 L2.278,0.410 L17.125,15.255 L16.989,15.392 L17.125,15.528 Z"/>
                </svg>
            </ListStyledArrowButton>
        );
    };

    const SliderSettings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrowButton/>,
        prevArrow: <PrevArrowButton/>
    }

    return (
        <ListBoxContainer type={props.type}>
            <Link to={props.link}>
                <Slider {...SliderSettings} className={props.isActive === '' ? '' : 'active'}>
                    {
                        props.images.map((item, index) => {
                            return (
                                <div key={index}>
                                    <img src={item} alt={item}/>
                                </div>
                            )
                        })
                    }
                </Slider>
                <div className="location">
                    {props.location}
                </div>
                <div className="product-name">
                    {props.title}
                </div>
            </Link>
        </ListBoxContainer>
    );
};

ListSliderBox.propTypes = {
    link: PropTypes.string,
    location: PropTypes.string,
    title: PropTypes.string,
    images: PropTypes.array
};

const ListBoxContainer = styled.div`
    width: 100%;
    max-width: 340px;
    height: auto;
    position: relative;
    margin-right: 20px;
    margin-bottom: 60px;

    & > a {
        text-decoration: none;
        color: inherit;
    }

    * {
        outline: none;
    }

    .location {
        font-size: 16px;
        color: #666;
        margin-top: 20px;
        margin-bottom: 5px;
    }

    .product-name {
        font-size: 18px;
        color: #000;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .slick-slider {
        .slick-arrow {
            display:none !important;
        }

        &:hover {
            .slick-arrow {
                display:flex !important;
            }
        }

        opacity: 0;
        visibility: hidden;
        transition: opacity 1s ease;

        &.active {
            visibility: visible;
            opacity: 1;
        }
    }

    @media ${device.small} {
        width: 100%;
        height: auto;
        margin-right: 0;
        margin-bottom: 20px;

        img {
            width: 100%;
            height: 100%;
        }

        .location {
            margin-top: 10px;
        }
    }
`;

const ListStyledArrowButton = styled.button`
    width: 61px;
    height: 61px;
    background: rgba(95, 193, 72, 0.8);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &.prev {
        left: 0;
    }

    &.next {
        right: 0;
    }

    display: flex;
    border: none;
    outline: none;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    z-index: 2;
`;