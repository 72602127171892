import React, {useState} from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import prevIcon from "../assets/images/recent-slide-prev.png";
import nextIcon from "../assets/images/recent-slide-next.png";
import {useCookies} from 'react-cookie';
import noImage from "../assets/images/noimage.jpg";
import {Link} from 'react-router-dom';

function RecentView(props) {
    // 노 이미지
    function defaultSrc(event) {
        event.target.src = noImage;
    }

    // 쿠키 데이터
    const [cookies, setCookie] = useCookies(['pension']);

    // 기존 쿠키 정보 가져오기
    let pensionCookie = cookies.pension;

    const PrevArrowButton = (props) => {
        return (
            <StyledArrowButton className="slick-arrow prev" onClick={props.onClick}>
                <img src={prevIcon} alt="" />
            </StyledArrowButton>
        );
    };

    const NextArrowButton = (props) => {
        return (
            <StyledArrowButton className="slick-arrow next" onClick={props.onClick}>
                <img src={nextIcon} alt="" />
            </StyledArrowButton>
        );
    };

    const SliderSettings = {
        infinite: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        nextArrow: <NextArrowButton />,
        prevArrow: <PrevArrowButton />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 970,
                settings: {
                    slidesToShow: 3
                }
            }
        ]
    }

    // 이미지 hover 시 최근 본 펜션에서 제거하는 버튼 표시
    const [isHover, setIsHover] = useState();

    function handleHoverImg(id) {
        setIsHover(id);
    }

    function handleHoverOutImg(id) {
        setIsHover(null);
    }

    function handleClickRemove(event, id) {
        event.preventDefault();

        // 쿠키 데이터에서 해당 펜션 삭제
        if (pensionCookie !== undefined) {
            const pensionCheck = (element) => element.id === id;
            let findIndex = pensionCookie.findIndex(pensionCheck);
            if (findIndex !== -1) {
                pensionCookie.splice(findIndex, 1);

                let midnight = new Date();
                midnight.setHours(23, 59, 59, 0);
                setCookie('pension', pensionCookie, {path: '/', expires: midnight});
            }
        }
    }

    return (
        <StyledContainer>
            <div className="category">
                <div></div>
                <div className="active">최근 본 펜션</div>
                <div></div>
            </div>

            <StyledSliderWrap>

                <Slider {...SliderSettings}>
                    {
                        cookies.pension &&
                        cookies.pension.map((item, index) => {
                            return (
                                <Link to={'/detail/' + item.id} key={index}>
                                    <img src={item.image} alt=""
                                         className="slide-img"
                                         onError={defaultSrc}
                                         onMouseOver={() => handleHoverImg(item.id)}
                                         onMouseLeave={() => handleHoverOutImg(item.id)}
                                    />
                                    <div className="slide-title">{item.title}</div>
                                    <StyledRemoveBtn
                                         onClick={(event) => handleClickRemove(event, item.id)}
                                            isHover={isHover}
                                            itemId={item.id}
                                    >x</StyledRemoveBtn>
                                </Link>
                            )
                        })
                    }
                </Slider>
            </StyledSliderWrap>
        </StyledContainer>
    );
}

const StyledContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    background-color: #e5e5e5;
    margin-top:130px;
    padding-bottom: 30px;
    
    .category {
        max-width: 1050px;
        display: flex;
        padding-top: 50px;
        margin: 0 auto 30px;
        
        div {
            width: 100%;
            max-width: 440px;
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            color: #434343;
            border-bottom: 2px solid #dcdcdc;
            
            &.active {
                width: 100%;
                max-width: 170px;
                border-bottom: 2px solid #f68b1f;
                padding-bottom: 12px;
            }
        }
    }
    
    @media (max-width: 475px) {
        padding-top: 50px;
        height: 280px;
        
        .category {
            div {
                font-size: 23px;
            }
        }
    }
`;

const StyledSliderWrap = styled.div`
    max-width: 1070px;
    margin: 0 auto;
    
    a {
        position: relative;
        max-width: 170px;
    }

    .slide-img {
        width: 170px;
        height: 127px;
        object-fit: cover;
    }
    
    .slide-title {
        font-size: 16px;
        color: #000;
        margin-top: 13px;
    }
    
    @media (max-width: 1200px) {
        max-width: 800px;
    }
    
    @media (max-width: 970px) {
        max-width: 370px;
        
        a {
            max-width: 104px;
        }
        
        .slide-img {
            width: 104px;
            height: 78px;
        }
    }
    
    @media (max-width: 475px) {
        max-width: 290px;
        padding-left: 15px;
        
        a {
            max-width: 84px;
        }
        
        .slide-img {
            width: 84px;
            height: 58px;
        }
        
        .slide-title {
            font-size: 12px;
        }
    }
`;

const StyledArrowButton = styled.button`
    background: none;
    position: absolute;
    top: 42%;
    transform: translateY(-50%);
    
    &.prev {
        left: -62px;
    }
    
    &.next {
        right: -62px;
    }
    
    display: flex;
    border: none;
    outline: none;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    z-index: 2;
    
    @media (max-width: 600px) {
        &.prev {
            left: -40px;
        }
        
        &.next {
            right: -35px;
        }
    }
`;

const StyledRemoveBtn = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    
    width: 21px;
    height: 21px;
    display: ${props => props.itemId === props.isHover ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;
    padding-bottom: 6px;
    color: #fff;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    
    &:hover {
        display: flex;
    }
    
    @media (max-width: 576px) {
        display: flex;
    }
`;

export default RecentView;