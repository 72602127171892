import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Header, Footer, Pagination, ListSliderBox, DetailSearchModal} from '../components';
import {device} from '../config/device';

// dummy api data
// import listPageData from '../dummy/listPageData.json';
import {callApi} from "../api";

const List = () => {

    const [pensionList, setPensionList] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);

        // 검색
        // const request = {
        //     category: ['커플']
        // }
        // callApi('/company', 'GET', request).then((res) => {
        //     console.log(res)
        //     if (res)
        //         setPensionList(res.list);
        // }).catch((err) => {
        //     console.log(err);
        // });

        // 일반
        callApi('/company/숙박', 'GET', {}).then((res) => {
            if (res)
                setPensionList(res.list);
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    // Data
    const listData = pensionList.map((item, index) => {
        return (
            <ListSliderBox
                key={item.id}
                link={item.homepage}
                location={item.location}
                title={item.companyName}
                images={item.images}
            />
        )
    });

    const [modalVisible, setModalVisible] = useState(false);
    const closeModal = () => {
        setModalVisible(false);
    }
    const handleClickDetailSearch = () => {
        setModalVisible(true);
    };

    return (
        <>
            <Header maxWidth="1420px"/>

            <ListContainer>
                <ListTitle>
                    <div className="sub-title">검색결과</div>
                    <div className="main-title">남양주 숙소</div>
                </ListTitle>

                <ButtonGroup>
                    <div>
                        <Button onClick={handleClickDetailSearch}>상세검색 <svg
                            width="23px" height="30px" className="search-icon">
                            <path fillRule="evenodd" fill="rgb(62, 71, 92)"
                                  d="M13.315,12.005 L5.538,19.781 L4.123,18.367 L10.805,11.688 L4.123,5.007 L5.538,3.593 L13.315,11.368 L12.997,11.687 L13.315,12.005 Z"/>
                        </svg></Button>
                    </div>
                    {/*<div>*/}
                    {/*    <Button><img src={mapIcon} alt="map icon" className="map-icon" /> 지도보기</Button>*/}
                    {/*</div>*/}
                </ButtonGroup>

                <ContentsContainer>
                    {listData}
                </ContentsContainer>

                <Pagination/>
            </ListContainer>

            <Footer/>

            {
                modalVisible && <DetailSearchModal
                    visible={modalVisible}
                    closable={true}
                    maskClosable={true}
                    onClose={closeModal}
                />
            }
        </>
    );
};

const ListContainer = styled.div`
    width: 100%;
    max-width: 1420px;
    margin: 60px auto;
    
    @media ${device.small} {
        padding: 0 20px;
    }
`;

const ListTitle = styled.div`
    margin-bottom: 30px;

    .sub-title {
        font-size: 18px;
        color: #666666;
        margin-bottom: 6px;
    }
    
    .main-title {
        font-size: 24px;
        color: #000;
    }
`;

const ButtonGroup = styled.div`
    //width: 340px;
    width: 170px;
    
    height: 60px;
    border: 1px solid #dfdfdf;
    display: flex;
    box-shadow: 2px 3px 7px 0px rgba(0, 0, 0, 0.15);
    margin-bottom: 29px;
    
    div {
        flex: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div:first-child {
        border-right: 1px solid #dfdfdf;
    }
`;

const Button = styled.button`
    width: 100%;
    height: 100%;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .search-icon {
        margin-top: 4px;
        margin-left: 3px;
    }
    
    .map-icon {
        margin-bottom: 9px;
        margin-right: 9px;
    }
`;

const ContentsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    
    div:nth-child(4n) {
       margin-right: 0;
    }
`;

export default List;