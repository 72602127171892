import React, {useState} from 'react';
import styled from 'styled-components';
import {MemberContainer, MemberHeader, MemberWrap} from '../../components/member';
import {InputBox, InputBusinessNumber, InputText} from '../../components/inputs';

function Forgot(props) {
    function handleClickGoBack() {
        props.history.goBack();
    }

    // 사업자 번호
    const [businessNumber, setBusinessNumber] = useState();
    function handleChangeBusinessNumber(formattedValue, raw) {
        setBusinessNumber(formattedValue);
    }

    // 상호(법인명)
    const [companyName, setCompanyName] = useState();
    function handleChangeCompanyName(event) {
        setCompanyName(event.target.value);
    }

    // 대표자 성명
    const [representativeName, setRepresentativeName] = useState();
    function handleChangeRepresentativeName(event) {
        setRepresentativeName(event.target.value);
    }

    // 대표 메일
    const [representativeMail, setRepresentativeMail] = useState();
    function handleChangeRepresentativeMail(event) {
        setRepresentativeMail(event.target.value);
    }

    function handleClickFind() {
        console.log('Find');
    }

    return (
        <MemberContainer>
            <MemberHeader
                title="비밀번호 찾기"
                onClick={handleClickGoBack}
            />

            <MemberWrap>
                <StyledTitle>이메일로 비밀번호 찾기</StyledTitle>

                <InputBox firstBox title="사업자번호">
                    <InputBusinessNumber
                        value={businessNumber}
                        onChange={handleChangeBusinessNumber}
                    />
                </InputBox>

                <InputBox title="상호(법인명)">
                    <InputText
                        value={companyName}
                        onChange={handleChangeCompanyName}
                    />
                </InputBox>

                <InputBox title="대표자 성명">
                    <InputText
                        value={representativeName}
                        onChange={handleChangeRepresentativeName}
                    />
                </InputBox>

                <InputBox title="대표 메일">
                    <InputText
                        value={representativeMail}
                        placeholder="___@___"
                        onChange={handleChangeRepresentativeMail}
                    />
                </InputBox>

                <StyledInfoText>
                    ※ 이메일로 임시 비밀번호를 전송합니다.
                </StyledInfoText>

                <StyledHr />

                <div>
                    <StyledButton onClick={handleClickFind}>비밀번호 찾기</StyledButton>
                </div>
            </MemberWrap>
        </MemberContainer>
    );
}

const StyledTitle = styled.div`
    font-size: 24px;
    color: #000;
    margin-bottom: 30px;
`;

const StyledInfoText = styled.div`
    font-size: 16px;
    color: #666;
    height: 55px;
    display: flex;
    align-items: center;
    padding-left: 20px;
`;

const StyledHr = styled.hr`
    display: block; 
    height: 1px;
    border: 0; 
    border-top: 1px solid #dfdfdf;
    margin: 10px 0;
    padding: 0;
`;

const StyledButton = styled.button`
    width: 100%;
    height: 60px;
    background-color: #5fc148;
    border: none;
    outline: none;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 30px;
`;

export default Forgot;