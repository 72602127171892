import React from 'react';
import styled from 'styled-components';
import {Editor} from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from 'draft-js';

const EditorContainer = styled.div`
    border: 1px solid #dfdfdf;
    //border-left: 1px solid #dfdfdf;
    //border-right: 1px solid #dfdfdf;
    background-color: #fff;
    
    & .rdw-editor-toolbar {
      border-bottom: 1px solid #dfdfdf;
    }
    
    & .rdw-editor-main {
      padding: 0 20px;
      min-height: 300px;
      font-size: 14px;
    }
`;

const WysiwygEditor = (props) => {
    return (
        <EditorContainer>
            <Editor placeholder="내용을 입력해주세요" editorState={props.contents ? props.contents : EditorState.createEmpty()} onEditorStateChange={props.onChange}/>
        </EditorContainer>
    );
};

export default WysiwygEditor;