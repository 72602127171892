import React, {useState} from 'react';
import {Header, Footer} from '../../components';
import styled from 'styled-components';
import CsHeader from './CsHeader';
import CsBody from './CsBody';

// material-ui
import MuiAccordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import apiNoticeData from '../../dummy/noticeData.json';

function Accordion() {
    const [expanded, setExpanded] = useState();

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    // 공지사항 id 내림차순 정렬
    const noticeData = apiNoticeData.sort((a, b) => {
        return b.id - a.id;
    });

    return (
            <div>
                {
                    noticeData.map((item, index) => {
                        return (
                            <StyledAccordion expanded={expanded === ('panel' + item.id)} onChange={handleChange('panel' + item.id)} key={item.id}>
                                <StyledAccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    {item.title}
                                </StyledAccordionSummary>
                                <StyledAccordionDetails>
                                    <div className="date">{item.date}</div>
                                    <div className="content" dangerouslySetInnerHTML={{__html: item.content}}>
                                    </div>
                                    {
                                        item.file.length !== 0 && (
                                            <div className="file">
                                                {
                                                    item.file.map((fileItem, fileIndex) => {
                                                        return (
                                                            <div key={fileItem.fileid}>
                                                                <div>{fileIndex + 1}. {fileItem.filename}</div>
                                                                <div><a href={fileItem.filepath} download={fileItem.filename}>다운로드</a></div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    }
                                </StyledAccordionDetails>
                            </StyledAccordion>
                        )
                    })
                }
            </div>
    )
}

const StyledAccordion = styled(MuiAccordion)`
    border: 1px solid #dfdfdf;
    border-radius: 0 !important;
    box-shadow: none !important;
    &:not(:last-child) {
        border-bottom: 0 !important;
    }
    
    &:last-child {
        .MuiAccordionDetails-root {
            border-bottom: 0 !important;
        }
    }
    
    &:before {
        height: 0 !important;
    }
    
    &.MuiAccordion-root.Mui-expanded {
        margin: 0 !important
    }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
    border-bottom: 1px solid #dfdfdf !important;
    margin-bottom: -1px !important;
    min-height: 60px !important;
    font-size: 18px !important;
`;

const StyledAccordionDetails = styled(AccordionDetails)`
    background-color: #efefef !important;
    padding: 25px 20px !important;
    font-size: 18px !important;
    flex-direction: column;
    
    .date {
        font-size: 16px;
        color: #666;
        margin-bottom: 13px;
    }
    
    .content {
        line-height: 30px;
    }
    
    .file {
        margin-top: 60px;
        margin-bottom: 40px;
        border-top: 1px solid #dfdfdf;
        display: flex;
        flex-direction: column;
        
        & > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100px;
            border-bottom: 1px solid #dfdfdf;
        }
        
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 180px;
            height: 60px;
            border: none;
            color: #fff;
            background-color: #5fc148;
            outline: none;
            cursor: pointer;
        }
    }
`;

function Notice(props) {
    return (
        <>
           <Header isSearch={false} />

           <CsHeader />

            <CsBody active='notice'>
                <Accordion />
            </CsBody>

           <Footer />
        </>
    );
}

export default Notice;