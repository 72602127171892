import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

MemberHeader.propTypes = {
    onClick: PropTypes.func,
    title: PropTypes.string
};

function MemberHeader(props) {
    return (
        <StyledHeader>
            <div>
                <button onClick={props.onClick}>
                    <svg
                        width="17px" height="30px">
                        <path fillRule="evenodd"  fill="rgb(62, 71, 92)"
                              d="M16.985,27.863 L14.864,29.985 L0.014,15.135 L0.150,14.999 L0.014,14.863 L14.864,0.014 L16.985,2.135 L4.121,15.000 L16.985,27.863 Z"/>
                    </svg>
                </button>
            </div>
            <div>{props.title}</div>
        </StyledHeader>
    );
}

const StyledHeader = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid #dfdfdf;
    font-size: 24px;
    
    & > div:first-child {
        position: absolute;
        top: 15px;
        left: 22px;
        
        button {
            padding: 0;
            border: none;
            outline: none;
            background-color: #fff;
            cursor: pointer;
        }
    }
`;

export default MemberHeader;